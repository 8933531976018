import React, { SVGProps } from 'react'

const IconWhatsAppLogo = ({ fill }: SVGProps<unknown>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="66"
    viewBox="0 0 65 66"
    fill="none"
  >
    <path
      d="M10.0003 11.286L10.0004 11.286L10.0081 11.2781C12.8421 8.37751 16.2202 6.06411 19.9491 4.47028C23.6749 2.87775 27.6779 2.03483 31.7295 1.98958C39.9035 2.06986 47.7158 5.37242 53.4688 11.1799C59.2243 16.9899 62.4534 24.8371 62.4539 33.0151L62.4539 33.0293L62.4539 33.0301C62.4576 38.1601 61.1726 43.2088 58.7169 47.7129L58.5269 48.0615L58.6277 48.4455L62.603 63.5883L47.8394 59.5453L47.4376 59.4353L47.0764 59.6431C40.6184 63.3604 33.0299 64.6048 25.7228 63.1449C18.4158 61.685 11.888 57.6202 7.35417 51.7068C2.82031 45.7934 0.589171 38.4343 1.07594 30.9988C1.56271 23.5632 4.73422 16.5578 10.0003 11.286Z"
      fill="#F2FDFF"
      stroke="#052B33"
      stroke-width="2"
    />
    <path
      d="M29.6543 36.6287V31.0928C33.0543 30.9578 35.4565 29.7089 35.4565 28.0886C35.4565 26.6709 34.0152 25.4557 31.7609 25.4557C29.95 25.4557 28.287 26.3333 27.1043 27.5485L24 24.6456C26.1065 22.4852 29.0261 21 32.6848 21C37.6 21 41 23.8017 41 27.616C41 30.654 38.2652 33.3882 34.4587 34.1983V36.6287H29.6543ZM32.0565 45C30.1348 45 28.6196 43.5823 28.6196 41.8608C28.6196 40.2743 30.1348 38.8565 32.0565 38.8565C33.8674 38.8565 35.3457 40.2743 35.3457 41.8608C35.3457 43.5823 33.8674 45 32.0565 45Z"
      fill="#052B33"
    />
  </svg>
)

export default IconWhatsAppLogo
