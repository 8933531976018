import React, { SVGProps } from 'react'

const IconAlmond = ({ fill }: SVGProps<unknown>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 239865">
      <path
        id="Vector"
        d="M43.8605 4.09994C40.7292 1.4555 36.754 0.0110501 32.6678 -6.10352e-05C14.6905 -6.10352e-05 0.966037 23.2111 0.966037 32.6889C0.966037 34 0.988221 35.2444 1.01043 36.3444C1.08816 40.4444 1.14368 43.6777 0.0443913 46.9C0.0110795 46.9888 0 47.0777 0 47.1666C0 47.6222 0.377534 47.9999 0.832795 47.9999C0.921627 47.9999 1.01045 47.9889 1.09928 47.9555C4.31942 46.8555 7.55067 46.9222 11.648 46.9889H11.9145C12.9916 47.0111 14.102 47.0333 15.3013 47.0333C24.7729 47.0333 47.969 33.3 47.969 15.3111C47.969 11.2222 46.5144 7.24439 43.8605 4.09994ZM2.67602 36.3C2.65381 35.1111 2.63163 33.9333 2.63163 32.6777C2.63163 24.9444 14.7571 1.65552 32.6678 1.65552C36.321 1.65552 39.8742 2.9444 42.6835 5.25551C45.0043 8.07773 46.2923 11.6444 46.3034 15.2999C46.3034 33.2111 23.0296 45.3556 15.3013 45.3556C14.1131 45.3556 13.0138 45.3333 11.9478 45.3222H11.6814C8.46122 45.1 5.21888 45.3 2.04315 45.9444C2.67608 42.7666 2.88705 39.5222 2.66497 36.3111L2.67602 36.3Z"
        fill="#052B33"
      />
      <path
        id="Vector_2"
        d="M5.50671 34.2333C7.53873 26.6666 11.736 19.9555 17.6433 14.8221C23.5617 9.68879 30.7903 6.47769 38.5631 5.53324H38.6742L38.4743 3.86658H38.3632C30.2573 4.86658 22.7178 8.211 16.5551 13.5666C10.3924 18.9221 6.01748 25.9222 3.89663 33.811L3.86328 33.9221L5.47337 34.3555L5.50671 34.2444V34.2333Z"
        fill="#052B33"
      />
      <path
        id="Vector_3"
        d="M5.46271 40.8444C11.2812 28.5555 20.4753 18.2999 32.0678 11.1999L32.1677 11.1444L31.2905 9.72217L31.2016 9.77771C19.3315 17.0444 9.91541 27.5444 3.95259 40.1333L3.9082 40.2332L5.31836 40.9L5.36282 40.7999L5.46271 40.8444Z"
        fill="#052B33"
      />
      <path
        id="Vector_4"
        d="M38.1427 8.66659C29.6926 20.1555 19.7768 30.511 8.67283 39.4333L8.58398 39.4999L9.56114 40.711L9.64999 40.6443L9.71661 40.7332C20.9427 31.711 30.9584 21.2555 39.4863 9.64437L39.5529 9.55546L38.2093 8.56659L38.1427 8.65546V8.66659Z"
        fill="#052B33"
      />
      <path
        id="Vector_5"
        d="M24.5185 31.1777L24.4297 31.2332L25.2514 32.5554L25.3402 32.4999L25.3957 32.5887C32.9464 27.9332 38.7538 21.1554 42.2071 12.9777L42.2515 12.8776L40.7191 12.2221L40.6748 12.3221C37.3547 20.1665 31.7694 26.6888 24.5297 31.1554L24.5185 31.1777Z"
        fill="#052B33"
      />
      <path
        id="Vector_6"
        d="M41.6403 18.6667C36.588 29.7 26.9164 37.6556 15.113 40.4778L15.002 40.5L15.3906 42.1222L15.5016 42.1C21.4978 40.6667 27.183 37.8333 31.9466 33.9111C36.7102 29.9889 40.5854 24.9556 43.1504 19.3445L43.1949 19.2445L41.6736 18.5555L41.6292 18.6556L41.6403 18.6667Z"
        fill="#052B33"
      />
    </g>
  </svg>
)

export default IconAlmond
