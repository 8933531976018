import { Subdomain } from './formConfig'

export const nonGeneralGrowTypeCropsList = ['Tomato']
export const nonGeneralGrowTypeVarietiesList = ['STVE-8444']
export const skipPlotAgeCropsList = ['Tomato']
export const plotMinAgeCropsList = ['Sugar Cane', 'Corn', 'Tomato']

export const HECTARE_PRICE_PER_YEAR = 24

export const subdomainCompanyNames: Record<Subdomain, string> = {
  [Subdomain.App]: 'Supplant',
  [Subdomain.Ahern]: 'Ahern',
  [Subdomain.Carrefour]: 'Carrefour',
  [Subdomain.Mexico]: 'Supplant',
}

export const START_RESERVE_HOUR = 7
export const END_RESERVE_HOUR = 18
export const SOIL_SILT_LOAM = 14