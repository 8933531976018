import React, { SVGProps } from 'react'

const IconWhatsapp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clip-path="url(#clip0_419_8527)">
      <g filter="url(#filter0_f_419_8527)">
        <path
          d="M17.4287 44.1647L18.143 44.5871C21.1432 46.3642 24.5831 47.3043 28.0911 47.3059H28.0984C38.8714 47.3059 47.6388 38.5571 47.6433 27.8042C47.6452 22.5934 45.6141 17.6933 41.9239 14.0074C40.114 12.1896 37.9608 10.7481 35.589 9.76644C33.2171 8.78478 30.6738 8.28243 28.1061 8.28845C17.3249 8.28845 8.55712 17.0362 8.55328 27.7885C8.54797 31.4601 9.58389 35.0583 11.5413 38.167L12.0063 38.9045L10.0315 46.1004L17.4287 44.1647ZM4.38531 51.6751L7.72167 39.517C5.66405 35.9589 4.58155 31.9224 4.58282 27.7869C4.58826 14.8498 15.1365 4.32495 28.0988 4.32495C34.3889 4.32814 40.293 6.77132 44.7333 11.2063C49.1735 15.6413 51.6166 21.5363 51.6144 27.8058C51.6086 40.7419 41.0588 51.2687 28.0984 51.2687H28.0882C24.1529 51.2671 20.286 50.2817 16.8512 48.4124L4.38531 51.6751Z"
          fill="#B3B3B3"
        />
      </g>
      <path
        d="M4.144 51.4339L7.48037 39.2758C5.4192 35.7091 4.33654 31.6632 4.34152 27.5457C4.34695 14.6086 14.8952 4.08374 27.8575 4.08374C34.1476 4.08693 40.0517 6.5301 44.492 10.9651C48.9322 15.4 51.3753 21.295 51.3731 27.5646C51.3673 40.5007 40.8175 51.0275 27.8571 51.0275H27.8469C23.9116 51.0259 20.0447 50.0405 16.6099 48.1712L4.144 51.4339Z"
        fill="white"
      />
      <path
        d="M27.8645 8.0473C17.0832 8.0473 8.3155 16.7951 8.31167 27.5474C8.30636 31.219 9.34227 34.8171 11.2997 37.9258L11.7647 38.6637L9.78984 45.8595L17.1874 43.9236L17.9017 44.3459C20.9019 46.123 24.3418 47.0628 27.8498 47.0647H27.8571C38.6301 47.0647 47.3978 38.316 47.402 27.563C47.4101 25.0001 46.9088 22.461 45.9271 20.0928C44.9453 17.7245 43.5027 15.5742 41.6826 13.7662C39.8727 11.9484 37.7194 10.5069 35.3475 9.52522C32.9756 8.54357 30.4322 8.04123 27.8645 8.0473Z"
        fill="url(#paint0_linear_419_8527)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.9799 17.737C21.5395 16.7606 21.0761 16.7408 20.6577 16.7239L19.5314 16.7102C19.1396 16.7102 18.5029 16.8569 17.9647 17.4439C17.4265 18.0308 15.908 19.4494 15.908 22.3347C15.908 25.22 18.0136 28.008 18.307 28.3997C18.6004 28.7915 22.3717 34.9011 28.3442 37.2518C33.3074 39.2053 34.3173 38.8168 35.395 38.7192C36.4727 38.6216 38.8714 37.3006 39.3607 35.9312C39.85 34.5617 39.8503 33.3885 39.7036 33.1432C39.5569 32.8979 39.1651 32.7521 38.577 32.4586C37.989 32.1651 35.1007 30.7466 34.5621 30.5507C34.0236 30.3548 33.6321 30.2576 33.2399 30.8445C32.8478 31.4314 31.7231 32.7518 31.3801 33.1432C31.0372 33.5346 30.6949 33.5837 30.1068 33.2905C29.5188 32.9974 27.6267 32.3779 25.3818 30.38C23.6351 28.8256 22.4561 26.9059 22.1129 26.3193C21.7696 25.7326 22.0764 25.4149 22.3711 25.1227C22.6348 24.8599 22.9585 24.4378 23.2529 24.0956C23.5473 23.7533 23.6441 23.5086 23.8397 23.1178C24.0353 22.7271 23.9378 22.3838 23.7908 22.0907C23.6438 21.7975 22.5021 18.8969 21.9799 17.737Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_419_8527"
        x="-2.67669"
        y="-2.73705"
        width="61.3531"
        height="61.4742"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="3.531"
          result="effect1_foregroundBlur_419_8527"
        />
      </filter>
      <linearGradient
        id="paint0_linear_419_8527"
        x1="27.4589"
        y1="10.3887"
        x2="27.6563"
        y2="43.7315"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#57D163" />
        <stop offset="1" stop-color="#23B33A" />
      </linearGradient>
      <clipPath id="clip0_419_8527">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconWhatsapp
