import api from 'Api/ApiMethods'
import axios from 'axios'
import parseGeoraster from 'georaster'
import GeoRasterLayer from 'georaster-layer-for-leaflet'

export const SATELLITE_POPUP_CHECKED = 'SATELLITE_POPUP_CHECKED'
export const SOIL_CHART_COLORS = [
  '#ff1400',
  '#ffea00',
  '#09fe00',
  '#006cff',
  '#706567'
]

export const UNIFORMITY_CHART_COLORS = [
  '#F66A4B',
  '#FFB966',
  '#FDED77',
  '#DFFFE7',
  '#7AD894',
  '#0B782B'
]

export const thresholdSoilColorsPallet: any = {
  '1': { textKey: 'very_dry', color: SOIL_CHART_COLORS[0] },
  '2': { textKey: 'dry', color: SOIL_CHART_COLORS[1] },
  '3': { textKey: 'adequate', color: SOIL_CHART_COLORS[2] },
  '4': { textKey: 'over_irrigation', color: SOIL_CHART_COLORS[3] }
  // '5': { textKey: '10%', color: SOIL_CHART_COLORS[4] }
  // 5 is not required as since all '5' values are to be converted to '3'
}

export const thresholdUniformityColorsPallet: any = {
  '0': { textKey: '0', color: UNIFORMITY_CHART_COLORS[0] },
  '1': { textKey: '1', color: UNIFORMITY_CHART_COLORS[1] },
  '2': { textKey: '2', color: UNIFORMITY_CHART_COLORS[2] },
  '3': { textKey: '3', color: UNIFORMITY_CHART_COLORS[3] },
  '4': { textKey: '4', color: UNIFORMITY_CHART_COLORS[4] },
  '5': { textKey: '5', color: UNIFORMITY_CHART_COLORS[5] },
  '6': { textKey: '6', color: UNIFORMITY_CHART_COLORS[5] }
}

export const pixelValuesToColorFn = (values: any): string => {
  const value = values[9] === 5 ? 3 : values[9]
  for (const threshold in thresholdSoilColorsPallet) {
    if (value === +threshold) {
      return thresholdSoilColorsPallet[threshold].color
    }
  }

  return ''
}

export const getTiffFromLink = async (link?: string) => {
  if (!link) {
    return undefined
  }

  try {
    const res = await axios.get(link, {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'application/json' }
    })

    return res.data
  } catch (error) {
    console.error('error in get tiff from link', error)
  }

  return undefined
}

export const getSatelliteByPeriodData = async (
  plotId: string,
  plotUuid: string
): Promise<any> => {
  const satelliteData = await api.getSatelliteByPeriodData(
    plotUuid,
    Date.now() - 3 * 24 * 60 * 60 * 1000,
    Date.now()
  )

  const plotData = satelliteData?.data?.[0]?.[plotId]
  const satelliteDataToday = await getTiffFromLink(plotData?.[0].link)
  return { plotData, satelliteDataToday }
}

export const getSatelliteData = async (
  tifData: any,
  plotId: string,
  plotData: any
): Promise<any> => {
  try {
    const tiffCopy = tifData.slice(0)
    const todayData = plotData?.map((item: any) => item.data)
    const georaster = await parseGeoraster(tiffCopy)
    const layer = new GeoRasterLayer({
      georaster,
      pixelValuesToColorFn,
      opacity: 1,
      resolution: 64
    })
    return { layer, data: todayData }
  } catch (error) {
    console.error(`failed to get satellite data for plot id ${plotId}`)
    return { layer: undefined, data: undefined}
  }
}

export const getPlotHeatwaveAlert = async (
  plotUuid: string,
  typeIds: number[]
) => {
  return await api.getPlotAlertByType(plotUuid, typeIds)
}

export function clamp(number: number, min = 0, max = 1) {
  return Math.max(min, Math.min(number, max))
}

const clampMinMax = <Type>(
  n: Type | number | string,
  clampValues: readonly [min: number, max: number]
) => {
  if (n != null) {
    return clamp(Number(n), clampValues[0], clampValues[1])
  }

  return n
}

export const getTiffGeorasterUniformityLayerFunc = async (
  tifData: any,
  plotData: any
) => {
  if (!tifData) {
    return undefined
  }

  const tiffCopy = tifData.slice(0) // Required to copy arraybuffer
  const todayData = plotData?.map((item: any) => item.data)
  const georaster = await parseGeoraster(tiffCopy)
  const valueArray: number[] = []
  const geoValues = georaster?.values?.[1] || []
  geoValues?.forEach((arr: number[]) => {
    valueArray.push(...(arr || []))
  })
  const filteredArray = valueArray.filter((el) => el)
  const minValue = Math.min(...filteredArray)
  const maxValue = Math.max(...filteredArray)
  const interval = (maxValue - minValue) / UNIFORMITY_CHART_COLORS.length

  const pixelValuesToColorFn = (values: number[]) => {
    const value = values[1] // selecting layer 2 of the tiff file
    if (value != null && !isNaN(value)) {
      const flooredValue = Math.floor((value - minValue) / interval)
      const clamp = clampMinMax(flooredValue, [0, 6]) // set min max bounds to flooredValue
      return thresholdUniformityColorsPallet[`${clamp}`]?.color || ''
    }

    return '' // tiff returns a square , NaN's will be filtered and transparent
  }

  const layer = new GeoRasterLayer({
    georaster,
    pixelValuesToColorFn,
    opacity: 1,
    resolution: 64
  })
  return { layer, data: todayData }
}
