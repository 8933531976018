import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import styled from '@emotion/styled/macro'
import { Grid } from '@mui/material'
import StyledButton from 'core/components/StyledButton'
import {
  NewPlotFormSteps,
  newPlotFormVariableNames,
  initialForm,
  PlotForm,
  Variety,
  AddPlotFormSteps,
  addPlotFormVariableNames,
  PaymentMethods,
  checkIsMasterPoolOrCouponPayment,
  Steps
} from 'utils/formConfig'
import Stepper from 'core/components/Stepper'
import { useEffect } from 'react'
import { constants } from 'chatbot-shared'
import SmsDialog from './SmsDialog'
import { PageProps } from 'pages/Onboarding'
import { toast } from 'react-toastify'
import { TFunction, useTranslation } from 'react-i18next'
import {
  IrrigationUnit,
  Plot,
  UtmData,
  SmsOTCUser,
  OnboardingMode
} from 'utils/types'
import useQueryParams from 'hooks/useQueryParams'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import eventEmitter, { events } from 'utils/eventEmitter'
import analytics from 'utils/analytics'
import {
  getFormNameByIndex,
  PLANT_ONBOARDING_CURRENT_PAGE,
  PLANT_ONBOARDING_CURRENT_STEP,
  PLANT_ONBOARDING_USER_VERIFY,
  PLANT_ONBOARDING_FORM_DATA,
  PLANT_ONBOARDING_USER_COUNTRY,
  PLANT_ONBOARDING_USER_LANG,
  newPlotFormSteps,
  addPlotFormSteps,
  PLANT_ONBOARDING_USER_LOCATION,
  PLANT_ONBOARDING_USER_RESELLER_ID,
  callOtcAccessApi,
  createPlotSpecial,
  PLANT_ONBOARDING_USER_PAYMENT_METHOD,
  postUserLeads,
  UtmObject
} from 'utils/formHelpers'
import { AppContext } from 'core/AppContext'
import verySessionStorage from 'utils/vss'
import { UserContext } from 'core/UserContext'
import { createPlot, getResellerIdByCountry } from 'utils/userManagement'
import { useLocation } from 'react-router-dom'
import vls from 'utils/vls'
import {
  getAddPlotTextByForm,
  getNewPlotTextByForm
} from 'utils/getTextByFormHelpers'
import { skipPlotAgeCropsList } from 'utils/constants'

const { varieties } = constants.crops
// const soils = constants.soils
const GENERAL_ONBOARDING_URL = 'https://app.plantbot.me/onboarding'

export interface FormProps {
  formData: PlotForm
  varietyList?: Variety[]
  utmData?: UtmData
  isLoading?: boolean
  handleChangeFormData: (form: PlotForm) => void
  handlePostOnboarding?: (
    onboardingFormData: PlotForm,
    tFunc: TFunction<string, any>
  ) => void
}

const GridContainer = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 0px 100px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 0px 25px;
    overflow: visible;
  }
`

const MenuButton = styled(StyledButton)`
  body[dir='rtl'] & {
    > span {
      font-size: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    body[dir='rtl'] & {
      > span {
        font-size: 14px;
      }
    }
  }
`

const InputButtonsContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    justify-content: center;
  }
`

const NavigationButtonsContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: row-reverse;
  align-self: flex-start;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex: 1;
    gap: 20px;
    align-self: center;
  }
`

const StyledGrid = styled(Grid)`
  /* @noflip */
  flex-direction: row-reverse;
  body[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`

const GridContentContainer = styled.div`
  display: flex;
  overflow-wrap: anywhere;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const GridFormContentContainer = styled(GridContentContainer)`
  align-items: flex-start;
  padding-top: 10vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding-top: 0px;
  }
`

const GridTextContentContainer = styled(GridContentContainer)`
  align-items: flex-start;
  padding-top: 10vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding-top: 0px;
  }
`

const StyledButtonContainer = styled.div<{
  $mobileOnly?: boolean
}>`
  display: ${({ $mobileOnly }) => ($mobileOnly ? 'none' : 'flex')};
  justify-content: flex-start;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: ${({ $mobileOnly }) => $mobileOnly && 'flex'};
    justify-content: center;
    align-items: center;
  }
`

const GridBody = styled(Grid)`
  min-height: 60vh;
  max-height: 60vh;
  overflow: hidden;
  padding: 5px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-bottom: 100px;
    max-height: unset;
    min-height: 40vh;
    overflow: visible;
  }
`

const GridText = styled(Grid)`
  min-height: 60vh;
  max-height: 60vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    max-height: unset;
    min-height: unset;
  }
`

const GridFooter = styled(Grid)`
  height: 20vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 10vh;
  }
`

const GridFooterButton = styled(Grid)`
  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 100px;
    background: ${({ theme }) => theme.colors.background.transparent};
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const PaddedText = styled.div`
  padding-right: 100px;
  display: flex;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  body[dir='rtl'] & {
    padding-right: 0px;
    padding-left: 100px;

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      overflow: inherit;
      padding-left: unset;
      padding: 0px 8%;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    justify-content: center;
    overflow: inherit;
    padding-left: unset;
    padding: 0px 8%;
  }
`

const StepperContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    justify-content: center;
  }
`

const FormScreen: FC<PageProps> = ({
  handleClickNextPage,
  scrollRef,
  pageNumber,
  currentStep,
  formData
}) => {
  const { t, i18n } = useTranslation('formScreen')
  const appContext = useContext(AppContext)
  const userContext = useContext(UserContext)
  const location = useLocation()
  const lang = useMemo(() => i18n.language, [i18n.language])
  const urlQueryParams = useQueryParams()
  const isKeyboardOpen = useDetectKeyboardOpen()
  const utmData: UtmObject = useMemo(
    () => ({
      utmSource: urlQueryParams.get('utmSource'), // Identifies which site sent the traffic, and is a required parameter. utmSource=google
      utmMedium: urlQueryParams.get('utmMedium'), // 	Identifies what type of link was used, such as cost per click or email. utmMedium=cpc
      utmCampaign: urlQueryParams.get('utmCampaign'), // Identifies a specific product promotion or strategic campaign. utmCampaign=spring_sale
      utmTerm: urlQueryParams.get('utmTerm'), // 	Identifies search terms. utmTerm=running+shoes
      utmContent:
        urlQueryParams.get(
          'utmContent'
        ), /* Identifies what specifically was clicked to bring the user to the site, 
      such as a banner ad or a text link. It is often used for A/B testing and content-targeted ads. utmContent=logolink or utmContent=textlink */
      utm_campaign: urlQueryParams.get('utm_campaign'),
      utm_source: urlQueryParams.get('utm_source'),
      utm_medium: urlQueryParams.get('utm_medium'),
      utm_term: urlQueryParams.get('utm_term'),
      utm_content:
        urlQueryParams.get(
          'utm_content'
        )
    }),
    [urlQueryParams]
  )

  const couponCode = useMemo(
    () => urlQueryParams.get('couponCode'),
    [urlQueryParams]
  )
  const sessionStorageFormData = verySessionStorage.get(
    PLANT_ONBOARDING_FORM_DATA
  )
  const [formScreenData, setFormScreenData] = useState(
    sessionStorageFormData ? sessionStorageFormData : initialForm
  )
  const [smsReqLoading, setSmsReqLoading] = useState(false)
  const [smsReq, setSmsReq] = useState<{
    paymentMethod: PaymentMethods
    smsReqId: ''
  }>({ paymentMethod: PaymentMethods.ECommerce, smsReqId: '' })
  const termsAccepted = useMemo(
    () => formScreenData.contactForm.termsAccepted,
    [formScreenData.contactForm.termsAccepted]
  )
  const sessionStorageFormStep = useMemo(
    () => verySessionStorage.getNumber(PLANT_ONBOARDING_CURRENT_STEP),
    []
  )
  const [currentFormStep, setCurrentFormStep] = useState<
    NewPlotFormSteps | AddPlotFormSteps
  >(sessionStorageFormStep ? sessionStorageFormStep : NewPlotFormSteps.CROP)
  const CurrentForm = useMemo(
    () =>
      userContext.onboardingMode === OnboardingMode.New
        ? newPlotFormSteps[currentFormStep]
        : addPlotFormSteps[currentFormStep],
    [currentFormStep, userContext.onboardingMode]
  )
  const [varietyData, setVarietyData] = useState<Variety[]>([])
  const currentButtonDisabled = useMemo(
    () =>
      userContext.onboardingMode === OnboardingMode.New
        ? !formScreenData[
          newPlotFormVariableNames[currentFormStep] as keyof PlotForm
        ].completed
        : !formScreenData[
          addPlotFormVariableNames[currentFormStep] as keyof PlotForm
        ].completed,
    [userContext.onboardingMode, formScreenData, currentFormStep]
  )
  const [onboardingLoading, setOnboardingLoading] = useState(false)
  const confirmButtonText = useMemo(
    () =>
      currentFormStep === NewPlotFormSteps.PAYMENT
        ? t('finish')
        : t('continue'),
    [currentFormStep, t]
  )
  const isFinalPage = useMemo(
    () => currentFormStep === NewPlotFormSteps.OPTIONS,
    [currentFormStep]
  )
  const backButtonDisabled = useMemo(
    () =>
      formScreenData.paymentForm.paymentSuccessful &&
      !checkIsMasterPoolOrCouponPayment(),
    [formScreenData.paymentForm.paymentSuccessful]
  )

  const [addPlotIrrigationUnit, setAddPlotIrrigationUnit] =
    useState<IrrigationUnit>()

  useEffect(() => {
    if (currentStep) {
      setCurrentFormStep(currentStep)
    }
  }, [currentStep])

  useEffect(() => {
    if (formData) {
      setFormScreenData(formData)
    }
  }, [formData])

  const formText =
    userContext.onboardingMode === OnboardingMode.New
      ? getNewPlotTextByForm(formScreenData, currentFormStep, t)
      : getAddPlotTextByForm(formScreenData, currentFormStep, t)

  const handleChangeFormData = useCallback((newForm: PlotForm) => {
    setFormScreenData(newForm)
    verySessionStorage.set(PLANT_ONBOARDING_FORM_DATA, newForm)
  }, [])

  const handleSendLeads = useCallback(async () => {
    postUserLeads(formScreenData, utmData)
  }, [formScreenData, utmData])

  const handleClickBack = useCallback(() => {
    analytics.sendEvent(analytics.events.onboardingBackClick, {
      value: currentFormStep,
      labelArg: getFormNameByIndex(currentFormStep)
    })
    handleSendLeads()

    if (currentFormStep > 0) {
      setCurrentFormStep((prev) => {
        if (
          userContext.onboardingMode === OnboardingMode.Add &&
          // currentFormStep === AddPlotFormSteps.NAME &&
          addPlotIrrigationUnit !== IrrigationUnit.Time
        ) {
          if (addPlotFormSteps?.[prev - 2]) {
            return prev - 2
          }
        }
        // if (
        //   currentFormStep === NewPlotFormSteps.UNIT_VALUES + 1 &&
        //   formScreenData.plotUnitsForm.selectedUnit !== IrrigationUnit.Time &&
        //   formScreenData.plotUnitsForm.completed
        // ) {
        //   // Move back twice if selected unit is not time (move to unit select)
        //   if (newPlotFormSteps?.[prev - 2]) {
        //     return prev - 2
        //   }
        // }

        return prev - 1
      })
      scrollRef?.scrollTo(0, 0)
    }
  }, [
    addPlotIrrigationUnit,
    currentFormStep,
    scrollRef,
    userContext.onboardingMode,
    handleSendLeads
  ])

  const handlePostOnboarding = useCallback(
    async (onboardingFormData: PlotForm, tFunc: TFunction<string, any>) => {
      try {
        eventEmitter.emit(events.ENABLE_LOADING)
        setOnboardingLoading(true)
        analytics.sendEvent(analytics.events.onboardingSuccess)
        let page = pageNumber
        if (!page) {
          page = verySessionStorage.getNumber(PLANT_ONBOARDING_CURRENT_PAGE)
        }

        handleClickNextPage(page + 1, onboardingFormData)
      } catch (error: any) {
        analytics.sendEvent(analytics.events.onboardingCreateChatFailure)
        if (error?.response?.status === 403) {
          toast.error(
            tFunc<string>('chats_limit_error', {
              chatsLimit: error?.response?.data?.chatsLimit
            })
          )
          return
        }

        toast.error(tFunc<string>('general_server_error'))
      } finally {
        eventEmitter.emit(events.DISABLE_LOADING)
        setOnboardingLoading(false)
      }
    },
    [pageNumber, handleClickNextPage]
  )

  const requestSmsCode = useCallback(
    async (phoneNumber: string) => {
      setSmsReqLoading(true)
      try {
        if (!verySessionStorage.get(PLANT_ONBOARDING_USER_VERIFY)) {
          const resellerId = `${getResellerIdByCountry(appContext.country)}`
          const accessData = await callOtcAccessApi(
            resellerId,
            phoneNumber,
            t,
            lang
          )
          appContext.onChangePaymentMethod(accessData.paymentMethod)
          verySessionStorage.set(
            PLANT_ONBOARDING_USER_PAYMENT_METHOD,
            accessData.paymentMethod
          )

          if (
            appContext.initialPaymentMethod !== PaymentMethods.ECommerce &&
            accessData.paymentMethod === PaymentMethods.ECommerce
          ) {
            if (appContext.initialPaymentMethod === PaymentMethods.MasterPool) {
              toast.error(t<string>('master_pool_unauthorized_user'))
              setTimeout(() => {
                window.location.replace(
                  GENERAL_ONBOARDING_URL +
                  `?lang=${appContext.country}&country=${lang}`
                )
              }, 5000)
            }

            verySessionStorage.set(PLANT_ONBOARDING_USER_RESELLER_ID, undefined)
          }

          setSmsReq({
            paymentMethod: accessData.paymentMethod,
            smsReqId: accessData.accessCode || ''
          })
        } else {
          setCurrentFormStep((prev) => prev + 1)
        }
      } catch (error) {
        toast.error(t<string>('general_server_error'))
      } finally {
        handleSendLeads()
        setSmsReqLoading(false)
      }
    },
    [appContext, lang, t, handleSendLeads]
  )

  const handleClickResend = useCallback(async () => {
    const resellerId = `${getResellerIdByCountry(appContext.country)}`
    const accessData = await callOtcAccessApi(
      resellerId,
      formScreenData.contactForm.userNumber,
      t,
      lang,
      smsReq.paymentMethod
    )
    setSmsReq((prev) => ({ ...prev, smsReqId: accessData?.accessCode || '' }))
  }, [
    appContext.country,
    formScreenData.contactForm.userNumber,
    lang,
    smsReq.paymentMethod,
    t
  ])

  const multiPlotCreateNewPlot = useCallback(async () => {
    if (
      !userContext.userSubscription ||
      !userContext.userSubscription.growerId
    ) {
      toast.error(t<string>('general_server_error'))
      return
    }

    eventEmitter.emit(events.ENABLE_LOADING)
    await createPlot(
      userContext.authToken,
      formScreenData,
      userContext.userSubscription?.growerId,
      appContext.country,
      t
    )
    eventEmitter.emit(events.DISABLE_LOADING)
    handlePostOnboarding(formScreenData, t)
  }, [formScreenData, handlePostOnboarding, t, userContext, appContext.country])

  const handleClickNextForm = useCallback(async () => {
    analytics.sendEvent(analytics.events.onboardingNextClick, {
      value: currentFormStep,
      labelArg: getFormNameByIndex(currentFormStep)
    })
    handleSendLeads()

    if (currentFormStep < newPlotFormSteps.length - 1) {
      if (
        userContext.onboardingMode === OnboardingMode.New &&
        currentFormStep === NewPlotFormSteps.CONTACT
      ) {
        requestSmsCode(formScreenData.contactForm.userNumber)
      } else {
        setCurrentFormStep((prev) => {
          if (
            userContext.onboardingMode === OnboardingMode.Add &&
            currentFormStep === AddPlotFormSteps.LOCATION
          ) {
            multiPlotCreateNewPlot()
            return prev
          }

          const isMarketingMethod =
            currentFormStep === NewPlotFormSteps.LOCATION &&
            (appContext.paymentMethod === PaymentMethods.Coupon ||
              appContext.paymentMethod === PaymentMethods.MasterPool)
          if (isMarketingMethod) {
            const resellerId = `${getResellerIdByCountry(appContext.country)}`
            createPlotSpecial(
              utmData,
              formScreenData,
              lang,
              t,
              resellerId,
              appContext.paymentMethod,
              couponCode || '',
              handlePostOnboarding
            )
            return prev
          }

          // Code to move forward one page
          if (
            userContext.onboardingMode === OnboardingMode.New &&
            newPlotFormSteps?.[prev + 1]
          ) {
            if (currentFormStep === NewPlotFormSteps.CROP && formScreenData.cropForm.cropId === -10) {
              handleClickNextPage(Steps.OtherCrop)
              return prev
            }

            return prev + 1
          }

          // Code to move forward one page
          if (
            userContext.onboardingMode === OnboardingMode.Add &&
            addPlotFormSteps?.[prev + 1]
          ) {
            if (currentFormStep === AddPlotFormSteps.CROP && formScreenData.cropForm.cropId === -10) {
              handleClickNextPage(Steps.OtherCrop)
              return prev
            }

            return prev + 1
          }

          return prev
        })
      }

      scrollRef?.scrollTo(0, 0)
    }
  }, [
    currentFormStep,
    userContext.onboardingMode,
    scrollRef,
    requestSmsCode,
    formScreenData,
    appContext.paymentMethod,
    appContext.country,
    multiPlotCreateNewPlot,
    utmData,
    lang,
    t,
    couponCode,
    handlePostOnboarding,
    handleSendLeads
  ])

  const enterFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (
          !(
            (isFinalPage && !termsAccepted) ||
            currentButtonDisabled ||
            onboardingLoading ||
            isFinalPage
          )
        ) {
          handleClickNextForm()
        }
      }
    },
    [
      currentButtonDisabled,
      handleClickNextForm,
      isFinalPage,
      onboardingLoading,
      termsAccepted
    ]
  )

  useEffect(() => {
    document.addEventListener('keydown', enterFunction)

    return () => {
      document.removeEventListener('keydown', enterFunction)
    }
  }, [enterFunction])

  useEffect(() => {
    async function fetchMyAPI() {
      if (
        formScreenData.cropForm.cropType &&
        currentFormStep === NewPlotFormSteps.VARIETY
      ) {
        const cropVarietyArray =
          varieties[`${formScreenData.cropForm.cropType}`]
        if (cropVarietyArray?.length) {
          setVarietyData(cropVarietyArray)
        }
      }
    }

    fetchMyAPI()
  }, [formScreenData.cropForm.cropType, currentFormStep])

  const handleSmsSuccess = async (
    authToken: string,
    userObject: SmsOTCUser
  ) => {
    setSmsReq((prev) => ({ ...prev, smsReqId: '' })) // close the sms popup, since its finished
    eventEmitter.emit(events.ENABLE_LOADING)
    if (
      userContext.onboardingMode === OnboardingMode.New &&
      skipPlotAgeCropsList.includes(urlQueryParams.get('cropType') || '') &&
      currentFormStep === NewPlotFormSteps.CONTACT
    ) {
      setCurrentFormStep((prev) => prev + 2)
    } else {
      setCurrentFormStep((prev) => prev + 1)
    }

    verySessionStorage.set(PLANT_ONBOARDING_USER_VERIFY, {
      authToken,
      userObject
    })
    handleSendLeads()
    eventEmitter.emit(events.DISABLE_LOADING)
  }

  const handleSmsCloseDialog = useCallback(async () => {
    setSmsReq((prev) => ({ ...prev, smsReqId: '' })) // close the sms popup, since its finished
  }, [])

  useEffect(() => {
    verySessionStorage.set(PLANT_ONBOARDING_CURRENT_PAGE, pageNumber)
    verySessionStorage.set(PLANT_ONBOARDING_CURRENT_STEP, currentFormStep)
    verySessionStorage.set(PLANT_ONBOARDING_USER_LANG, lang)
  }, [currentFormStep, pageNumber, lang])

  useEffect(() => {
    if (urlQueryParams.has('isStripeSuccess')) {
      return
    }

    verySessionStorage.set(PLANT_ONBOARDING_USER_COUNTRY, appContext)
  }, [appContext, urlQueryParams])

  useEffect(() => {
    if (userContext.onboardingMode === OnboardingMode.Add) {
      setAddPlotIrrigationUnit(
        (userContext.userSettings?.userPreferences?.viewPref
          .irrUnit as IrrigationUnit) || IrrigationUnit.M3HA
      )
      // const soilValue = soils.find(
      //   (soil: any) =>
      //     (location.state as unknown as Plot).soil === soil.webfrontSoilTypeId
      // )

      vls.set(PLANT_ONBOARDING_USER_LOCATION, {
        lat: (location.state as unknown as Plot).latitude,
        lng: (location.state as unknown as Plot).longitude
      })

      handleChangeFormData({
        ...formScreenData,
        contactForm: {
          userName: userContext.userSettings?.firstName || '',
          userNumber: userContext.userSettings?.phone || '',
          completed: true,
          termsAccepted: true
        }
        // plotDesiredTimeForm: {
        //   userTime:
        //     userContext.userSettings?.userPreferences?.sendingTimePrefMap?.[
        //       'WA'
        //     ].sendingTimePref.hourFrom + ':00' || '08:00',
        //   completed: true
        // }
        // plotSoilForm: {
        //   soilType: soilValue.level,
        //   growthMethod: undefined,
        //   completed: true
        // }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const resellerId = urlQueryParams.get('resellerId')
    if (!resellerId) {
      return
    }

    verySessionStorage.set(
      PLANT_ONBOARDING_USER_RESELLER_ID,
      urlQueryParams.get('resellerId')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GridContainer id="form-screen">
      <StyledGrid container style={{ position: 'relative' }}>
        <GridBody item xs={12} md={6} order={{ xs: 3, md: 1 }}>
          <GridFormContentContainer>
            <CurrentForm
              varietyList={varietyData}
              isLoading={onboardingLoading}
              formData={formScreenData}
              utmData={utmData}
              handleChangeFormData={handleChangeFormData}
              handlePostOnboarding={handlePostOnboarding}
            />
          </GridFormContentContainer>
        </GridBody>
        <GridText item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <GridTextContentContainer>
            <PaddedText>{formText}</PaddedText>
          </GridTextContentContainer>
        </GridText>
        <GridFooter item xs={12} md={6} order={{ xs: 2, md: 3 }}>
          <GridContentContainer>
            <StageContainer>
              <StepperContainer>
                <Stepper
                  total={newPlotFormSteps.length}
                  currentIndex={currentFormStep}
                  currentStepCompleted={!currentButtonDisabled}
                />
                {/* {currentFormStep > 0 && !isFinalPage && (
                  <BackText onClick={handleClickBack}>
                    {t('back_to_previous_page')}
                  </BackText>
                )} */}
              </StepperContainer>
            </StageContainer>
          </GridContentContainer>
        </GridFooter>
        {!isKeyboardOpen && (
          <GridFooterButton item xs={12} md={6} order={{ xs: 4, md: 4 }}>
            <GridContentContainer>
              <InputButtonsContainer>
                <NavigationButtonsContainer>
                  {!isFinalPage && (
                    <StyledButtonContainer>
                      <MenuButton
                        hasInternalPadding
                        disabled={
                          (isFinalPage && !termsAccepted) ||
                          currentButtonDisabled ||
                          smsReqLoading ||
                          onboardingLoading
                        }
                        onClick={handleClickNextForm}
                        text={confirmButtonText}
                      />
                    </StyledButtonContainer>
                  )}
                  {currentFormStep > 0 && (
                    <StyledButtonContainer>
                      <MenuButton
                        hasInternalPadding
                        altStyle
                        disabled={backButtonDisabled}
                        onClick={handleClickBack}
                        text={t('back')}
                      />
                    </StyledButtonContainer>
                  )}
                </NavigationButtonsContainer>
              </InputButtonsContainer>
            </GridContentContainer>
          </GridFooterButton>
        )}
      </StyledGrid>
      <SmsDialog
        smsReqId={smsReq.smsReqId}
        isOpen={!!smsReq.smsReqId}
        couponCode={couponCode}
        paymentMethod={smsReq.paymentMethod}
        lang={lang}
        userPhoneNumber={formScreenData.contactForm.userNumber}
        onSuccess={handleSmsSuccess}
        onClickResend={handleClickResend}
        onClose={handleSmsCloseDialog}
      />
    </GridContainer>
  )
}

export default FormScreen
