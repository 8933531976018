import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled from '@emotion/styled/macro'
import MainLogo from 'assets/images/icon_supplant_logo.svg'
import MainLogoAlternative from 'assets/images/icon_supplant_logo_alternative.svg'
import { useTranslation } from 'react-i18next'
import LangSelect from 'core/components/LangSelect'
import UpArrowIcon from 'assets/images/icon_arrow_up.svg'
import OnePagerFirstPage from './OnePagerFirstPage'
import OnePagerSecondPage from './OnePagerSecondPage'
import OnePagerThirdPage from './OnePagerThirdPage'
import OnePagerFourthPage from './OnePagerFourthPage'
import analytics from 'utils/analytics'

const OnePagerContainer = styled.div<{
  $onePagerOpen: boolean
  $transitionEnabled: boolean
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ $onePagerOpen }) => ($onePagerOpen ? '0' : '100vh')};
  transition-duration: ${({ $transitionEnabled }) =>
    $transitionEnabled ? '1000ms' : '0ms'};
  height: 100vh;
  width: 100vw;
  z-index: 101;
`

const OnePagerContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background: #052b33;
  position: relative;
`

const OnePagerScroll = styled.div<{
  $pageIndex: number
  $transitionEnabled: boolean
}>`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  background: #052b33;
  transition-duration: ${({ $transitionEnabled }) =>
    $transitionEnabled ? '1000ms' : '0ms'};
  transform: ${({ $pageIndex }) =>
    `translate(calc(-${$pageIndex} * 100vw), 0px)`};
  position: relative;

  body[dir='rtl'] & {
    transform: ${({ $pageIndex }) =>
      `translate(calc(${$pageIndex} * 100vw), 0px)`};
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    transform: unset;

    body[dir='rtl'] & {
      transform: unset;
    }
  }
`

const Main = styled.img`
  height: 150px;
  width: 150px;
  align-self: flex-start;
  margin: 0px 79px;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin: 0px 30px;
    height: 80px;
    width: 80px;
  }
`

const Header = styled.div<{
  $pageIndex: number
  $transitionEnabled: boolean
}>`
  display: flex;
  position: absolute;
  max-height: 10vh;
  min-height: 10vh;
  width: 100vw;
  flex: 1;
  justify-content: space-between;
  direction: rtl;
  z-index: 1;
  transition-duration: ${({ $transitionEnabled }) =>
    $transitionEnabled ? '1000ms' : '0ms'};
  transform: ${({ $pageIndex }) =>
    `translate(calc(${$pageIndex} * 100vw), 0px)`};

  body[dir='rtl'] & {
    transform: ${({ $pageIndex }) =>
      `translate(calc(-${$pageIndex} * 100vw), 0px)`};
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    background: #052b33;
    position: unset;
    transform: unset;

    body[dir='rtl'] & {
      transform: unset;
    }
  }
`

const ScrollButtonContainer = styled.div`
  position: fixed;
  cursor: pointer;
  bottom: 21px;
  right: 21px;
  background: #dcf900;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 105;
`

const ScrollButtonImage = styled.img`
  height: 16px;
  width: 16px;
`

const LanguageContainer = styled.div`
  display: flex;
`

const AboutUsText = styled.span<{ $altStyle?: boolean }>`
  font-size: 22px;
  z-index: 1;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: -0.66px;
  color: ${({ $altStyle }) => ($altStyle ? '#112a32' : '#dcf900')};
  margin-top: 60px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 12px;
    letter-spacing: -0.36px;
    margin-top: 30px;
  }
`

interface OnePagerProps {
  onePagerOpen: boolean
  isMobile: boolean
  handleClickOnePager: () => void
}

const OnePager: FC<OnePagerProps> = ({
  isMobile,
  onePagerOpen,
  handleClickOnePager
}) => {
  const { t } = useTranslation('onePager')
  const [loadTransitionEnabled, setLoadTransitionEnabled] = useState(false)
  const [pageTransitionEnabled, setPageTransitionEnabled] = useState(false)
  const [open, setOpen] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [transitionPageIndex, setTransitionPageIndex] = useState(0)
  const alternativeStyle = useMemo(() => pageIndex === 1, [pageIndex])
  const focusDisabled = useMemo(() => !onePagerOpen, [onePagerOpen])
  const scrollRef = useRef<HTMLDivElement>(null)

  const handleClickOnePagerReturn = useCallback(() => {
    analytics.sendEvent(analytics.events.onePagerReturnClick, {
      value: pageIndex,
      labelArg: `${pageIndex}`
    })
    handleClickOnePager()
  }, [handleClickOnePager, pageIndex])

  const handleChangePageIndex = useCallback((newIndex: number) => {
    setPageIndex((prev) => {
      if (prev < newIndex) {
        analytics.sendEvent(analytics.events.onePagerNextClick, {
          value: prev,
          labelArg: `${newIndex}`
        })
      } else {
        analytics.sendEvent(analytics.events.onePagerBackClick, {
          value: prev,
          labelArg: `${newIndex}`
        })
      }

      return newIndex
    })
  }, [])

  const handleClickScrollToTop = useCallback(() => {
    if (scrollRef?.current?.scrollTo) {
      scrollRef?.current?.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [])

  const handleWheelScroll = useCallback(
    (event: WheelEvent) => {
      if (!loadTransitionEnabled && !pageTransitionEnabled && !isMobile) {
        if (event.deltaY > 10) {
          setPageIndex((prev) => (prev < 3 ? prev + 1 : prev))
        }
        if (event.deltaY < -10) {
          setPageIndex((prev) => (prev > 0 ? prev - 1 : prev))
        }
      }
    },
    [isMobile, loadTransitionEnabled, pageTransitionEnabled]
  )

  // Handling animations and transitions for opening/closing one pager
  useEffect(() => {
    setLoadTransitionEnabled(true)
    setOpen(onePagerOpen)
    const timer = setTimeout(() => {
      setLoadTransitionEnabled(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [onePagerOpen])

  // Handling animations and transitions for for switching between one pager pages
  useEffect(() => {
    setPageTransitionEnabled(true)
    setTransitionPageIndex(pageIndex)
    const timer = setTimeout(() => {
      setPageTransitionEnabled(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [pageIndex])

  // Resetting the current page to the default when closed and no animation is playing
  useEffect(() => {
    if (!loadTransitionEnabled && !open) {
      setPageIndex(0)
    }
  }, [loadTransitionEnabled, open])

  useEffect(() => {
    if (open) {
      window.addEventListener('wheel', handleWheelScroll)
    }
    return () => {
      window.removeEventListener('wheel', handleWheelScroll)
    }
  }, [handleWheelScroll, open])

  return (
    <OnePagerContainer
      $onePagerOpen={open}
      $transitionEnabled={loadTransitionEnabled}
      tabIndex={-1}
    >
      <OnePagerContent>
        <OnePagerScroll
          $pageIndex={transitionPageIndex}
          $transitionEnabled={pageTransitionEnabled}
          ref={scrollRef}
        >
          <Header
            $pageIndex={transitionPageIndex}
            $transitionEnabled={pageTransitionEnabled}
          >
            <LanguageContainer>
              <LangSelect
                tick={open}
                altStyle={alternativeStyle}
                focusDisabled={focusDisabled}
              />
              <AboutUsText
                $altStyle={alternativeStyle}
                onClick={handleClickOnePagerReturn}
              >
                {t('back')}
              </AboutUsText>
            </LanguageContainer>
            <Main src={alternativeStyle ? MainLogoAlternative : MainLogo} />
          </Header>
          <OnePagerFirstPage
            handleChangePageIndex={handleChangePageIndex}
            focusDisabled={focusDisabled}
          />
          <OnePagerSecondPage
            handleChangePageIndex={handleChangePageIndex}
            isMobile={isMobile}
            focusDisabled={focusDisabled}
          />
          <OnePagerThirdPage
            handleChangePageIndex={handleChangePageIndex}
            focusDisabled={focusDisabled}
            isMobile={isMobile}
          />
          <OnePagerFourthPage
            focusDisabled={focusDisabled || (onePagerOpen && pageIndex !== 3)}
            isMobile={isMobile}
            handleChangePageIndex={handleChangePageIndex}
          />
          {!!isMobile && !!open && (
            <ScrollButtonContainer onClick={handleClickScrollToTop}>
              <ScrollButtonImage src={UpArrowIcon} />
            </ScrollButtonContainer>
          )}
        </OnePagerScroll>
      </OnePagerContent>
    </OnePagerContainer>
  )
}

export default OnePager
