import styled from '@emotion/styled/macro'
import React, { FC, useMemo } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

interface TextSize {
  lg: string
  md: string
  sm: string
}

interface LanguageTextSize {
  he?: TextSize
  es?: TextSize
  en?: TextSize
}

const DEFAULT_SIZES: TextSize = { lg: '110px', md: '70px', sm: '50px' }
const DEFAULT_SIZES_SMALL: TextSize = { lg: '80px', md: '55px', sm: '40px' }
const DEFAULT_SUBTEXT_SIZES: TextSize = { lg: '24px', md: '18px', sm: '16px' }

interface TriColoredTextProps {
  text1?: string
  text2?: string
  text3?: string
  subText?: string | JSX.Element
  breakLine1?: boolean
  breakLine2?: boolean
  isSmaller?: boolean
  textCustomSizes?: TextSize
  textLanguageCustomSizes?: LanguageTextSize
  subtextLanguageCustomSizes?: LanguageTextSize
  responsiveHeight?: boolean
  subtextCustomSizes?: TextSize
  textAlign?: 'right' | 'center' | 'left' | 'start' | 'end'
  isFormText?: boolean
}

const TextContainer = styled.div<{
  $textAlign: string
  $isFormText?: boolean
  $isSmaller?: boolean
  $textSizes: TextSize
  $responsiveHeight: boolean
}>`
  text-align: ${({ $textAlign }) => `${$textAlign}`};
  line-height: ${({ $isFormText }) => ($isFormText ? `0.9` : `1.1`)};
  letter-spacing: -2.5px;
  font-weight: 900;
  font-size: ${({ $textSizes }) => $textSizes.lg};
  word-break: break-word;

  @media (max-width: ${({ theme }) => theme.media.width.md}) {
    font-size: ${({ $textSizes }) => $textSizes.md};
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: ${({ $textSizes }) => $textSizes.sm};
    text-align: center;
    line-height: 0.85;
  }

  ${({ $responsiveHeight }) =>
    $responsiveHeight &&
    `
    @media (max-height: 899px) and (min-width: 899px) {
    font-size: ${DEFAULT_SIZES_SMALL.md};
  }
  `}
`

const RotatingTextSpanWhite = styled.span`
  color: ${({ theme }) => theme.colors.palette.primary};
`

const RotatingTextSpanGreen = styled.span`
  color: ${({ theme }) => theme.colors.palette.secondary};
`

export const RotatingTextSpanSubtext = styled.div<{
  $textSizes?: TextSize
  $responsiveHeight?: boolean
}>`
  color: ${({ theme }) => theme.colors.text.description};
  letter-spacing: -0.9px;
  line-height: 1.33;
  margin-top: 20px;
  font-size: ${({ $textSizes }) => $textSizes?.lg || DEFAULT_SUBTEXT_SIZES.lg};
  white-space: break-spaces;
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.media.width.md}) {
    font-size: ${({ $textSizes }) =>
      $textSizes?.md || DEFAULT_SUBTEXT_SIZES.md};
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: ${({ $textSizes }) =>
      $textSizes?.sm || DEFAULT_SUBTEXT_SIZES.sm};
    text-align: center;
    line-height: 1.11;
    letter-spacing: -0.5px;
  }

  ${({ $responsiveHeight }) =>
    $responsiveHeight &&
    `
    @media (max-height: 899px) and (min-width: 899px) {
    font-size: ${DEFAULT_SUBTEXT_SIZES.sm};
  }
  `}
`

export const RotatingTextLinkSubtext = styled.a`
  color: #b7d2cd;
  letter-spacing: -0.9px;
  line-height: 1.33;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 400;
  text-decoration: underline;
  text-underline-offset: 3px;

  :hover {
    color: #dcf904;
  }

  @media (max-width: ${({ theme }) => theme.media.width.md}) {
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 18px;
    text-align: center;
    line-height: 1.11;
    letter-spacing: -0.5px;
  }
`

const TriColoredText: FC<TriColoredTextProps> = ({
  text1,
  breakLine1,
  text2,
  breakLine2,
  text3,
  subText,
  textAlign = 'center',
  isSmaller,
  textCustomSizes,
  subtextCustomSizes,
  textLanguageCustomSizes,
  subtextLanguageCustomSizes,
  responsiveHeight,
  isFormText,
  ...rest
}) => {
  const { i18n } = useTranslation('')
  const textSizes: TextSize = useMemo(() => {
    const selectedCustomLanguageSize =
      textLanguageCustomSizes?.[i18n.language as 'es' | 'en' | 'he']
    if (selectedCustomLanguageSize) {
      return selectedCustomLanguageSize
    }

    if (textCustomSizes) {
      return textCustomSizes
    }

    if (isSmaller) {
      return DEFAULT_SIZES_SMALL
    }

    return DEFAULT_SIZES
  }, [textLanguageCustomSizes, textCustomSizes, isSmaller, i18n.language])

  const subtextSizes: TextSize = useMemo(() => {
    const selectedCustomLanguageSize =
      subtextLanguageCustomSizes?.[i18n.language as 'es' | 'en' | 'he']
    if (selectedCustomLanguageSize) {
      return selectedCustomLanguageSize
    }

    if (subtextCustomSizes) {
      return subtextCustomSizes
    }

    return DEFAULT_SUBTEXT_SIZES
  }, [subtextLanguageCustomSizes, i18n.language, subtextCustomSizes])

  const subTextComponent = useMemo(() => {
    if (typeof subText === 'function') {
      return subText
    }
    return (
      <RotatingTextSpanSubtext
        $textSizes={subtextSizes}
        $responsiveHeight={!!responsiveHeight}
      >
        {subText}
      </RotatingTextSpanSubtext>
    )
  }, [responsiveHeight, subText, subtextSizes])

  return (
    <TextContainer
      $textAlign={textAlign}
      $isFormText={isFormText}
      $isSmaller={isSmaller}
      $textSizes={textSizes}
      $responsiveHeight={!!responsiveHeight}
      {...rest}
    >
      {!!text1 && <RotatingTextSpanWhite>{text1}</RotatingTextSpanWhite>}
      {!!breakLine1 && <br />}
      {!!text2 && <RotatingTextSpanGreen>{text2}</RotatingTextSpanGreen>}
      {!!breakLine2 && <br />}
      {!!text3 && <RotatingTextSpanWhite>{text3}</RotatingTextSpanWhite>}
      {!!subText && (
        <>
          <br />
          {subTextComponent}
        </>
      )}
    </TextContainer>
  )
}

export default memo(TriColoredText)
