import React, { SVGProps } from 'react'

const IconCoffee = ({ fill }: SVGProps<unknown>) => (
  <svg
    width="56"
    height="48"
    viewBox="0 0 56 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons" clip-path="url(#clip0_371_3529)">
      <path
        id="Vector"
        d="M37.6713 38.6316C37.4313 38.6316 37.1914 38.5481 36.9932 38.3812C32.2046 34.2812 26.112 32.2782 19.8108 32.7581C12.9671 33.2797 6.32156 31.1098 1.11573 26.6446C0.677569 26.2691 0.625406 25.6118 1.00098 25.1737C1.37655 24.7355 2.0338 24.6833 2.47196 25.0589C7.26049 29.1693 13.3844 31.1619 19.6543 30.6716C26.498 30.15 33.1436 32.3199 38.3494 36.7955C38.7875 37.171 38.8397 37.8283 38.4641 38.2665C38.2555 38.5064 37.9634 38.6316 37.6713 38.6316Z"
        fill="#313133"
      />
      <path
        id="Vector_2"
        d="M33.6022 16.9007C33.1536 16.9007 32.7363 16.6086 32.6007 16.1495C32.4338 15.5966 32.7572 15.0124 33.2997 14.8559C35.2297 14.2821 37.045 13.4058 38.7037 12.2373C43.1793 9.07627 48.6146 7.84524 54.0082 8.78416C54.5716 8.87806 54.9576 9.42055 54.8637 9.99434C54.7698 10.5577 54.2377 10.9437 53.6535 10.8498C48.8128 10.0152 43.92 11.121 39.9035 13.9587C38.0569 15.2628 36.033 16.2434 33.8839 16.8798C33.79 16.9111 33.6857 16.9215 33.5918 16.9215L33.6022 16.9007Z"
        fill="#313133"
      />
      <path
        id="Vector_3"
        d="M21.9815 48C19.8846 48 17.7355 47.7183 15.6072 47.1445C10.4953 45.7779 6.11363 42.9089 3.24469 39.0802C0.323586 35.168 -0.667503 30.7238 0.448777 26.5612C1.56506 22.3986 4.64265 19.0393 9.12863 17.1198C13.5207 15.2315 18.7578 14.9498 23.8698 16.3165C34.5005 19.1645 41.3025 28.3973 39.0178 36.8998C37.9015 41.0624 34.8239 44.4217 30.3379 46.3412C27.7715 47.4471 24.9235 48 21.9815 48ZM17.4851 17.5371C14.8248 17.5371 12.2584 18.0378 9.9528 19.0289C6.0719 20.6981 3.41161 23.5671 2.46225 27.0932C1.51289 30.6299 2.37879 34.4377 4.91389 37.8283C7.50116 41.2919 11.4864 43.8792 16.1393 45.1311C20.7922 46.383 25.5494 46.1326 29.5138 44.4217C33.3947 42.7525 36.055 39.8835 37.0043 36.3573C38.9865 28.9711 32.8522 20.8755 23.3273 18.3299C21.3764 17.8083 19.4047 17.5475 17.4851 17.5475V17.5371Z"
        fill="#313133"
      />
      <path
        id="Vector_4"
        d="M42.5239 25.4553C42.0648 25.4553 41.6475 25.1528 41.5224 24.6938C41.3659 24.1408 41.6893 23.5671 42.2422 23.4106C45.8519 22.3882 48.9294 20.3225 50.9012 17.5997C52.8208 14.9498 53.4467 12.0078 52.6747 9.29537C51.9027 6.58292 49.8162 4.40252 46.8012 3.16105C43.6923 1.87785 39.9992 1.74223 36.3791 2.76462C31.6428 4.09998 27.7827 7.33406 26.2805 11.1837C26.0718 11.7157 25.4667 11.987 24.9347 11.7783C24.4026 11.5697 24.1314 10.9646 24.34 10.4325C26.0927 5.91524 30.3804 2.29515 35.8158 0.761575C39.874 -0.396434 44.0575 -0.219081 47.5941 1.23104C51.2142 2.72289 53.7284 5.38318 54.6778 8.73202C55.6271 12.0704 54.8864 15.6592 52.5808 18.8307C50.3378 21.9291 46.8638 24.266 42.8056 25.424C42.7117 25.4553 42.6178 25.4658 42.5239 25.4658V25.4553Z"
        fill="#313133"
      />
    </g>
    <defs>
      <clipPath id="clip0_371_3529">
        <rect width="55.0837" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconCoffee
