import { PaymentMethods } from "./formConfig"

export interface UtmData {
  utmSource?: string | null
  utmMedium?: string | null
  utmCampaign?: string | null
  utmTerm?: string | null
  utmContent?: string | null
}

export interface Chat {
  growerId: number
  growerName: string
  growerPhoneNumber: string
  growerEmail: string
  growerCountry: string
  growerLanguage: string
  plotId: number
  plotName: string
  chatCreationDate: number
  chatSendingHour: number
  chatIrrigationUnits: string
  satelliteOrderId: number
  satelliteOrderUuid: string
  satelliteAlertsSentDate: any
  isForTestingOnly: boolean
  isActive: boolean
  submittedPlotInfo: any
  payment: any
  polygon: any
  geojson: any
  utmData: UtmData
}

export interface LeadMongo {
  growerName: string
  growerPhoneNumber: string
  growerLanguage: string
  isConverted: boolean
  growerCountry?: string
  creationDate?: number
}

export enum PaymentOption {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export enum IrrigationUnit {
  MM = 'MM',
  M3HA = 'M3HA',
  Time = 'TIME'
}

export interface SmsOTCUser {
  id: string
  firstName: string | null
  lastName: string | null
  email: string | null
  lastLogin: string | null
  locale: string | null
  preferredMeasurementSystem: string | null
  clientId: string | null
  gaRelevant: boolean
  phone: string | null
  userPreferences?: UserPreferences
}

interface SendingTimePref {
  hourFrom: number
  hourTo: number
  weekDays: number
}

interface ViewPref {
  irrUnit: string
}

export interface PreferenceType {
  sendingTimePref: SendingTimePref
}

export interface PreferenceSendingTime {
  [key: string]: PreferenceType
}

export interface UserPreferences {
  sendingTimePrefMap: PreferenceSendingTime
  viewPref: ViewPref
  profileId: number
}

export interface BotUser {
  id: string | number
  firstName: string | null
  lastName: string | null
  email: string | null
  companyRoleId: number | null
  active: boolean
  preferredMeasurementSystem: string | null
  gaRelevant: boolean
  userApi: unknown
  locale: string | null
  utm: UtmData
  userPreferences: UserPreferences
  phone: string | null
}

export interface SlimGrower {
  id: number
  resellerId: number
  name: string
  plotCount: number
  userGrowerRole: string
  criticalAlerts: number
  otherAlerts: number
  growerType: string
  isActive: boolean
}

export interface Plot {
  id: number
  uuid: string,
  growthMethod: string | null
  growerId?: number
  area: any
  crop?: number
  cropProtocolId: string | null
  description: string | null
  endTime?: string
  growingType?: string
  latitude?: number
  longitude?: number
  name?: string
  plantTime?: number
  sampleFrequency?: string
  serviceLevel: string | null
  irrigationMethod: string | null
  soil?: number
  startTime?: number
  timeZone?: string
  startSeason?: number
  variety: string | null
  category: string | null
  pendingProtocolId: string | null
  previousProtocolId: string | null
  cropName?: string | null
  geojson: any
}

export interface UserSubscription {
  activePlots: number | null
  customerId: string | null
  growerId: number | null
  resellerId: number | null
  plotPlan: number | null
  subscriptionId: string | null
  userId: number | null
  sendingTime: string | null
  paymentType: PaymentMethods
  serviceContractId: string | null
}

export enum UserSettingsAction {
  AddPlot,
  UpdateSubscription,
  UserSettingsDashboard
}

export interface SubscriptionPlan {
  plan: number
  description: string
  price: string
  priceId: PriceId
}

export interface PriceId {
  live: string
  test: string
}

export enum OnboardingMode {
  New,
  Add
}

export interface SatelliteAlertsType {
  isStressAlert: boolean
  isUnderIrrigationAlert: boolean
  isOverIrrigationAlert: boolean
  date: string
}

export interface HeatWaveAlert {
  isDryNight: boolean
  isDryTomorrow: boolean
  isDryAfterTomorrow: boolean
  date: string
}

export interface PlotCenter {
  latitude?: number
  longitude?: number
}

export enum GrowthMethod {
  General = 'General',
  Mulch = 'Mulch'
}

export interface MapFeatureCollection {
  type: string;
  crs: Crs;
  features?: (FeaturesEntity)[] | null;
}
export interface Crs {
  type: string;
  properties: Properties;
}
export interface Properties {
  name: string;
}
export interface FeaturesEntity {
  type: string;
  properties: Properties1;
  geometry: Geometry;
}
export interface Properties1 {
  area: number;
}
export interface Geometry {
  type: string;
  coordinates?: ((((number)[] | null)[] | null)[] | null)[] | null;
}
