import { TFunction } from 'react-i18next'

const areaPercentageOverIrrigation = 10
const waterUnlimitedCropProductionSensitivity = 0.8

export const generateSatelliteAlerts = (fieldData: any, t: TFunction) => {
  if (!fieldData.length) {
    return
  }

  const isUnderIrrigationAlert =
    fieldData.length === 2 &&
    fieldData.every(
      (dailyFieldData: any) =>
        dailyFieldData.soilMoistureRootZone <
        dailyFieldData.criticalSoilMoistureRootZone
    )

  const isOverIrrigationAlert =
    fieldData.length === 2 &&
    fieldData.every(
      (dailyFieldData: any) =>
        dailyFieldData.areaPercentageOverIrrigation >
        areaPercentageOverIrrigation
    )

  const isStressAlert =
    fieldData[0].waterUnlimitedCropProduction *
      waterUnlimitedCropProductionSensitivity >
    fieldData[0].actualCropProduction

  return {
    isStressAlert,
    isOverIrrigationAlert,
    isUnderIrrigationAlert,
    date: getDateOfAlerts(fieldData[0].date, t)
  }
}

export const generateHeatWavesAlerts = (heatwave: any, t: TFunction) => {
  if (!heatwave || !heatwave.length) {
    return
  }
  
  const isDryNight = heatwave.find((item: any) => item.type === 'DRY_NIGHT_EXPECTED')
  const isDryTomorrow = heatwave.find((item: any) => item.type === 'DRY_TOMORROW_EXPECTED')
  const isDryAfterTomorrow = heatwave.find((item: any) => item.type === 'DRY_DAY_AFTER_TOMORROW_EXPECTED')
  return {
    isDryNight: Boolean(isDryNight),
    isDryTomorrow: Boolean(isDryTomorrow),
    isDryAfterTomorrow: Boolean(isDryAfterTomorrow),
    date: getDateOfAlerts(heatwave[0]?.timestamp, t)
  }
}

const getDateOfAlerts = (alertsDate: string | number, t: TFunction) => {
  const date = new Date(alertsDate)
  const diff = getDaysBetweenDates(date, new Date())

  if (diff === 0) {
    return t('today')
  } else if (diff === 1) {
    return t('yesterday')
  }

  return t('days_before', { days: diff })
}

export const getDaysBetweenDates = (date1: Date, date2: Date) => {
  const differenceMs = Math.abs(date2.getTime() - date1.getTime())
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24))
  return days
}
