import { css } from '@emotion/react'
import '@fontsource/heebo/hebrew.css'
import '@fontsource/heebo/latin.css'
import 'rmc-picker/assets/index.css'
import 'assets/fonts/Metropolis/Metropolis-Black.woff'
import 'assets/fonts/Metropolis/Metropolis-Bold.woff'
import 'assets/fonts/Metropolis/Metropolis-Light.woff'
import 'assets/fonts/Metropolis/Metropolis-Medium.woff'
import 'assets/fonts/Metropolis/Metropolis-Regular.woff'
import 'assets/fonts/Metropolis/Metropolis-SemiBold.woff'
import 'assets/fonts/Metropolis/metropolis.css'
import 'assets/fonts/Metropolis/simplerPro.css'
import theme, { mediaQueries } from './theme'

const globalStyles = css`
  html {
    box-sizing: border-box;
    font-size: calc(16px + 0.25vw);

    @media (max-width: ${mediaQueries.width.sm}) {
      display: flex;
      flex: 1;
      height: 100%;
    }
  }

  body {
    font-family: ${(theme as any)?.fonts.metropolis};
    font-weight: 900;
    min-width: 100vw;
    min-height: 100vh;
    background: ${theme.colors.background.default};
    direction: ltr;
    overflow: hidden;

    @media (max-width: ${mediaQueries.width.sm}) {
      display: flex;
      min-height: 100%;
    }
  }

  body[dir='rtl'] & {
    direction: rtl;
    font-family: 'SimplerPro';
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    outline: none;
    border: none;
  }

  .label {
    margin-bottom: 0.5rem;
  }

  .modes {
    margin-bottom: 1rem;
  }

  .fade-enter {
    overflow: hidden;
  }

  .fade-enter div {
    opacity: 0;
    transform: translateX(-100%);
  }

  .fade-enter-active div {
    opacity: 1;
    transform: translateX(0%);
  }

  .fade-exit div {
    opacity: 1;
    transform: translateX(0%);
  }

  .fade-exit-active div {
    opacity: 0;
    transform: translateX(100%);
  }

  .fade-enter-active div,
  .fade-exit-active div {
    transition: opacity 500ms, transform 500ms;
  }

  /* geo tiff map viewer legend styles */
  .legend {
    position: relative;
    text-align: center;
    font-family: Metropolis;
    bottom: 60px;
    color: white;

    body[dir='rtl'] & {
      font-family: 'SimplerPro';
    }
  }

  .legend > h2 {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .legend > h3 {
    font-family: Metropolis;
    font-size: 14px;
    font-weight: 900;
  }

  .legend > div {
    background: rgba(255, 255, 255, 0.8);
    line-height: 24px;
    border-radius: 5px;
    opacity: 0.95;
    padding: 8px;
    padding-bottom: 30px;
  }

  .legend > div > h4 {
    font-size: 18px;
    margin: 2px 0px;
    color: #052b33;
    font-weight: bolder;
    margin-bottom: 8px;
    white-space: break-spaces;
  }

  .legend > div > span {
    position: relative;
    bottom: 3px;
    float: left;
    color: #052b33;
    font-size: 16px;
    font-weight: normal;

    body[dir='rtl'] & {
      float: right;
    }
  }

  .legend > div > i {
    width: 16px;
    height: 16px;
    float: left;
    margin: 0 8px 0 0;
  }
  /* eof geo tiff map viewer legend styles */

  .lang-popover-one-pager {
    z-index: 101;
  }

  .btn-payment-confirm {
    font-size: 20px;
    height: 70px;
    margin-top: 15px;

    @media (max-width: ${mediaQueries.width.sm}) {
      font-size: 14px;
      height: 51px;
      margin-top: 20px;
    }
  }

  .pac-container {
    min-width: 169px;
    top: 40px !important;
    left: 0px !important;
    border-top: 1px transparent;

    @media (pointer: none), (pointer: coarse) {
      min-width: 193px;
    }
  }

  .payment-ep-input-container {
    @media (max-width: 767px) {
      padding-top: 0.5rem !important;
    }
  }

  .cvv-input-container {
    @media (max-width: 767px) {
      padding-top: 0.5rem !important;
    }
  }

  .sms-dialog {
    max-width: 90vw;
    max-height: 90vh;
    position: fixed;

    @media (max-width: ${mediaQueries.width.sm}) {
      margin: 24px;
    }
  }

  .custom-dialog {
    margin: 8px;
    background-color: transparent;
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  .phone-select {
    direction: ltr;

    .MuiPaper-root {
      background: #052f37;
    }

    .MuiList-root {
      padding: 0px;
      background: #052f37;
      max-height: 170px;
    }
  }
`

export default globalStyles
