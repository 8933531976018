import React, { SVGProps } from 'react'

const IconPlum = ({ fill }: SVGProps<unknown>) => (
  <svg
    width="33"
    height="48"
    viewBox="0 0 33 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Plum" clip-path="url(#clip0_371_4551)">
      <path
        id="Vector"
        d="M20.3414 9.89867C19.3174 9.824 18.2827 9.89867 17.2907 10.144C17.3014 9.36533 17.2267 8.58667 17.0667 7.81867C18.496 8.74667 20.16 9.26933 21.8667 9.32267C22.0267 9.32267 22.1867 9.32267 22.3467 9.30133C26.592 9.01333 29.7814 4.90667 29.92 4.736C30.1974 4.384 30.176 3.88267 29.8667 3.552C29.728 3.41333 26.528 0 22.496 0C22.336 0 22.176 0 22.016 0.0106667C18.9654 0.224 16.4587 2.37867 15.1787 3.73333C14.8587 3.30667 14.496 2.912 14.112 2.53867C13.7387 2.19733 13.152 2.21867 12.8107 2.592C12.4694 2.96533 12.4907 3.54133 12.864 3.88267C14.5174 5.48267 15.4454 7.68 15.4454 9.984C14.048 9.6 12.576 9.536 11.1467 9.80267C3.69069 11.136 -1.18398 20.736 0.256024 31.2107C1.56802 40.7787 7.77603 48 14.688 48C16.0854 48 17.4614 47.7013 18.7307 47.1253C22.0694 46.912 25.28 44.9813 27.7654 41.6853C30.3254 38.1333 31.8294 33.9413 32.1174 29.5787C32.9494 19.3173 27.6694 10.4853 20.3414 9.888M16.4374 5.088C17.3654 4.096 19.5947 2.02667 22.144 1.856C22.2614 1.84533 22.3787 1.856 22.4854 1.84533C24.512 2.016 26.4107 2.85867 27.8934 4.24533C26.9654 5.23733 24.736 7.30667 22.1867 7.47733C22.0694 7.488 21.952 7.49867 21.8454 7.488C19.8187 7.33867 17.9094 6.48533 16.4374 5.088ZM14.688 46.1547C8.68269 46.1547 3.26402 39.6267 2.08002 30.9547C0.757358 21.4507 4.97069 12.7787 11.456 11.616C11.9254 11.5307 12.4054 11.488 12.8854 11.488C18.88 11.488 24.3094 18.016 25.4934 26.688C26.8054 36.192 22.592 44.8747 16.1067 46.0373C15.6374 46.1227 15.1574 46.1653 14.6774 46.1653M27.3174 26.4427C26.4534 20.1813 23.3494 14.5813 19.168 11.7227C19.5094 11.7013 19.8507 11.712 20.192 11.7333C23.2214 11.9787 25.9414 13.9413 27.8507 17.2587C29.856 20.9813 30.7094 25.2267 30.2827 29.44C30.016 33.4507 28.64 37.312 26.2934 40.5867C25.4614 41.7067 24.4587 42.6987 23.328 43.5093C26.688 39.3387 28.2134 32.9067 27.328 26.4427"
        fill="#052B33"
      />
      <path
        id="Vector_2"
        d="M7.39114 18.72C7.1778 18.6133 6.9218 18.592 6.68714 18.6666C6.45247 18.7413 6.26047 18.912 6.1538 19.136C4.60714 22.4533 4.02047 26.144 4.43647 29.7813C4.47914 30.2506 4.88447 30.6133 5.3538 30.6133H5.5778V30.5813C6.01514 30.464 6.30314 30.048 6.26047 29.6C5.88714 26.304 6.42047 22.976 7.79647 19.9573C8.02047 19.4986 7.83914 18.9546 7.38047 18.7306"
        fill="#052B33"
      />
    </g>
    <defs>
      <clipPath id="clip0_371_4551">
        <rect width="32.2027" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconPlum
