import React, { FC, useCallback, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import { FormProps } from './FormScreen'
import { Crop, getCropIcon } from 'utils/formConfig'
import { constants } from 'chatbot-shared'
import { useTranslation } from 'react-i18next'
import { theme } from 'ui'

const cropList: Crop[] = constants.crops.types

interface CropItemProps {
  crop: Crop
  isSelected: boolean
  handleSelectCrop: (crop: Crop) => void
}

const CropFormContent = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 40px;
  align-items: flex-start;
  margin-bottom: auto;
  padding-bottom: 8px;

  @media (max-width: 1920px) {
    gap: 45px;
  }

  @media (max-width: 1760px) {
    gap: 40px;
  }

  @media (max-width: 1400px) {
    gap: 35px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 0;
    gap: 15px;
    justify-content: center;
  }
`

const CropIconContainer = styled.div`
  display: flex;
  height: 50px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: auto;

    svg {
      height: 35px;
      width: 38px;
    }
  }
`

const CropItemContainer = styled.div<{ isSelected: boolean }>`
  gap: 4px;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  background: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.sections.crops.backgroundSelected
      : theme.colors.sections.crops.background};
  border: ${({ isSelected, theme }) =>
    `1px solid ${
      isSelected
        ? theme.colors.sections.crops.borderSelected
        : theme.colors.sections.crops.border
    }`};
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 100px;
    width: 100px;
  }
`

const CropItemText = styled.span<{ isSelected: boolean }>`
  text-align: center;
  font-size: 16px;
  color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.sections.crops.iconLabelSelected
      : theme.colors.sections.crops.iconLabel};
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 12px;
  }
`

const CropFormContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-x: hidden;
`

const CropItem: FC<CropItemProps> = ({
  crop,
  isSelected,
  handleSelectCrop
}) => {
  const { t } = useTranslation('crop')
  const handleClickCropItem = useCallback(() => {
    handleSelectCrop(crop)
  }, [crop, handleSelectCrop])

  const CropIcon = useMemo(() => getCropIcon(crop.value), [crop.value])

  return (
    <CropItemContainer isSelected={isSelected} onClick={handleClickCropItem}>
      {CropIcon && (
        <CropIconContainer>
          <CropIcon
            fill={
              isSelected
                ? theme.colors.sections.crops.iconSelected
                : theme.colors.sections.crops.icon
            }
          />
        </CropIconContainer>
      )}
      <CropItemText isSelected={isSelected}>{t(crop.name)}</CropItemText>
    </CropItemContainer>
  )
}

const CropForm: FC<FormProps> = ({ formData, handleChangeFormData }) => {
  const selectedType = useMemo(() => formData.cropForm.cropType, [formData])

  const handleSelectCrop = useCallback(
    (crop: Crop) => {
      handleChangeFormData({
        ...formData,
        cropForm: {
          ...formData.cropForm,
          cropType: crop.value,
          displayName: crop.name,
          cropId: crop.id,
          completed: true
        },
        varietyForm: {
          ...formData.varietyForm,
          varietyType: '',
          completed: false
        }
      })
    },
    [formData, handleChangeFormData]
  )

  return (
    <CropFormContainer id="crop-selection-form">
      <CropFormContent>
        {cropList.map((crop) => (
          <CropItem
            key={crop.name}
            isSelected={selectedType === crop.value}
            crop={crop}
            handleSelectCrop={handleSelectCrop}
          />
        ))}
      </CropFormContent>
    </CropFormContainer>
  )
}

export default CropForm
