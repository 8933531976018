import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import MainLogo from 'assets/images/icon_supplant_logo.svg'
import SunDoLogo from 'assets/images/icon_sun_do.svg'
import useQueryParams from 'hooks/useQueryParams'
import WhatsAppContact from '../WhatsAppContact'
import LangSelect from '../LangSelect'
import { useTranslation } from 'react-i18next'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import analytics from 'utils/analytics'
import { HeaderLogoProps } from '../HeaderLogo'

const PHONE_URL = process.env.REACT_APP_WHATSAPP_PHONE_URL

const HeaderContainer = styled.div`
  overflow: hidden;
  min-height: 20vh;
  height: 20vh;
  display: flex;
  direction: ltr;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    min-height: 15%;
    height: 15%;
  }
`

const GridContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  z-index: 1;
`

const MainLogoContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex: 1;
  }
`

const AboutUsText = styled.span`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.66px;
  color: ${({ theme }) => theme.colors.palette.secondary};

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 18px;
    letter-spacing: -0.36px;
  }
`

const HelpText = styled(AboutUsText)``

const HelpContainer = styled.a`
  display: flex;
  align-self: flex-start;
  margin: 65px 0px 0px 0px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin: 32px 0px 0px 0px;
  }
`

const AboutUsContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin: 60px 0px 0px 0px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin: 30px 0px 0px 0px;
  }
`

const UserSettingsContainer = styled(AboutUsContainer)`
  margin: 70px 0px 0px 0px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin: 34px 0px 0px 0px;
  }
`

const UserSettingsText = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.66px;
  color: ${({ theme }) => theme.colors.palette.secondary};

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    visibility: hidden;
    width: 0;
  }
`

const UserLogoutText = styled(UserSettingsText)`
  color: ${({ theme }) => theme.colors.text.description};

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 12px;
    visibility: visible;
    width: 100%;
  }
`

const GridFirstContentContainer = styled(GridContentContainer)`
  justify-content: flex-end;
`

const GridLastContentContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: none;
  }
`

const Main = styled.img`
  height: 150px;
  width: 150px;
  align-self: flex-start;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 80px;
    width: 80px;
    margin: 0px 30px;
  }
`

const EndLogo = styled.img`
  height: 60px;
  width: 160px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 80px;
    width: 80px;
  }
`

const DefaultHeader: FC<HeaderLogoProps> = ({
  onePagerOpen,
  isLogin = false,
  userSettingsHeaderText = '',
  userLogoutText = '',
  contactUsMessage,
  handleClickOnePager,
  handleClickLogo,
  logoutClick
}) => {
  const { t } = useTranslation('general')
  const urlQueryParams = useQueryParams()
  const isKeyboardOpen = useDetectKeyboardOpen()
  const isSunDoQueryParam = useMemo(
    () => urlQueryParams.get('sundo') === 'true',
    [urlQueryParams]
  )

  const handleWhatsAppClick = () => {
    analytics.sendEvent(analytics.events.onboardingContactUsClick)
  }

  return (
    <>
      {!isKeyboardOpen && (
        <WhatsAppContact contactUsMessage={contactUsMessage} />
      )}
      <HeaderContainer>
        <GridFirstContentContainer>
          {!isLogin ? (
            <HelpContainer
              onClick={handleWhatsAppClick}
              target="_blank"
              href={PHONE_URL + '?text=' + contactUsMessage}
            >
              <HelpText>{t('help')}</HelpText>
            </HelpContainer>
          ) : (
            <UserSettingsContainer>
              <UserSettingsText>{userSettingsHeaderText}</UserSettingsText>
              &nbsp;&nbsp;
              <UserLogoutText onClick={logoutClick}>
                {userLogoutText}
              </UserLogoutText>
            </UserSettingsContainer>
          )}
          <LangSelect tick={onePagerOpen} />
          {isSunDoQueryParam && <EndLogo src={SunDoLogo} />}
        </GridFirstContentContainer>
        <GridContentContainer>
          <MainLogoContainer>
            <Main src={MainLogo} onClick={handleClickLogo} />
          </MainLogoContainer>
        </GridContentContainer>
        <GridLastContentContainer></GridLastContentContainer>
      </HeaderContainer>
    </>
  )
}

export default DefaultHeader
