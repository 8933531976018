import { FC } from 'react'
import { Country, Crop, PaymentMethods, PlotForm } from './formConfig'
import { constants } from 'chatbot-shared'
import * as turf from '@turf/turf'
import { IrrigationUnit, OnboardingMode, Plot, UtmData } from './types'
import { TFunction } from 'i18next'
import { FormProps } from 'pages/Onboarding/formScreen/FormScreen'
import CropForm from 'pages/Onboarding/formScreen/CropForm'
import VarietyForm from 'pages/Onboarding/formScreen/VarietyForm'
import ContactForm from 'pages/Onboarding/formScreen/ContactForm'
import CropBlossomForm from 'pages/Onboarding/formScreen/CropBlossomForm'
import PlotLocation from 'pages/Onboarding/formScreen/PlotLocation'
import PlotNameForm from 'pages/Onboarding/formScreen/PlotNameForm'
import PaymentOptions from 'pages/Onboarding/formScreen/PaymentOptions'
import api from 'Api/ApiMethods'
import verySessionStorage from './vss'
import eventEmitter, { events } from './eventEmitter'
import { getFourYearsAgo, processUser } from './userManagement'
import { toast } from 'react-toastify'
import { CoralogixRum } from '@coralogix/browser'
import _ from 'lodash'

export const PLANT_ONBOARDING_FORM_DATA = 'PLANT_ONBOARDING_FORM_DATA'
export const PLANT_ONBOARDING_CURRENT_PAGE = 'PLANT_ONBOARDING_CURRENT_PAGE'
export const PLANT_ONBOARDING_USER_VERIFY = 'PLANT_ONBOARDING_USER_VERIFY'
export const PLANT_ONBOARDING_CURRENT_STEP = 'PLANT_ONBOARDING_CURRENT_STEP'
export const PLANT_ONBOARDING_USER_COUNTRY = 'PLANT_ONBOARDING_USER_COUNTRY'
export const PLANT_ONBOARDING_USER_LANG = 'PLANT_ONBOARDING_USER_LANG'
export const PLANT_ONBOARDING_USER_LOCATION = 'PLANT_ONBOARDING_USER_LOCATION'
export const PLANT_ONBOARDING_USER_PAYMENT_METHOD =
  'PLANT_ONBOARDING_USER_PAYMENT_METHOD'
export const PLANT_ONBOARDING_USER_RESELLER_ID =
  'PLANT_ONBOARDING_USER_RESELLER_ID'
export const PLANT_ONBOARDING_MODE = 'PLANT_ONBOARDING_MODE'

export type UtmObject = {
  utmSource: string | null | undefined;
  utmMedium: string | null | undefined;
  utmCampaign: string | null | undefined;
  utmTerm: string | null | undefined;
  utmContent: string | null | undefined;
  utm_campaign: string | null | undefined;
  utm_source: string | null | undefined;
  utm_medium: string | null | undefined;
  utm_term: string | null | undefined;
  utm_content: string | null | undefined;
}

export const calcPlotCenterAndArea = (bounds: any) => {
  const formattedBounds = bounds.map(({ lat, lng }: any) => [lng, lat])
  const polygon = turf.polygon([[...formattedBounds, formattedBounds[0]]])
  const centroid = turf.centroid(polygon)
  const [lng, lat] = centroid.geometry.coordinates
  const areaInSquareMeters = turf.area(polygon)
  return {
    center: { lat, lng },
    area: Number((areaInSquareMeters / 1000).toFixed(2))
  }
}

export const generatePolygonGeoJson = (bounds: any) => {
  const formattedBounds = bounds.map(({ lat, lng }: any) => [lng, lat])
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[...formattedBounds, formattedBounds[0]]]
        }
      }
    ]
  }
}

export const varietyTypeConvertor = (
  varietyType: string | number
): string | null => {
  if (typeof varietyType === 'number') {
    return null
  }

  if (varietyType.includes('Other')) {
    return 'Other'
  }

  if (varietyType.includes('Mexican') || varietyType.includes('Persa')) {
    return 'Mexican Persa'
  }

  if (varietyType.includes('Hass')) {
    return 'Hass'
  }

  return varietyType
}

export const createForm = (
  formData: PlotForm,
  utmData: UtmData,
  lang: string,
  country: Country,
  plot: Plot | undefined,
  growerId: number | undefined,
  plotCreationError: string,
  onboardingMode: OnboardingMode
) => {
  const center = formData.plotLocationForm.plotLocation
  const area = formData.plotLocationForm.plotAreaAmount

  const body = {
    grower: {
      id: growerId,
      name: formData.contactForm.userName.trim(),
      phoneNumber: formData.contactForm.userNumber,
      language: lang,
      country: country
    },
    plot: {
      id: plot?.id,
      name: `${formData.cropForm.cropType}_1`,
      cropType: formData.cropForm.cropType,
      varietyType: varietyTypeConvertor(formData.varietyForm.varietyType),
      categoryType:
        constants.crops.varieties[formData.cropForm.cropType]?.[
          formData.varietyForm.varietyType
        ]?.categoryTypeValue || plot?.category,
      plantingType: plot?.growthMethod,
      // soilType: constants.soils.find(
      //   ({ level }: any) => level === formData?.plotSoilForm.soilType
      // )?.value,
      soilType: 'Silt Loam',
      plantTime: new Date(getFourYearsAgo(), 0).getTime(),
      initialStageDate: new Date(
        formData.cropBlossomForm.initialStageDate || new Date().getTime()
      ).getTime(),
      latitude: center?.latitude,
      longitude: center?.longitude,
      area: { unit: 'DUNAM', val: area }
      // nominalFlow: formData.plotUnitsValuesForm
      //   ? Number(formData?.plotUnitsValuesForm?.nominalFlow)
      //   : null
    },
    polygon: generatePolygonGeoJson(formData.plotLocationForm.plotBounds),
    chat: {
      sendingHour: 6,
      irrigationUnits: IrrigationUnit.M3HA // formData?.plotUnitsForm.selectedUnit
    },
    payment: {
      ...formData.paymentForm.payment,
      option: formData.paymentOptionsForm.paymentOption
    },
    isActive: true,
    utmData,
    plotCreationError
  }

  return body
}

const formNameArray = [
  'CROP',
  'VARIETY',
  'CONTACT',
  // 'YEAR',
  'BLOSSOM',
  // 'SOIL',
  'LOCATION',
  // 'UNIT',
  // 'UNIT_VALUES',
  // 'NAME',
  // 'EXAMPLE',
  // 'TIME',
  'OPTIONS',
  'PAYMENT'
]

export const getFormNameByIndex = (index: number) => {
  if (index < 0 || index >= formNameArray.length) {
    return ''
  }

  return formNameArray[index]
}

export const getCountryByLanguage = (lng: string) => {
  switch (lng) {
    case 'he':
      return Country.Israel
    case 'en':
    case 'es':
      return Country.Mexico
    case 'fr':
      return Country.France
    case 'ar':
      return Country.Morocco      
    default:
      return Country.Mexico
  }
}

export const getCurrencyByCurrencyCode = (code: string) => {
  switch (code) {
    case 'ILS':
      return '₪'
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    default:
      return '€'
  }
}

export const getLocationCoordinatesByCountry = (country: Country) => {
  switch (country) {
    case Country.Israel:
      return { lat: 32.609473, lng: 35.290161 }
    case Country.Spain:
      return { lat: 40.414673, lng: -3.705628 }
    case Country.Mexico:
      return { lat: 20.659698, lng: -103.349609 }
    case Country.India:
      return { lat: 19.527256, lng: 75.64537 }
    case Country.Morocco:
      return { lat: 31.63098, lng: -7.985503 }
    case Country.Australia:
      return { lat: -37.65519443, lng: 144.9046798806 }
    case Country.France:
      return { lat: 47.0844, lng: 2.3964 }
    default:
      return { lat: 40.414673, lng: -3.705628 }
  }
}

export const countryIsValid = (country: string | null) => {
  if (!country) {
    return false
  }

  return Object.values(Country).includes(country.toLocaleUpperCase() as Country)
}

/* 
  convert binary weekday table to integer value:
  s m t w t f s (week days)
  1 0 0 0 0 0 0 = 64
*/
export const getCountryWeekdayBitmask = (country: Country) => {
  switch (country) {
    case Country.Israel:
      return 64
    case Country.Mexico:
      return 32
    case Country.Spain:
      return 32
    case Country.India:
      return 32
    case Country.Morocco:
      return 32
    case Country.Australia:
      return 32
    case Country.France:
      return 32
    default:
      return 32
  }
}

export const getInitialStageKey = (initialStage: string, t: TFunction) => {
  switch (initialStage) {
    case 'BUD_BURST':
      return t('form_bud_burst_2')
    case 'FLOWERING':
      return t('form_flowering_2')
    case 'EMERGENCE':
      return t('form_emergence_2')
    case 'RATOON_GROWTH':
      return t('form_ratoon_growth_2')
    case 'PLANTING':
      return t('form_planting_2')
    default:
      return t('form_flowering_2')
  }
}

export type PolygonBound = {
  lat: number
  lng: number
}

export const buildDefaultPolygon = (
  lat: number | undefined,
  lng: number | undefined,
  zoom: number,
  minZoom: number,
  middleZoom: number
) => {
  if (!lat || !lng) {
    return
  }

  const factor = zoom < middleZoom ? 0.002 : 0.00036 * (21 - zoom)

  return [
    { lat: lat + factor, lng: lng + factor },
    { lat: lat + factor, lng: lng - factor },
    { lat: lat - factor, lng: lng - factor },
    { lat: lat - factor, lng: lng + factor }
  ] as PolygonBound[]
}

export const newPlotFormSteps: FC<FormProps>[] = [
  CropForm,
  VarietyForm,
  ContactForm,
  // PlotYearForm,
  CropBlossomForm,
  // PlotSoilForm,
  PlotLocation,
  // PlotUnits,
  // PlotUnitsValues,
  // PlotNameForm,
  // ExampleForm,
  // PlotDesiredTime,
  PaymentOptions
]

export const addPlotFormSteps: FC<FormProps>[] = [
  CropForm,
  VarietyForm,
  // PlotYearForm,
  CropBlossomForm,
  // PlotSoilForm,
  PlotLocation,
  // PlotUnitsValues,
  PlotNameForm
]

export const isAustraliaStrip = (country: Country) =>
  country === Country.Israel ||
  country === Country.Australia ||
  country === Country.Morocco || 
  country === Country.France

export const createInitialFormFromParams = (
  cropType: string | null,
  variety: string | null
) => {
  if (!cropType) {
    return undefined
  }

  const cropTypeObject = ((constants.crops.types as Crop[]) || []).find(
    (el) => el.value === cropType
  )

  const cropObject = constants.crops.varieties?.[cropType]
  let varietyObject
  if (variety) {
    varietyObject = cropObject?.find(
      (el: { value: string }) => (el.value = variety)
    )
  }
  
  // const shouldSkipPlotAge = skipPlotAgeCropsList.includes(cropTypeObject?.value || '')
  if (cropTypeObject) {
    return {
      cropForm: {
        cropType: cropTypeObject.value,
        displayName: cropTypeObject.name,
        cropId: cropTypeObject.id,
        completed: true
      },
      varietyForm: {
        varietyType: varietyObject ? varietyObject.value : undefined,
        completed: !!varietyObject?.value
      },
      // plotYearForm: {
      //   plantYear: shouldSkipPlotAge ? new Date().getFullYear() - 1 : undefined,
      //   completed: shouldSkipPlotAge
      // },
      cropBlossomForm: {
        initialStageDate: undefined,
        completed: false
      },
      // plotUnitsForm: {
      //   selectedUnit: undefined,
      //   completed: false
      // },
      // plotUnitsValuesForm: {
      //   dripperCount: undefined,
      //   rowDistance: undefined,
      //   irrigationLines: undefined,
      //   emitterDistance: undefined,
      //   flowRate: undefined,
      //   nominalFlow: undefined,
      //   completed: false
      // },
      // plotSoilForm: {
      //   soilType: 2,
      //   completed: true
      // },
      // plotNameForm: {
      //   plotName: '',
      //   completed: false
      // },
      // exampleForm: {
      //   completed: false
      // },
      plotLocationForm: {
        plotLocation: undefined,
        plotBounds: undefined,
        plotAreaAmount: null,
        shouldDisplayPolygonConfirmation: false,
        shouldDisplayPolygon: true,
        completed: false
      },
      contactForm: {
        userName: '',
        userNumber: '',
        completed: false
      },
      // plotDesiredTimeForm: {
      //   userTime: '',
      //   completed: false
      // },
      paymentOptionsForm: {
        paymentOption: null,
        completed: false
      },
      paymentForm: {
        payment: {},
        paymentSuccessful: false,
        completed: false
      }
    } as PlotForm
  }
}

export const callOtcAccessApi = async (
  resellerId: string,
  phoneNumber: string,
  t: TFunction,
  lang: string,
  paymentMethod?: PaymentMethods
) => {
  let chosenPaymentMethod: PaymentMethods
  let accessCode
  if (paymentMethod) {
    chosenPaymentMethod = paymentMethod
  } else {
    const paymentMethodRes = await api.getPaymentMethod(resellerId, phoneNumber)
    chosenPaymentMethod = paymentMethodRes.data
  }

  // Error displayed when payment method returned as none
  if (chosenPaymentMethod === PaymentMethods.None) {
    toast.error(t<string>('general_server_error'))
    return { paymentMethod: PaymentMethods.None, accessCode: '' }
  }

  switch (chosenPaymentMethod) {
    case PaymentMethods.Coupon: {
      const response = await api.getAccessCodeCoupon(phoneNumber, lang)
      accessCode = response.data
      break
    }

    case PaymentMethods.MasterPool: {
      const response = await api.getAccessCodeMasterPool(
        resellerId,
        phoneNumber,
        lang
      )
      accessCode = response.data
      break
    }

    case PaymentMethods.ECommerce: {
      const response = await api.getAccessCode(phoneNumber, lang)
      accessCode = response.data
      break
    }

    default: {
      const response = await api.getAccessCode(phoneNumber, lang)
      accessCode = response.data
      break
    }
  }

  return { paymentMethod: chosenPaymentMethod, accessCode: accessCode }
}

export const callOtcVerifyApi = async (
  code: string,
  secret: string,
  resellerId: string,
  coupon: string | null,
  paymentMethod: PaymentMethods,
  lang: string
) => {
  switch (paymentMethod) {
    case PaymentMethods.Coupon: {
      const response = await api.postAccessCodeCoupon(
        code,
        secret,
        resellerId,
        coupon || '',
        lang
      )
      return {
        userResponse: response.data?.tokenMap,
        isValid: !!response?.data?.coupon
      }
    }

    case PaymentMethods.MasterPool: {
      const response = await api.postAccessCodeMasterPool(
        code,
        secret,
        resellerId,
        lang
      )
      return {
        userResponse: response.data?.tokenMap,
        isValid: !!response?.data?.masterPool
      }
    }

    case PaymentMethods.ECommerce: {
      const response = await api.postAccessCode(code, secret, lang)
      return { userResponse: response.data, isValid: true }
    }

    default: {
      const response = await api.postAccessCode(code, secret, lang)
      return { userResponse: response.data, isValid: true }
    }
  }
}

export const createPlotSpecial = async (
  utmData: UtmData,
  formData: PlotForm,
  lang: string,
  t: TFunction,
  resellerId: string,
  paymentMethod: PaymentMethods,
  couponCode: string | undefined,
  handlePostOnboarding?: (
    onboardingFormData: PlotForm,
    tFunc: TFunction
  ) => void
) => {
  const userVerify = verySessionStorage.get(PLANT_ONBOARDING_USER_VERIFY)
  const userCountry = verySessionStorage.get(PLANT_ONBOARDING_USER_COUNTRY)
  eventEmitter.emit(events.ENABLE_LOADING)
  const userData = await processUser(
    userVerify.authToken,
    userVerify.userObject,
    utmData as UtmData,
    formData,
    userCountry.country,
    lang,
    t
  )

  const phoneNumber = formData.contactForm.userNumber

  try {
    if (userData.growerId) {
      switch (paymentMethod) {
        case PaymentMethods.Coupon: {
          await api.postActivateCoupon(
            userVerify.authToken,
            couponCode || '',
            resellerId,
            userData.growerId,
            lang
          )
          break
        }

        case PaymentMethods.MasterPool: {
          await api.postActivateMasterPool(
            userVerify.authToken,
            resellerId,
            userData.growerId,
            phoneNumber,
            lang
          )
          break
        }

        default: {
          break
        }
      }
    }
  } catch (e) {
    toast.error(t<string>('general_server_error'))
  }

  if (handlePostOnboarding) {
    handlePostOnboarding(formData, t)
  }
}

export const postUserLeads = async (formData: PlotForm, utmData: UtmObject) => {
  const userVerify = verySessionStorage.get(PLANT_ONBOARDING_USER_VERIFY)
  const filteredUtmData = _.pickBy(utmData, _.identity)
  const combinedData = { ...filteredUtmData, ...formData }
  if (userVerify?.authToken && formData?.contactForm?.userNumber) {
    api.postLeads(
      userVerify.authToken,
      formData.contactForm.userNumber,
      Date.now(),
      JSON.stringify(combinedData)
    )
    CoralogixRum.setUserContext({
      user_id: formData.contactForm.userNumber,
      user_name: formData.contactForm.userNumber,
      user_email: formData.contactForm.userName,
    })
    CoralogixRum.error('New user started onboarding: ', { key: formData.contactForm.userNumber })
  }
}

export const getActiveUser = async (token: string, userId: number) =>
  await api.getActiveUser(token, userId)
