import { constants } from 'chatbot-shared'
import TriColoredText, {
  RotatingTextLinkSubtext,
  RotatingTextSpanSubtext
} from 'core/components/TriColoredText'
import { TFunction } from 'react-i18next'
import { AddPlotFormSteps, NewPlotFormSteps, PlotForm } from 'utils/formConfig'
import styled from '@emotion/styled/macro'
import { getInitialStageKey } from 'utils/formHelpers'
import analytics from 'utils/analytics'

const WHATSAPP_DOWNLOAD_LINK = 'https://www.whatsapp.com/download'
const { types: cropTypes } = constants.crops

const PaymentText = styled(RotatingTextSpanSubtext)`
  font-size: 24px;

  @media (max-width: ${({ theme }) => theme.media.width.md}) {
    font-size: 18px;
  }
`

const PaymentLinkText = styled(RotatingTextLinkSubtext)`
  font-weight: bold;
`

export const getNewPlotTextByForm = (
  formData: PlotForm,
  formId: number,
  t: TFunction
) => {
  const initialStage = cropTypes.find(
    ({ value }: any) => value === formData.cropForm.cropType
  )?.initialStage

  switch (formId) {
    case NewPlotFormSteps.CROP:
      return (
        <TriColoredText
          isFormText
          text1={t('form_growing_1')}
          text2={t('form_growing_2')}
          text3={t('form_growing_3')}
          subText={t('form_growing_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    case NewPlotFormSteps.VARIETY:
      return (
        <TriColoredText
          isFormText
          text1={t('form_variety_1')}
          text2={t(`crop:${formData.cropForm.displayName}`)}
          text3={t('form_variety_2')}
          subText={t('form_variety_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    // case NewPlotFormSteps.YEAR:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_year_1')}
    //       text2={t('form_year_2')}
    //       text3={t('form_year_3')}
    //       subText={t('form_year_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    case NewPlotFormSteps.BLOSSOM:
      // note: RTL hebrew makes this line seem wrong
      return (
        <TriColoredText
          isFormText
          text1={t('form_flowering_1')}
          text2={getInitialStageKey(initialStage, t)}
          text3={t('form_flowering_3')}
          subText={t('form_flowering_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    // case NewPlotFormSteps.UNIT:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_units_1')}
    //       text2={t('form_units_2')}
    //       text3={t('form_units_3')}
    //       subText={t('form_units_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    // case NewPlotFormSteps.UNIT_VALUES:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_units_values_1')}
    //       text2={t('form_units_values_2')}
    //       text3={t('form_units_values_3')}
    //       subText={t('form_units_values_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    // case NewPlotFormSteps.SOIL:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_plot_soil_1')}
    //       text2={t('form_plot_soil_2')}
    //       text3={t('form_plot_soil_3')}
    //       subText={t('form_plot_soil_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    // case NewPlotFormSteps.NAME:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_plot_name_1')}
    //       text2={t('form_plot_name_2')}
    //       text3={t('form_plot_name_3')}
    //       subText={t('form_plot_name_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    // case NewPlotFormSteps.EXAMPLE:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_example_1')}
    //       text2={t('form_example_2')}
    //       text3={t('form_example_3')}
    //       subText={t('form_example_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //       subtextLanguageCustomSizes={{
    //         es: { lg: '24px', md: '18px', sm: '18px' },
    //         he: { lg: '24px', md: '18px', sm: '18px' },
    //         en: { lg: '24px', md: '18px', sm: '18px' }
    //       }}
    //     />
    //   )
    case NewPlotFormSteps.LOCATION:
      return (
        <TriColoredText
          isFormText
          text1={t('form_plot_location_1')}
          text2={t('form_plot_location_2')}
          text3={t('form_plot_location_3')}
          subText={t('form_plot_location_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    case NewPlotFormSteps.CONTACT:
      return (
        <TriColoredText
          isFormText
          text1={t('form_grower_details_1')}
          text2={t('form_grower_details_2')}
          text3={t('form_grower_details_3')}
          subText={t('form_grower_details_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    // case NewPlotFormSteps.TIME:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_message_time_1')}
    //       text2={t('form_message_time_2')}
    //       text3={t('form_message_time_3')}
    //       subText={t('form_message_time_subtext')}
    //       textAlign={'start'}
    //       textLanguageCustomSizes={{
    //         he: { lg: '70px', md: '50px', sm: '34px' }
    //       }}
    //       isSmaller
    //     />
    //   )
    case NewPlotFormSteps.OPTIONS:
      return (
        <TriColoredText
          isFormText
          text1={t('form_payment_options_1')}
          text2={t('form_payment_options_2')}
          subText={t('form_payment_options_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    case NewPlotFormSteps.PAYMENT:
      return (
        <TriColoredText
          isFormText
          text1={t('form_payment_1')}
          text2={t('form_payment_2')}
          subText={
            <>
              <PaymentText>{t('form_payment_subtext')}</PaymentText>
              <PaymentLinkText
                onClick={() =>
                  analytics.sendEvent(
                    analytics.events.onboardingDownloadWhatsAppClick
                  )
                }
                target="blank"
                href={WHATSAPP_DOWNLOAD_LINK}
              >
                {t('form_payment_subtext_link')}
              </PaymentLinkText>
            </>
          }
          textAlign={'start'}
          isSmaller
        />
      )
    default:
      return (
        <TriColoredText
          isFormText
          text1={''}
          text2={''}
          text3={''}
          subText={''}
          textAlign={'start'}
        />
      )
  }
}

export const getAddPlotTextByForm = (
  formData: PlotForm,
  formId: number,
  t: TFunction
) => {
  const initialStage = cropTypes.find(
    ({ value }: any) => value === formData.cropForm.cropType
  )?.initialStage

  switch (formId) {
    case AddPlotFormSteps.CROP:
      return (
        <TriColoredText
          isFormText
          text1={t('form_growing_1')}
          text2={t('form_growing_2')}
          text3={t('form_growing_3')}
          subText={t('form_growing_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    case AddPlotFormSteps.VARIETY:
      return (
        <TriColoredText
          isFormText
          text1={t('form_variety_1')}
          text2={t(`crop:${formData.cropForm.displayName}`)}
          text3={t('form_variety_2')}
          subText={t('form_variety_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    // case AddPlotFormSteps.YEAR:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_year_1')}
    //       text2={t('form_year_2')}
    //       text3={t('form_year_3')}
    //       subText={t('form_year_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    case AddPlotFormSteps.BLOSSOM:
      // note: RTL hebrew makes this line seem wrong
      return (
        <TriColoredText
          isFormText
          text1={t('form_flowering_1')}
          text2={getInitialStageKey(initialStage, t)}
          text3={t('form_flowering_3')}
          subText={t('form_flowering_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    // case AddPlotFormSteps.UNIT_VALUES:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_units_values_1')}
    //       text2={t('form_units_values_2')}
    //       text3={t('form_units_values_3')}
    //       subText={t('form_units_values_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    // case AddPlotFormSteps.SOIL:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_plot_soil_1')}
    //       text2={t('form_plot_soil_2')}
    //       text3={t('form_plot_soil_3')}
    //       subText={t('form_plot_soil_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    // case AddPlotFormSteps.NAME:
    //   return (
    //     <TriColoredText
    //       isFormText
    //       text1={t('form_plot_name_1')}
    //       text2={t('form_plot_name_2')}
    //       text3={t('form_plot_name_3')}
    //       subText={t('form_plot_name_subtext')}
    //       textAlign={'start'}
    //       isSmaller
    //     />
    //   )
    case AddPlotFormSteps.LOCATION:
      return (
        <TriColoredText
          isFormText
          text1={t('form_plot_location_1')}
          text2={t('form_plot_location_2')}
          text3={t('form_plot_location_3')}
          subText={t('form_plot_location_subtext')}
          textAlign={'start'}
          isSmaller
        />
      )
    default:
      return (
        <TriColoredText
          isFormText
          text1={''}
          text2={''}
          text3={''}
          subText={''}
          textAlign={'start'}
        />
      )
  }
}
