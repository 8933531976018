import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled from '@emotion/styled/macro'
import StyledButton from 'core/components/StyledButton'
import { Grid } from '@mui/material'
import { UserContext } from 'core/UserContext'
import TriColoredText from 'core/components/TriColoredText'
import ActivePlotLogo from 'assets/images/icon_active_plot.svg'
import AddPlotLogo from 'assets/images/icon_add_plot.svg'
import AddFirstPlotLogo from 'assets/images/icon_add_first_plot.svg'
import api from 'Api/ApiMethods'
import { Plot, UserSettingsAction, UserSubscription } from 'utils/types'
import { useTranslation } from 'react-i18next'
import PlotsList from './PlotsListItem'
import CallSupportDialog from './CallSupportDialog'
import { AppContext } from 'core/AppContext'
import StyledOutlineButton from 'core/components/StyledOutlineButton'
import analytics from 'utils/analytics'
import { PaymentMethods } from 'utils/formConfig'

const GridContainer = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 0px 100px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 0px 25px;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 130px;
  }
`

const StyledGrid = styled(Grid)`
  flex-direction: row-reverse;

  body[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`

const GridBody = styled(Grid)`
  min-height: 60vh;
  max-height: 60vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-bottom: 100px;
    max-height: unset;
    min-height: 40vh;
    overflow: visible;
  }
`

const GridText = styled(Grid)`
  min-height: 60vh;
  max-height: 60vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    max-height: unset;
    min-height: unset;
  }
`

const GridContentContainer = styled.div`
  display: flex;
  overflow-wrap: anywhere;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    justify-content: center;
    align-items: center;
  }
`

const GridFooter = styled(Grid)`
  align-items: flex-start;
  margin-top: 35px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 100px;
    background: rgba(5, 43, 51, 0.8);
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const GridFormContentContainer = styled(GridContentContainer)`
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  justify-content: unset;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding-top: 8vh;
  }
`

const GridTextContentContainer = styled(GridContentContainer)`
  align-items: flex-start;
`

const PaddedText = styled.div`
  padding-right: 100px;
  display: flex;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  body[dir='rtl'] & {
    padding-right: 0px;
    padding-left: 100px;

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      overflow: inherit;
      padding-left: unset;
      padding: 0px 8%;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    justify-content: center;
    overflow: inherit;
    padding-left: unset;
    padding: 0px 8%;
  }
`

const NavigationButtonsContainer = styled.div`
  display: flex;
  gap: 30px;
  align-self: flex-start;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex: 1;
    gap: 20px;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
`

const GridContainerText = styled.div`
  color: #b7d2cd;
  font-size: 20px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 16px;
  }
`

const GridFooterButton = styled(Grid)`
  margin-top: -270px;

  @media (max-height: 840px) {
    margin-top: -100px;
  }

  @media (max-height: 520px) {
    margin-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: none;
  }
`

const AddPlot = styled.img`
  height: 110px;
  width: 110px;
  align-self: flex-start;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 80px;
    width: 80px;
    margin: 0px 30px;
  }
`

const ActivePlot = styled(AddPlot)`
  background-color: white;
`

const PlotsBoxesContainer = styled.div<{
  plotPlan: number
}>`
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding-bottom: ${({ plotPlan }) => (plotPlan > 5 ? '100px' : '75px')};
  margin-right: 70px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    justify-content: center;
  }
`

const PlotCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: start;
  position: relative;
  width: 100%;
  padding-top: 10px;
`

const PlotCardItem = styled.div`
  width: calc(17% + 15px);

  @media (max-width: 1460px) {
    width: calc(18% + 15px);
  }

  @media (max-width: 1270px) {
    width: calc(18% + 15px);
  }

  @media (max-width: 1125px) {
    width: calc(31% + 15px);
  }
`

const PlotCardTitleText = styled.div`
  color: #b7d2cd;
  font-size: 14px;
  padding-top: 10px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 13px;
  }
`

const PlotCardBodyText = styled.div`
  padding-top: 10px;
  color: #dcf900;
  font-size: 14px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 13px;
  }
`

const AddPlotButton = styled(StyledButton)`
  width: ${(props) => (props.lang === 'es' ? '270px' : '250px')};
  height: 70px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 160px;
    height: 45px;
  }
`

const UpgradePlanButton = styled(StyledOutlineButton)`
  width: ${(props) => (props.lang === 'es' ? '270px' : '250px')};
  height: 70px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: ${(props) => (props.lang === 'es' ? '195px' : '160px')};
    height: 45px;
  }
`

const Space = styled.div`
  height: 1rem;
`

interface UserSettingsDashboardProps {
  onActionChange: Dispatch<SetStateAction<UserSettingsAction | null>>
  setUserPlot: Dispatch<SetStateAction<Plot | null>>
}

interface UserSettingsButtonsActionProps {
  onActionChange: Dispatch<SetStateAction<UserSettingsAction | null>>
  disabled: boolean
  paymentType: PaymentMethods
}

const actionOptions = [
  {
    textKey: 'add_plot',
    action: UserSettingsAction.AddPlot
  },
  {
    textKey: 'update_subscription',
    action: UserSettingsAction.UpdateSubscription
  }
]

const addPlotOptions = {
  textKey: 'add_plot',
  action: UserSettingsAction.AddPlot
}

const UserSettingsButtonsAction: FC<UserSettingsButtonsActionProps> = ({
  onActionChange,
  disabled,
  paymentType
}) => {
  const { t, i18n } = useTranslation('userSettings')

  if (paymentType === PaymentMethods.MasterPool) {
    return (
      <AddPlotButton
        disabled={
          addPlotOptions.action === UserSettingsAction.AddPlot && disabled
        }
        key={addPlotOptions.action}
        text={t(`${addPlotOptions.textKey}`).toLocaleUpperCase()}
        onClick={() => {
          analytics.sendEvent(analytics.events.userSettingsAddPlotClick)
          onActionChange(addPlotOptions.action)
        }}
      />
    )
  }

  return (
    <>
      {actionOptions.map((action) =>
        action.action === UserSettingsAction.UpdateSubscription ? (
          <UpgradePlanButton
            key={action.action}
            text={t(`${action.textKey}`).toLocaleUpperCase()}
            lang={i18n.language}
            onClick={() => {
              analytics.sendEvent(analytics.events.userSettingsPlanUpgradeClick)
              onActionChange(action.action)
            }}
          />
        ) : (
          <AddPlotButton
            disabled={action.action === UserSettingsAction.AddPlot && disabled}
            key={action.action}
            text={t(`${action.textKey}`).toLocaleUpperCase()}
            onClick={() => {
              analytics.sendEvent(analytics.events.userSettingsAddPlotClick)
              onActionChange(action.action)
            }}
          />
        )
      )}
    </>
  )
}

const UserSettingsDashboard: FC<UserSettingsDashboardProps> = ({
  onActionChange,
  setUserPlot
}) => {
  const { t } = useTranslation('userSettings')
  const userContext = useContext(UserContext)
  const userCountryData = useContext(AppContext)
  const [userSubscription, setUserSubscription] = useState<UserSubscription>()
  const [plotsList, setPlotsList] = useState<Plot[]>()
  const [addPlotButtonDisabled, setAddPlotButtonDisabled] =
    useState<boolean>(false)

  const [dialogVisible, setDialogVisible] = useState(false)
  const [actionButtonVisible, setActionButtonVisible] = useState(true)

  const getSubscriptionAndPlots = useCallback(async () => {
    try {
      const subscription = await api.getSubscription(
        userContext.authToken,
        userCountryData.country
      )

      setUserSubscription(subscription.data)
      setAddPlotButtonDisabled(
        subscription.data.activePlots >= subscription.data.plotPlan
      )
      userContext.updateUserSubscription(subscription.data)
      if (subscription) {
        const plots = await api.getPlots(userContext.authToken)
        if (plots) {
          setPlotsList(plots.data)
          setUserPlot(plots.data[0])
        }
      }
    } catch {
      setDialogVisible(true)
      setActionButtonVisible(false)
    }
  }, [userContext, userCountryData.country, setUserPlot])

  useEffect(() => {
    getSubscriptionAndPlots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const plotsCards = useMemo(() => {
    const cards = new Array<Plot | null | string>()
    plotsList?.forEach((plot) => cards.push(plot))
    if (
      userSubscription?.plotPlan &&
      userSubscription?.plotPlan > cards.length
    ) {
      cards.push('add')
      for (let i = cards.length; i < userSubscription?.plotPlan; i++) {
        cards.push('')
      }
    }

    return cards
  }, [userSubscription, plotsList])

  const callCustomerSuccessDialogOnClose = useCallback(() => {
    setDialogVisible(false)
  }, [])

  return (
    <GridContainer>
      <CallSupportDialog
        dialogVisible={dialogVisible}
        contactUsMessage={t('contact_us_user_connection')}
        onClose={callCustomerSuccessDialogOnClose}
      />
      <StyledGrid container style={{ position: 'relative' }}>
        <GridText item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <GridTextContentContainer>
            <PaddedText>
              <TriColoredText
                isFormText
                text1={t('user_settings_dashboard_1')}
                breakLine1
                text2={userContext.userSettings?.firstName || ''}
                subText={t('user_settings_dashboard_sub_text', {
                  plots: userSubscription?.plotPlan
                })}
                textAlign={'start'}
                isSmaller
              />
            </PaddedText>
          </GridTextContentContainer>
        </GridText>
        <GridBody item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          {plotsList && (
            <GridFormContentContainer>
              {plotsList?.map((plot: Plot, index) => (
                <>
                  <PlotsList plotIndex={index + 1} plot={plot} />
                  <Space />
                </>
              ))}
            </GridFormContentContainer>
          )}
        </GridBody>
        <GridFooter item xs={12} md={6} order={{ xs: 3, md: 3 }}>
          <GridContentContainer>
            <NavigationButtonsContainer>
              {actionButtonVisible && (
                <UserSettingsButtonsAction
                  onActionChange={onActionChange}
                  disabled={addPlotButtonDisabled}
                  paymentType={
                    userSubscription?.paymentType || PaymentMethods.ECommerce
                  }
                />
              )}
            </NavigationButtonsContainer>
          </GridContentContainer>
        </GridFooter>
        <GridFooterButton item xs={12} md={6} order={{ xs: 4, md: 4 }}>
          <GridContentContainer>
            {actionButtonVisible && (
              <PlotsBoxesContainer
                plotPlan={
                  userSubscription?.plotPlan ? userSubscription?.plotPlan : 0
                }
              >
                <GridContainerText>
                  {t('plots_plan', { plan: userSubscription?.plotPlan })}
                </GridContainerText>
                <NavigationButtonsContainer>
                  <PlotCardContainer>
                    {plotsCards.map((card, index) =>
                      card && typeof card === 'object' ? (
                        <PlotCardItem>
                          <ActivePlot src={ActivePlotLogo} />
                          <PlotCardTitleText>
                            {`${t('plot')} ${index + 1}`}
                          </PlotCardTitleText>
                          <PlotCardBodyText>{card.name}</PlotCardBodyText>
                          <Space />
                        </PlotCardItem>
                      ) : (
                        <PlotCardItem>
                          {card === 'add' ? (
                            <AddPlot
                              src={AddFirstPlotLogo}
                              onClick={() => {
                                onActionChange(UserSettingsAction.AddPlot)
                              }}
                            />
                          ) : (
                            <AddPlot src={AddPlotLogo} />
                          )}
                          <PlotCardTitleText>
                            {`${t('plot')} ${index + 1}`}
                          </PlotCardTitleText>
                          <Space />
                        </PlotCardItem>
                      )
                    )}
                  </PlotCardContainer>
                </NavigationButtonsContainer>
              </PlotsBoxesContainer>
            )}
          </GridContentContainer>
        </GridFooterButton>
      </StyledGrid>
    </GridContainer>
  )
}

export default UserSettingsDashboard
