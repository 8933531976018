import React, { SVGProps } from 'react'

const IconGrapefruit = ({ fill }: SVGProps<unknown>) => (
  <svg
    width="51"
    height="48"
    viewBox="0 0 51 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_1" clip-path="url(#clip0_371_4599)">
      <path
        id="Vector"
        d="M13.4655 37.9095C6.17991 35.7116 0.970703 29.5432 0.970703 19.8533C0.970703 9.42274 9.42201 0.971436 19.8526 0.971436C30.2831 0.971436 38.7344 9.42274 38.7344 19.8533C38.7344 21.2618 38.6251 22.6583 38.3944 24.0425"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_2"
        d="M16.8047 8.26918C17.3025 7.0792 18.4804 6.30207 19.7675 6.30207C21.0668 6.27778 22.2567 7.05491 22.7303 8.26918"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M22.744 4.78418C22.0276 5.68274 20.9226 6.16845 19.7812 6.08345C18.6398 6.18059 17.5105 5.69488 16.8184 4.78418"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M49.8698 27.4546C49.8698 38.2616 41.1149 47.0164 30.3079 47.0164C19.501 47.0164 10.7461 38.2616 10.7461 27.4546"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_5"
        d="M45.2681 27.4546C45.2681 35.7238 38.5653 42.4265 30.2961 42.4265C22.027 42.4265 15.3242 35.7238 15.3242 27.4546"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_6"
        d="M33.15 27.4546C33.15 29.0331 31.875 30.296 30.2965 30.296C28.7301 30.296 27.4551 29.021 27.4551 27.4546"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_7"
        d="M10.7461 27.4546H27.4666"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        id="Vector_8"
        d="M49.8572 27.4546H33.1367"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        id="Vector_9"
        d="M30.2969 42.3901V30.381"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_10"
        d="M28.7539 29.9196L20.1934 38.4801"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_11"
        d="M31.875 29.9438L40.3992 38.468"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_12"
        d="M11.2449 18.6148C11.2449 18.9305 10.9899 19.1855 10.6742 19.1855C10.3585 19.1855 10.1035 18.9305 10.1035 18.6148C10.1035 18.2991 10.3585 18.0441 10.6742 18.0441C10.9899 18.0441 11.2449 18.2991 11.2449 18.6026"
        stroke="#052B33"
        stroke-width="2"
      />
      <path
        id="Vector_13"
        d="M11.2449 18.6148C11.2449 18.9305 10.9899 19.1855 10.6742 19.1855C10.3585 19.1855 10.1035 18.9305 10.1035 18.6148C10.1035 18.2991 10.3585 18.0441 10.6742 18.0441C10.9899 18.0441 11.2449 18.2991 11.2449 18.6026V18.6148Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_14"
        d="M11.5359 13.7941C11.5359 14.1098 11.2809 14.3648 10.9652 14.3648C10.6495 14.3648 10.3945 14.1098 10.3945 13.7941C10.3945 13.4784 10.6495 13.2234 10.9652 13.2234C11.2809 13.2234 11.5359 13.4784 11.5359 13.782"
        stroke="#052B33"
        stroke-width="2"
      />
      <path
        id="Vector_15"
        d="M11.5359 13.7941C11.5359 14.1098 11.2809 14.3648 10.9652 14.3648C10.6495 14.3648 10.3945 14.1098 10.3945 13.7941C10.3945 13.4784 10.6495 13.2234 10.9652 13.2234C11.2809 13.2234 11.5359 13.4784 11.5359 13.782V13.7941Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_16"
        d="M6.96953 20.9218C6.96953 21.2375 6.71453 21.4925 6.39882 21.4803C6.08311 21.4682 5.82812 21.2253 5.84026 20.9096C5.84026 20.5939 6.09526 20.3511 6.41097 20.3511C6.72668 20.3511 6.98167 20.6061 6.98167 20.9096"
        stroke="#052B33"
        stroke-width="2"
      />
      <path
        id="Vector_17"
        d="M6.96953 20.9218C6.96953 21.2375 6.71453 21.4925 6.39882 21.4803C6.08311 21.4682 5.82812 21.2253 5.84026 20.9096C5.84026 20.5939 6.09526 20.3511 6.41097 20.3511C6.72668 20.3511 6.98167 20.6061 6.98167 20.9096L6.96953 20.9218Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_18"
        d="M7.24883 16.1012C7.24883 16.4169 6.99384 16.6719 6.67813 16.6719C6.36242 16.6719 6.10742 16.4169 6.10742 16.1012C6.10742 15.7855 6.36242 15.5305 6.67813 15.5305C6.99384 15.5305 7.24883 15.7855 7.24883 16.0891"
        stroke="#052B33"
        stroke-width="2"
      />
      <path
        id="Vector_19"
        d="M7.24883 16.1012C7.24883 16.4169 6.99384 16.6719 6.67813 16.6719C6.36242 16.6719 6.10742 16.4169 6.10742 16.1012C6.10742 15.7855 6.36242 15.5305 6.67813 15.5305C6.99384 15.5305 7.24883 15.7855 7.24883 16.0891V16.1012Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_371_4599">
        <rect width="50.8414" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconGrapefruit
