import React, { FC, memo } from 'react'
import styled from '@emotion/styled/macro'

const OnePagerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;
`

const TitleContainer = styled.div`
  display: flex;
`
const SubTextContainer = styled.div`
  display: flex;
`

const TitleText = styled.span<{ $color?: string }>`
  font-size: 50px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -1.5px;
  text-align: start;
  color: ${({ $color }) => $color || '#dcf900'};

  @media (max-width: 1200px) {
    font-size: 43px;
    font-weight: 900;
    letter-spacing: -1.29px;
  }

  @media (max-height: 1000px) {
    font-size: 43px;
    font-weight: 900;
    letter-spacing: -1.29px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 30px;
    font-weight: 900;
    letter-spacing: -0.9px;
  }
`

const SubText = styled.span<{ $color?: string }>`
  font-size: 25px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: -0.75px;
  color: ${({ $color }) => $color || '#e8fbfa'};
  margin-top: 25px;
  text-align: start;

  @media (max-width: 1200px) {
    font-size: 21px;
    letter-spacing: -0.63px;
  }

  @media (max-height: 1000px) {
    font-size: 21px;
    letter-spacing: -0.63px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    color: ${({ $color }) => $color || '#b7d2cd'};
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: -0.6px;
  }
`

interface OnePagerTextProps {
  titleText: string
  titleColor?: string
  subText: string
  subTextColor?: string
}

const OnePagerText: FC<OnePagerTextProps> = ({
  titleText = '',
  titleColor,
  subText = '',
  subTextColor
}) => (
  <OnePagerTextContainer>
    <TitleContainer>
      <TitleText $color={titleColor}>{titleText}</TitleText>
    </TitleContainer>
    <SubTextContainer>
      <SubText $color={subTextColor}>{subText}</SubText>
    </SubTextContainer>
  </OnePagerTextContainer>
)

export default memo(OnePagerText)
