const verySessionStorage = {
  // eslint-disable-next-line
  set(key: string, value: any): void {
    const toSave = JSON.stringify(value)
    sessionStorage.setItem(key, toSave)
  },
  // eslint-disable-next-line
  get(key: string): any {
    try {
      const data = sessionStorage.getItem(key)
      if (!data) return false
      const json = JSON.parse(data)
      return json
    } catch {
      return false
    }
  },
  getNumber(key: string): any {
    try {
      const data = sessionStorage.getItem(key)
      if (!data) return false
      const json = JSON.parse(data)
      const number = Number(json)
      return number
    } catch {
      return false
    }
  },
  delete(key: string): void {
    sessionStorage.removeItem(key)
  },
  clear(): void {
    sessionStorage.clear()
  }
}

export default verySessionStorage
