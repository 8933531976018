import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { LatLngExpression, LeafletMouseEvent } from 'leaflet'
import { MapContainer, useMap, Polygon, Tooltip } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import 'leaflet/dist/leaflet.css'
import { useTranslation } from 'react-i18next'
import {
  UNIFORMITY_CHART_COLORS,
  getPlotHeatwaveAlert,
  getSatelliteByPeriodData,
  getSatelliteData,
  getTiffGeorasterUniformityLayerFunc,
  thresholdSoilColorsPallet
} from 'utils/geoTiffHelpers'
import { calcPlotCenterAndArea } from 'utils/formHelpers'
import { Plot, PlotCenter } from 'utils/types'
import styled from '@emotion/styled/macro'
import RulerMapLegend from './MapAlerts/RulerMapLegend'
import TextualMapLegend from './MapAlerts/TextualMapLegend'

const UNIFORMITY_COLOR_PALETTE_ARRAY = UNIFORMITY_CHART_COLORS.reverse()
const mapContainerStyle = { height: '100%', width: '100%' }

const CENTER = [32, 35] as LatLngExpression
const DEFAULT_ZOOM = 12
const ZOOM = 17
const DRY_NIGHT_EXPECTED = 21
const DRY_TOMORROW_EXPECTED = 22
const DRY_AFTER_TOMORROW_EXPECTED = 23

const StyledLegend = styled.div`
  position: absolute;
  height: 42% !important;
  z-index: 1000;
  bottom: 30px;
  left: 6px;
  min-width: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  display: flex;
  overflow: hidden;
  width: 45px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    bottom: 90px;
  }
`

const DateTitle = styled.h2`
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 14px;
  color: white;
`

const DateContent = styled.h2`
  margin-top: 0;
  padding-top: 0;
  font-size: 14px;
  color: white;
`

interface GeoTiffMapViewerProps {
  plots: Plot[] | null
  selectedPlotCenter: PlotCenter | null
  isUniformity: boolean
  onPlotIdChange: Dispatch<SetStateAction<number>>
  onPlotAlertChange: Dispatch<SetStateAction<any>>
}

interface MapContentProps extends GeoTiffMapViewerProps {}

const MapContent: FC<MapContentProps> = ({
  plots,
  selectedPlotCenter,
  isUniformity,
  onPlotIdChange,
  onPlotAlertChange
}) => {
  const { t } = useTranslation('geoMap')
  const map = useMap()
  const [fieldData, setFieldData] = useState<any>()
  const [dateAlertsData, setDateAlertsData] = useState<any[]>()
  const [currentLayer, setCurrentLayer] = useState<any>()

  useEffect(() => {
    onPlotAlertChange(dateAlertsData)
  }, [dateAlertsData, onPlotAlertChange])

  const getMapLayers = useCallback(async () => {
    if (!map || !plots) {
      return
    }

    Array.from(plots).forEach(async (plot: Plot) => {
      if (!plot.geojson) {
        return
      }

      const satellitePeriodData = await getSatelliteByPeriodData(`${plot.id}`, plot.uuid)
      const mapLayers = await getSatelliteData(
        satellitePeriodData.satelliteDataToday,
        `${plot.id}`,
        satellitePeriodData.plotData
      )

      const uniformity = await getTiffGeorasterUniformityLayerFunc(
        satellitePeriodData.satelliteDataToday,
        satellitePeriodData.plotData
      )
      const plotHeatwave = await getPlotHeatwaveAlert(`${plot.uuid}`, [
        DRY_NIGHT_EXPECTED,
        DRY_TOMORROW_EXPECTED,
        DRY_AFTER_TOMORROW_EXPECTED
      ])

      const { layer, data } = !isUniformity ? mapLayers : uniformity
      if (layer && data) {
        currentLayer?.removeFrom(map)
        layer.addTo(map)
        setCurrentLayer(layer)
        setFieldData(data[0])
        setDateAlertsData((prev) => {
          if (prev) {
            prev.push({
              id: plot.id,
              data: data,
              hw: plotHeatwave.data
            })

            return prev
          }

          return [
            {
              id: plot.id,
              data: data,
              hw: plotHeatwave.data
            }
          ]
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, plots, isUniformity])

  useEffect(() => {
    getMapLayers()
  }, [getMapLayers])

  const polygonPositions = useMemo(() => {
    if (!plots) {
      return
    }

    const result: any[] = []
    Array.from(plots).forEach((plot: Plot) => {
      const coordinates: any[] =
        plot.geojson?.features[0]?.geometry?.coordinates[0]
      if (coordinates) {
        result.push({
          polygon: coordinates?.map(([lng, lat]: any) => ({ lat, lng })),
          plotName: plot.name,
          id: plot.id
        })
      }
    })

    return result
  }, [plots])

  useEffect(() => {
    if (!polygonPositions || polygonPositions.length === 0) {
      return
    }

    const center = calcPlotCenterAndArea(polygonPositions[0].polygon)
    map.flyTo({ lat: center.center.lat, lng: center.center.lng }, ZOOM, {
      animate: false
    })
  }, [polygonPositions, map])

  const handlePolygonClick = useCallback(
    (event: LeafletMouseEvent, id: any) => {
      const dateData = dateAlertsData?.find((item: any) => item.id === id)
      if (dateData) {
        onPlotIdChange(id)
        setFieldData(dateData.data[0])
      }
    },
    [dateAlertsData, onPlotIdChange]
  )

  useEffect(() => {
    if (
      !map ||
      !selectedPlotCenter ||
      !selectedPlotCenter.latitude ||
      !selectedPlotCenter.longitude
    ) {
      return
    }

    map.flyTo(
      { lat: selectedPlotCenter.latitude, lng: selectedPlotCenter.longitude },
      ZOOM,
      { animate: false }
    )
  }, [map, selectedPlotCenter])

  return (
    <>
      <StyledLegend>
        <DateTitle>{t('map_data_update_date')}</DateTitle>
        <DateContent>
          {fieldData?.date?.split('-').reverse().join('/') || ''}
        </DateContent>
        {!isUniformity && (
          <TextualMapLegend
            legendColorArray={thresholdSoilColorsPallet}
            title={t('soil_moisture')}
          />
        )}
        {isUniformity && (
          <RulerMapLegend
            legendColorArray={UNIFORMITY_COLOR_PALETTE_ARRAY}
            highText={t('high_vegetation')}
            lowText={t('low_vegetation')}
            title={t('uniformity')}
          />
        )}
      </StyledLegend>
      <ReactLeafletGoogleLayer
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
        type={'satellite'}
      />
      {polygonPositions &&
        polygonPositions.map((polygon) => (
          <Polygon
            positions={polygon.polygon}
            pathOptions={{
              fill: true,
              color: 'rgb(220, 249, 0)',
              fillColor: 'transparent'
            }}
            eventHandlers={{
              click: (event) => {
                handlePolygonClick(event, polygon.id)
              }
            }}
          >
            <Tooltip permanent={true}>{polygon.plotName}</Tooltip>
          </Polygon>
        ))}
    </>
  )
}

const GeoTiffMapViewer: FC<GeoTiffMapViewerProps> = ({ ...props }) => {
  return (
    <MapContainer style={mapContainerStyle} center={CENTER} zoom={DEFAULT_ZOOM}>
      <MapContent {...(props as MapContentProps)} />
    </MapContainer>
  )
}

export default GeoTiffMapViewer
