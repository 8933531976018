import React, { FC } from 'react'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'

interface IDialog extends DialogProps {
  open: boolean
  showClose?: boolean
  dialogTitle?: string
  isCallSupport?: boolean
}

const Dialog: FC<IDialog> = (props) => {
  const {
    onClose,
    open,
    children,
    dialogTitle,
    isCallSupport = false,
    ...rest
  } = props

  return (
    <MuiDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth={isCallSupport ? 'xs' : 'md'}
      PaperProps={{
        style: { borderRadius: 15 }
      }}
    >
      {children}
    </MuiDialog>
  )
}

export default Dialog
