import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import AlertAlarmIcon from 'assets/images/icon_alert_alarm.svg'
import { Plot } from 'utils/types'
import PlotAlert from './PlotAlert'
import analytics from 'utils/analytics'

const DrawerContainer = styled.div<{
  isOpen: boolean
  direction: string
}>`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 495px;
  height: ${({ isOpen }) => (isOpen ? '579px' : '76px')};
  overflow: hidden;
  z-index: 9999;
  transition: height 0.3s ease-in-out;
  background-color: #052b33;
  justify-content: flex-end;
  border-radius: 18px 18px 0 0;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 100%;
    height: ${({ isOpen }) => (isOpen ? '435px' : '63px')};
  }
`

const DrawerAction = styled.div`
  width: 100%;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  margin-top: 5px;
  height: 50px;
`

const BorderTop = styled(DrawerAction)`
  ::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 40%;
    width: 20%;
    height: 1px;
    border-top: 3px solid #b7d2cd;
  }
`

const AlertsCloseTitle = styled.div`
  width: 100%;
  height: 76px;
  font-size: 14px;
  color: #dcf900;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 100%;
    height: 63px;
  }
`

const AlertAlarm = styled.img`
  width: 32px;
  height: 32px;
  margin-top: 5px;
`

const Drawer = styled.div<{
  isOpen: boolean
  direction: string
}>`
  width: 500px;
  height: 579px;
  overflow-y: auto;
  background-color: #052b33;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(0%)' : 'translateY(100%)'};

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 100%;
    height: 435px;
  }
`

interface AlertsDrawerProps {
  isOpen: boolean
  currentPlot: Plot | null
  plotFieldData: any
  plotHeatWave: any
  nextPlot: () => void
  previousPlot: () => void
}

const AlertsDrawer: FC<AlertsDrawerProps> = ({
  isOpen,
  currentPlot,
  plotFieldData,
  plotHeatWave,
  nextPlot,
  previousPlot
}) => {
  const { t, i18n } = useTranslation('geoMap')
  const [open, setOpen] = useState(isOpen)
  const drawerRef = useRef(null)
  const touchStartYRef = useRef(null)

  const handleDrawerAction = useCallback(() => {
    analytics.sendEvent(analytics.events.plotsViewerAlertsDrawerToggle, {
      labelArg: open ? 'close' : 'open'
    })
    setOpen((prev) => !prev)
  }, [open])

  const handleOutsideClick = useCallback((event: any) => {
    if (
      drawerRef.current &&
      !(drawerRef.current as unknown as any).contains(event.target)
    ) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleOutsideClick, isOpen])

  const handleTouchStart = useCallback((event: any) => {
    touchStartYRef.current = event.touches[0].clientY
  }, [])

  const handleTouchMove = useCallback((event: any) => {
    if (
      touchStartYRef.current &&
      event.touches[0].clientY - touchStartYRef.current > 50
    ) {
      setOpen(false)
    }
  }, [])

  return (
    <DrawerContainer
      isOpen={open}
      direction={i18n.dir()}
      ref={drawerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <DrawerAction onClick={handleDrawerAction}>
        <BorderTop />
      </DrawerAction>
      {!open && (
        <AlertsCloseTitle onClick={handleDrawerAction}>
          <AlertAlarm src={AlertAlarmIcon} /> {t('alerts_drawer')}
        </AlertsCloseTitle>
      )}
      <Drawer isOpen={open} direction={i18n.dir()}>
        <PlotAlert
          plot={currentPlot}
          plotFieldData={plotFieldData}
          plotHeatWave={plotHeatWave}
          nextPlot={nextPlot}
          previousPlot={previousPlot}
        />
      </Drawer>
    </DrawerContainer>
  )
}

export default AlertsDrawer
