import React, { FC } from 'react'
import styled from '@emotion/styled/macro'
import { TextField, TextFieldProps } from '@mui/material'
import { CacheProvider } from '@emotion/react'
import rtlPlugin from 'stylis-plugin-rtl'
import { prefixer } from 'stylis'
import createCache from '@emotion/cache'

const StyledTextField = styled(TextField)<{ $textAlign?: string }>`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.colors.inputs.text};
    align-self: flex-end;
    text-align: ${({ $textAlign }) => $textAlign || 'start'};
    padding: 4px 4px 5px 0px;
    padding-top: 25px;

    :focus {
      color: ${({ theme }) => theme.colors.inputs.textFocused};
    }
  }

  .MuiInput-root {
    font-size: 60px;
    font-family: 'Metropolis';

    body[dir='rtl'] & {
      font-family: 'SimplerPro';
    }

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      font-size: 30px;
    }

    :hover:not(.Mui-disabled):before {
      border-bottom: ${({ theme }) =>
        `1px solid ${theme.colors.inputs.border} !important`};
    }

    &:before {
      border-bottom: ${({ theme }) =>
        `1px solid ${theme.colors.inputs.border} !important`};
    }

    &:after {
      border-bottom: ${({ theme }) =>
        `1px solid ${theme.colors.inputs.borderFocused} !important`};
    }

    &.Mui-error {
      .MuiInputBase-input {
        color: #d32f2f;
        :focus {
          color: #d32f2f;
        }
      }

      &:after {
        border-bottom: 1px solid red;
      }
    }
  }

  .MuiInputLabel-root {
    color: ${({ theme }) => theme.colors.inputs.label};
    font-weight: normal;
    font-size: 20px;
    font-family: 'Metropolis';

    body[dir='rtl'] & {
      font-family: 'SimplerPro';
    }

    &.Mui-error {
      color: #d32f2f;
    }

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      font-size: 18px;
      font-weight: normal;
    }
  }

  .MuiInputLabel-standard {
    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      top: 0px;
    }
  }

  .Mui-focused {
    color: ${({ theme }) =>
      `${theme.colors.inputs.labelFocused} !important`}; // for whatever reason , mui overwrites only this class
  }

  .MuiSvgIcon-root {
    top: unset;
    color: #617b7f;
    bottom: 30px;

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      bottom: 15px;
    }
  }

  .MuiSelect-select {
    padding-right: 45px !important;
  }
`

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin]
})

const RtlInput: FC<TextFieldProps & { isRtl?: boolean; textAlign?: string }> = (
  props
) => {
  const { isRtl, textAlign, ...rest } = props

  return isRtl ? (
    <CacheProvider value={cacheRtl}>
      <StyledTextField dir="rtl" $textAlign={textAlign} {...rest} />
    </CacheProvider>
  ) : (
    <StyledTextField dir="ltr" $textAlign={textAlign} {...rest} />
  )
}

export default RtlInput
