import React, { FC } from 'react'
import styled from '@emotion/styled/macro'
import MainLogo from 'assets/images/icon_plant_dark_green.svg'
import WhatsAppContact from '../WhatsAppContact'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { HeaderLogoProps } from '../HeaderLogo'

const HeaderContainer = styled.div`
  overflow: hidden;
  min-height: 20vh;
  height: 20vh;
  display: flex;
  direction: ltr;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    min-height: 15%;
    height: 15%;
  }
`

const GridContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  z-index: 1;
`

const MainLogoContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex: 1;
  }
`

const GridFirstContentContainer = styled(GridContentContainer)`
  justify-content: flex-end;
`

const GridLastContentContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: none;
  }
`

const Main = styled.img`
  height: 150px;
  width: 150px;
  align-self: flex-start;
  cursor: pointer;
  margin: 0px 30px 0 0;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 80px;
    width: 80px;
    margin: 0px 30px;
  }
`

const PurpleGreenHeader: FC<HeaderLogoProps> = ({
  contactUsMessage,
  handleClickLogo,
  companyLogo
}) => {
  const isKeyboardOpen = useDetectKeyboardOpen()
  return (
    <>
      {!isKeyboardOpen && (
        <WhatsAppContact contactUsMessage={contactUsMessage} />
      )}
      <HeaderContainer>
        <GridFirstContentContainer>
          <Main src={MainLogo} onClick={handleClickLogo} />
        </GridFirstContentContainer>
        <GridContentContainer>
          <MainLogoContainer>
            <Main src={companyLogo} onClick={handleClickLogo} />
          </MainLogoContainer>
        </GridContentContainer>
        <GridLastContentContainer />
      </HeaderContainer>
    </>
  )
}

export default PurpleGreenHeader
