import React, { FC } from 'react'
import styled from '@emotion/styled/macro'
import { DialogContent } from '@mui/material'
import { Grid } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SatelliteLogo from 'assets/images/icon_satellite.svg'
import { useTranslation } from 'react-i18next'
import CustomCheckbox from './Checkbox'

interface SatelliteDialogProps {
  onClose: () => void
  checkBoxChange: () => void
}

const DialogBodyContent = styled(DialogContent)`
  background-color: #dcf900;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 500px;
  }
`

const SatelliteIcon = styled.img`
  width: 200px;
  height: 183px;

  @media (max-width: 500px) {
    width: 98px;
    height: 90px;
  }
`

const Space = styled.div`
  height: 1rem;
`

const DialogTitleText = styled.div`
  font-size: 56px;
  white-space: break-spaces;
  line-height: 0.93;
  color: #052b33;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    font-size: 27px;
  }
`

const DialogContentText = styled.div`
  font-size: 28px;
  white-space: break-spaces;
  color: #052b33;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    font-size: 17px;
  }
`

const CloseButton = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 0px;
  }
`

const TermsOfUseContainer = styled.div`
  display: block;
  justify-content: flex-start;
  letter-spacing: -0.5px;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    letter-spacing: -0.4px;
  }
`

const ConfirmTermsText = styled.span`
  font-size: 20px;
  font-weight: 400;
  align-self: center;
  color: #052b33;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const SatelliteDialog: FC<SatelliteDialogProps> = ({
  onClose,
  checkBoxChange
}) => {
  const { t } = useTranslation('plotScreen')
  return (
    <DialogBodyContent>
      <CloseButton>
        <div />
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </CloseButton>
      <Grid>
        <SatelliteIcon src={SatelliteLogo} />
        <Space />
        <DialogTitleText>{t('satellite_popup_title')}</DialogTitleText>
        <Space />
        <DialogContentText>{t('satellite_popup_content')}</DialogContentText>
        <Space />
      </Grid>
      <Space />
      <CheckboxContainer>
        <CustomCheckbox onChange={checkBoxChange} isSatellite={true} />
        <TermsOfUseContainer>
          <ConfirmTermsText>{t('satellite_popup_checked')}</ConfirmTermsText>
        </TermsOfUseContainer>
      </CheckboxContainer>
    </DialogBodyContent>
  )
}

export default SatelliteDialog
