import React, { FC, useContext, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { Steps, getSubdomain } from 'utils/formConfig'
import { HECTARE_PRICE_PER_YEAR, subdomainCompanyNames } from 'utils/constants'
import StyledButton from 'core/components/StyledButton'
import TriColoredText from 'core/components/TriColoredText'
import { AppContext } from 'core/AppContext'
import { Country } from '../../../utils/formConfig'
import { PageProps } from '..'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    gap: 20px;
    align-self: center;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 20vh;
  }
`

const PriceText = styled(TriColoredText)`
  margin: 27px 0;
`

const MenuButton = styled(StyledButton)`
  font-size: 20px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const NavigationButtonsContainer = styled.div`
  display: flex;
  gap: 30px;
  height: 20vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 100px;
    gap: 20px;
    align-self: center;
  }
`

const calculateDiscount = (area: number, country: Country): number => {
  const isDunam = country === Country.Israel
  const hectares = isDunam ? area * 10 : area
  return hectares * HECTARE_PRICE_PER_YEAR
}

const SavedMoneyScreen: FC<PageProps> = ({ handleClickNextPage, formData }) => {
  const { t } = useTranslation('savedMoneyScreen')
  const userCountryData = useContext(AppContext)
  const companyName = useMemo(() => subdomainCompanyNames[getSubdomain()], [])

  const savedMoney = useMemo(() => {
    const discount = calculateDiscount(
      Number(formData?.plotLocationForm.plotAreaAmount),
      userCountryData?.country
    )

    return `${discount} USD`
  }, [formData?.plotLocationForm.plotAreaAmount, userCountryData?.country])

  return (
    <>
      <Container>
        <TextContainer>
          <TriColoredText text1={t('annual_cost')} isSmaller />
          <PriceText text2={savedMoney} isSmaller />
          <TriColoredText
            text1={t('company_payed_for_you', { companyName })}
            isSmaller
          />
        </TextContainer>
        <NavigationButtonsContainer>
          <MenuButton
            altStyle
            hasInternalPadding
            onClick={() => handleClickNextPage(Steps.Form)}
            text={t('formScreen:back')}
          />
          <MenuButton
            hasInternalPadding
            onClick={() => handleClickNextPage(Steps.Ending)}
            text={t('formScreen:finish')}
          />
        </NavigationButtonsContainer>
      </Container>
    </>
  )
}

export default SavedMoneyScreen
