import React, { SVGProps } from 'react'

const IconNectarine = ({ fill }: SVGProps<unknown>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M35.7607 14.553C33.4568 14.5319 31.1846 14.9231 29.0075 15.674C28.9652 14.5742 28.8278 13.5589 28.6164 12.6283C30.3074 13.2417 32.0829 13.58 33.8901 13.5906C35.1372 13.5906 36.3737 13.4108 37.5573 13.0407C40.7596 11.8351 43.4651 9.5826 45.23 6.65325C45.2406 6.6321 45.2512 6.61095 45.2617 6.5898C45.4837 6.11391 45.2829 5.54282 44.8073 5.31017C41.7002 3.92481 38.202 3.64987 34.9152 4.5382C32.0301 5.62745 29.5465 7.56272 27.7921 10.0902C26.0166 5.93413 22.9518 4.21039 22.7721 4.10464C22.3177 3.87198 21.7575 4.04116 21.5039 4.47475C21.2397 4.92948 21.3982 5.52169 21.8526 5.78607C22.0534 5.9024 26.7247 8.57794 27.0735 15.6316C24.9175 14.9019 22.6664 14.5213 20.3942 14.5424C12.0557 14.5424 6 21.0039 6 29.9083C6 45.6442 21.5779 52 28.0775 52C34.577 52 50.1549 45.6442 50.1549 29.9083C50.1549 21.0039 44.0992 14.5424 35.7607 14.5424V14.553ZM35.4965 6.3677C37.9378 5.70145 40.5271 5.79664 42.9155 6.64266C41.4359 8.72599 39.3645 10.3229 36.9655 11.2112C34.5242 11.8774 31.9349 11.7822 29.5465 10.9362C31.026 8.85289 33.0975 7.25602 35.4965 6.3677ZM28.0775 50.0859C23.6281 50.0859 7.92345 45.253 7.92345 29.9188C7.92345 22.0085 13.0491 16.4776 20.3942 16.4776C22.2014 16.4671 24.0086 16.7104 25.7524 17.1968C22.064 19.5445 19.4325 23.5948 19.4325 29.9188C19.3374 35.8093 21.9689 41.4141 26.5556 45.1049C26.9889 45.4116 27.5913 45.3165 27.8978 44.8829C28.2043 44.4493 28.1092 43.8465 27.6758 43.5398C23.6281 40.1768 21.3137 35.1747 21.3559 29.9188C21.3559 17.44 32.3682 16.4776 35.7501 16.4776C43.0952 16.4776 48.2209 22.0085 48.2209 29.9188C48.2209 45.253 32.5162 50.0859 28.0669 50.0859H28.0775Z"
      fill="#052B33"
    />
  </svg>
)

export default IconNectarine
