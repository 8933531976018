const basePalette = {
  primary: '#582a7a',
  secondary: '#4e9349',
  white: '#ffffff',
  red: '#c13636'
}

const palette = {
  ...basePalette,
  bluishGray: '#323b41',
  lavender: '#b090b5',
  steelGrey: '#878a8f',
  eggBlue: '#b7d2cd',
  lightGray: '#cdd2db'
}

const purpleGreenColorSchema = {
  palette: basePalette,
  background: {
    default: palette.white,
    transparent: 'rgba(255, 255, 255, 0.8)'
  },
  text: {
    description: palette.bluishGray
  },
  icons: {
    whatsAppIcon: palette.secondary
  },
  buttons: {
    text: palette.white,
    background: palette.primary,
    backgroundHover: '#4f266e',
    backgroundActive: '#421f5b',
    backgroundDisabled: palette.lavender,
    altText: palette.primary,
    altBorder: palette.primary
  },
  inputs: {
    text: palette.bluishGray,
    textFocused: palette.bluishGray,
    label: palette.steelGrey,
    labelFocused: palette.steelGrey,
    border: palette.eggBlue,
    borderFocused: palette.bluishGray,
    borderError: palette.red
  },
  switch: {
    track: palette.lightGray,
    trackChecked: palette.secondary,
    thumb: palette.white,
    thumbChecked: palette.white,
    thumbFocused: palette.white,
    text: palette.lightGray,
    activeText: palette.secondary
  },
  stepper: {
    line: palette.primary,
    lineSelected: palette.secondary,
    circle: palette.secondary,
    circleSelected: palette.primary,
    circleBorder: palette.primary,
    circleBorderSelected: palette.secondary
  },
  imageCarousel: {
    boxShadow: 'none'
  },
  datePicker: {
    maskGradient: 'none',
    label: palette.primary,
    text: palette.lightGray
  },
  slider: {
    line: palette.primary,
    markBorder: palette.white,
    text: palette.lightGray,
    textSelected: palette.primary,
    actionShadowColor: `${palette.primary}1C`
  },
  checkbox: {
    checkboxIcon: palette.secondary,
    checkboxTitle: palette.primary,
    checkboxDescription: palette.steelGrey,
    checkboxDescriptionSmall: palette.steelGrey
  },
  sections: {
    intro: {
      introScreenGradient: 'none'
    },
    crops: {
      icon: palette.primary,
      iconSelected: palette.white,
      iconLabel: palette.primary,
      iconLabelSelected: palette.white,
      background: palette.white,
      backgroundSelected: palette.primary,
      border: palette.primary,
      borderSelected: palette.primary
    },
    units: {
      unitsTitle: palette.primary,
      unitsSizeTitle: palette.secondary,
      nominalFlowTitle: palette.primary,
      nominalSizeTitle: palette.secondary,
      unitsInputLabel: palette.bluishGray,
      unitsInputText: palette.bluishGray,
      unitsInputBorder: palette.bluishGray
    },
    plotLocation: {
      description: palette.steelGrey
    },
    plotName: {
      text: palette.bluishGray,
      linkHover: palette.secondary,
      checkbox: palette.bluishGray,
      checkboxDark: palette.bluishGray
    },
    paymentOptions: {
      couponText: palette.primary,
      couponLink: palette.primary,
      couponLinkHover: palette.secondary
    }
  }
}

export default purpleGreenColorSchema
