import useQueryParams from 'hooks/useQueryParams'
import React, { createContext, useState, FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Country, PaymentMethods, getPaymentMethodFromSubdomain } from 'utils/formConfig'
import { PLANT_ONBOARDING_USER_PAYMENT_METHOD, countryIsValid, getCountryByLanguage } from 'utils/formHelpers'
import verySessionStorage from 'utils/vss'

const AppContext = createContext<{
  country: Country
  initialPaymentMethod: PaymentMethods
  paymentMethod: PaymentMethods
  onChangeCountry: (newCountry: Country) => void
  onChangePaymentMethod: (newPaymentMethod: PaymentMethods) => void
}>({
  country: Country.Israel,
  onChangeCountry: (newCountry) => { },
  onChangePaymentMethod: (newPaymentMethod) => { },
  initialPaymentMethod: PaymentMethods.ECommerce,
  paymentMethod: PaymentMethods.ECommerce
})

interface AppContextProviderProps {
  children: JSX.Element
}

const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
  const { i18n } = useTranslation('')
  const urlQueryParams = useQueryParams()
  const initParams = useMemo(
    () => ({
      lang: urlQueryParams.get('lang'),
      country: urlQueryParams.get('country')
    }),
    [urlQueryParams]
  )
  const initialPaymentMethod = useMemo(() => {
    const localSessionPaymentMethod = verySessionStorage.get(
      PLANT_ONBOARDING_USER_PAYMENT_METHOD,
    )
    return localSessionPaymentMethod || getPaymentMethodFromSubdomain()
  }, [])

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(initialPaymentMethod)
  const [country, setCountry] = useState<Country>(
    (countryIsValid(initParams.country) && (initParams.country?.toLocaleUpperCase() as Country)) ||
    getCountryByLanguage(initParams.lang || i18n.language || 'en')
  )

  const onChangeCountry = (newCountry: Country) => {
    setCountry(newCountry)
  }

  const onChangePaymentMethod = (newPaymentMethod: PaymentMethods) => {
    setPaymentMethod(newPaymentMethod)
  }

  return (
    // the Provider gives access to the context to its children
    <AppContext.Provider value={{ country, onChangeCountry, onChangePaymentMethod, initialPaymentMethod, paymentMethod }}>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }
