import styled from '@emotion/styled/macro'
import StyledButton from './StyledButton'

const StyledOutlineButton = styled(StyledButton)`
  color: #dcf900;
  background-color: #052b33;
  border: solid 1px #dcf900;

  :hover {
    background-color: #dcf900;
    color: #052b33;
  }
`

export default StyledOutlineButton
