import React, { FC, memo, useEffect, useRef } from 'react'

interface MapControlProps {
  position: keyof typeof google.maps.ControlPosition
  map: google.maps.Map
}

const MapControl: FC<React.PropsWithChildren<MapControlProps>> = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (props.map && ref.current !== null) {
      props.map.controls[
       google.maps.ControlPosition[props.position]
      ].push(ref?.current)
    }
  }, [props.map, props.position, ref])
  return <div ref={ref}>{props.children}</div>
}

export default memo(MapControl)
