import React, { FC } from 'react'
import styled from '@emotion/styled/macro'

interface StepperProps {
  currentIndex: number
  total: number
  currentStepCompleted: boolean
}

interface LineStepperProps {
  currentLineIndex: number
  currentIndex: number
  currentStepCompleted: boolean
  total: number
}

const StepperContainer = styled.div`
  display: block;
  padding: 0px 15px;
  margin-top: 55px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 0px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex: 1;
`

const Line = styled.div<{
  $isSelected: boolean
  $width: number
  $position: number
  $shouldRender: boolean
}>`
  display: ${({ $shouldRender }) => ($shouldRender ? 'flex' : 'none')};
  width: ${({ $width }) => `${$width}%`};
  height: 2px;
  background: ${({ $isSelected, theme }) =>
    $isSelected
      ? theme.colors.stepper.lineSelected
      : theme.colors.stepper.line};
`

const TestLineCircle = styled.div<{
  $isSelected: boolean
  $isCurrent: boolean
}>`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  z-index: 2;
  background: ${({ $isSelected, theme }) =>
    $isSelected
      ? theme.colors.stepper.circle
      : theme.colors.stepper.circleSelected};
  border: ${({ $isCurrent, $isSelected, theme }) =>
    $isCurrent || $isSelected
      ? `3px solid ${theme.colors.stepper.circleBorderSelected}`
      : `3px solid ${theme.colors.stepper.circleBorder}`};

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 9px;
    width: 9px;
    border: ${({ $isCurrent, $isSelected, theme }) =>
      $isCurrent || $isSelected
        ? `1.5px solid ${theme.colors.stepper.circleBorderSelected}`
        : `1.5px solid ${theme.colors.stepper.circleBorder}`};
  }
`

const LineContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
`

const LineStepper: FC<LineStepperProps> = ({
  currentLineIndex,
  currentIndex,
  currentStepCompleted,
  total
}) => {
  const initialPosition = (100 / total) * currentLineIndex
  const finalPosition = (100 / total) * (currentLineIndex + 1)
  const distance = finalPosition - initialPosition
  const isSelected =
    currentLineIndex < currentIndex ||
    (currentLineIndex <= currentIndex && currentStepCompleted)
  return (
    <Line
      $isSelected={isSelected}
      $width={distance}
      $position={initialPosition}
      $shouldRender={currentLineIndex !== total - 1}
    />
  )
}

const Circle: FC<LineStepperProps> = ({
  currentLineIndex,
  currentIndex,
  currentStepCompleted,
  total
}) => {
  const isCurrent =
    currentLineIndex === currentIndex ||
    (currentLineIndex === currentIndex + 1 && currentStepCompleted)
  const isSelected =
    currentLineIndex < currentIndex ||
    (currentLineIndex <= currentIndex && currentStepCompleted)
  return (
    <div style={{ display: 'flex' }}>
      <TestLineCircle $isSelected={isSelected} $isCurrent={isCurrent} />
    </div>
  )
}

const Stepper: FC<StepperProps> = ({
  currentIndex,
  total,
  currentStepCompleted
}) => {
  const valueArray = Array.from(Array(total).keys())
  return (
    <StepperContainer>
      <ContentContainer>
        <LineContainer>
          {valueArray.map((valueIndex) => (
            <LineStepper
              key={`line-${valueIndex}`}
              currentLineIndex={valueIndex}
              currentIndex={currentIndex}
              currentStepCompleted={currentStepCompleted}
              total={total}
            />
          ))}
        </LineContainer>
        {valueArray.map((valueIndex) => (
          <Circle
            key={`circle-${valueIndex}`}
            currentLineIndex={valueIndex}
            currentIndex={currentIndex}
            currentStepCompleted={currentStepCompleted}
            total={total}
          />
        ))}
      </ContentContainer>
    </StepperContainer>
  )
}

export default Stepper
