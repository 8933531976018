type func = (...args: unknown[]) => unknown

class EventEmitter {
  private events: any

  constructor() {
    this.events = {}
  }

  on(name: string, listener: func): void {
    if (!this.events[name]) {
      this.events[name] = []
    }

    this.events[name].push(listener)
  }

  removeListener(name: string, listenerToRemove: func): void {
    if (!this.events[name]) {
      return
    }

    const filterListeners = (listener: func): boolean => listener !== listenerToRemove
    this.events[name] = this.events[name].filter(filterListeners)
  }

  emit(name: string, data?: any): void {
    if (!this.events[name]) {
      return
    }

    const fireCallbacks = (callback: func): void => {
      callback(data)
    }

    this.events[name].forEach(fireCallbacks)
  }
}

export default EventEmitter
