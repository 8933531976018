import React, { FC } from 'react'
import styled from '@emotion/styled/macro'
import analytics from 'utils/analytics'
import IconHelpLogo from 'assets/icons/IconHelpLogo';
import { theme } from 'ui'

const PHONE_URL = process.env.REACT_APP_WHATSAPP_PHONE_URL

const WhatsAppContactContainer = styled.a`
  cursor: pointer;
  display: flex;
  position: fixed;
  text-decoration: none;
  justify-content: center;
  z-index: 100;
  top: 40px;
  align-items: center;
  left: 30px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    top: calc(100% - 135px);
    z-index: 101;

    body[dir='rtl'] & {
      left: unset;
      right: 30px;
    }
  }
`

interface WhatsAppContactProps {
  contactUsMessage: string
}

const WhatsAppContact: FC<WhatsAppContactProps> = ({ contactUsMessage }) => {
  // no need for useCallback since the component wont rerender
  const handleWhatsAppClick = () => {
    analytics.sendEvent(analytics.events.onboardingContactUsClick)
  }

  return (
    <WhatsAppContactContainer
      onClick={handleWhatsAppClick}
      target="_blank"
      href={PHONE_URL + '?text=' + contactUsMessage}
    >
      <IconHelpLogo fill={theme.colors.icons.whatsAppIcon} />
    </WhatsAppContactContainer>
  )
}

export default WhatsAppContact
