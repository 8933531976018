import React, {
  FC,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect
} from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'core/AppContext'
import PhoneInput from 'core/components/PhoneInput'
import { matchIsValidTel } from 'mui-tel-input'

interface PhoneLoginProps {
  setPhone: Dispatch<SetStateAction<string>>
}

const PhoneContentContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  direction: ltr;
  padding: 10px 5px;
`

const PhoneLogin: FC<PhoneLoginProps> = ({ setPhone }) => {
  const { i18n, t } = useTranslation('login')
  const userCountryData = useContext(AppContext)
  const [number, setNumber] = useState('')

  const onChangeNumber = useCallback(
    (value: string) => {
      setNumber(value)
    },
    [setNumber]
  )

  useEffect(() => {
    const ready =  matchIsValidTel(number)
    if (!!ready) {
      setPhone(number)
    } else {
      setPhone('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number])

  return (
    <PhoneContentContainer>
      <PhoneInput
        value={number}
        defaultCountry={userCountryData.country}
        onChange={onChangeNumber}
        label={t('phone')}
        lang={i18n.language}
        fullWidth
      />
    </PhoneContentContainer>
  )
}

export default PhoneLogin
