import styled from '@emotion/styled/macro'
import React, { FC } from 'react'

interface StyledButtonProps extends React.ComponentPropsWithoutRef<'div'> {
  onClick: () => void
  hasInternalPadding?: boolean
  disabled?: boolean
  altStyle?: boolean
  text: string
}

const FooterButton = styled.div<{
  $disabled: boolean
  hasInternalPadding: boolean
}>`
  min-width: 220px;
  height: 80px;
  background-color: ${({ $disabled, theme }) =>
    $disabled
      ? theme.colors.buttons.backgroundDisabled
      : theme.colors.buttons.background};
  color: ${({ theme }) => theme.colors.buttons.text};
  border-radius: 50px;
  justify-content: center;
  display: inline-flex;
  padding: 0px 20px;
  cursor: ${({ $disabled }) => ($disabled ? '' : 'pointer')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : '')};
  padding: ${({ hasInternalPadding }) =>
    hasInternalPadding ? '0px 50px' : ''};
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 1200px) {
    :hover {
      background-color: ${({ theme }) => theme.colors.buttons.backgroundHover};
    }

    :active {
      background-color: ${({ theme }) => theme.colors.buttons.backgroundActive};
    }
  }

  @media (max-width: 1200px) {
    min-width: 160px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    min-width: 120px;
    border-radius: 25.5px;
    height: 51px;
  }
`

const FooterButtonAltStyle = styled(FooterButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.buttons.altText};
  border: ${({ theme }) => `1px solid ${theme.colors.buttons.altBorder}`};
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};

  @media (min-width: 899px) {
    :hover {
      background-color: unset;
    }

    :active {
      background-color: ${({ theme }) => `${theme.colors.buttons.altBorder}1c`};
    }
  }
`

const ButtonText = styled.span`
  font-size: 16px;
  align-self: center;

  body[dir='ltr'] & {
    margin-top: 4px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    line-height: 22px;
    font-size: 14px;
  }
`

const StyledButton: FC<StyledButtonProps> = ({
  onClick,
  disabled = false,
  text,
  altStyle = false,
  hasInternalPadding = false,
  ...props
}) => {
  return altStyle ? (
    <FooterButtonAltStyle
      {...props}
      $disabled={disabled}
      hasInternalPadding={hasInternalPadding}
      onClick={onClick}
    >
      <ButtonText>{text}</ButtonText>
    </FooterButtonAltStyle>
  ) : (
    <FooterButton
      {...props}
      $disabled={disabled}
      hasInternalPadding={hasInternalPadding}
      onClick={onClick}
    >
      <ButtonText>{text}</ButtonText>
    </FooterButton>
  )
}

export default StyledButton
