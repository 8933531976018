import React, { FC, memo, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import LeftArrowIcon from 'assets/images/icon_arrow_left.svg'
import LeftArrowIconAlternative from 'assets/images/icon_arrow_left_alternative.svg'
import { useCallback } from 'react'

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  direction: ltr;
  width: 100%;
  max-width: 620px;
  align-self: center;
  margin-top: 100px;

  body[dir='rtl'] & {
    transform: rotate(180deg);
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: none;
  }
`

const LeftArrow = styled.img`
  height: 31px;
  width: 31px;
  cursor: pointer;
`

const Point = styled.div<{ $isSelected: boolean; $altStyle?: boolean }>`
  height: 15px;
  width: 15px;
  border: ${({ $altStyle }) =>
    $altStyle ? '2px solid #052b33' : '2px solid #e8fbfa'};
  margin: 0px 12px;
  background: ${({ $isSelected, $altStyle }) =>
    $isSelected ? '#e8fbfa' : $altStyle ? '#dcf900' : '#052b33;'};
  border-radius: 50%;
`

const RightArrow = styled(LeftArrow)`
  transform: rotate(180deg);
`

interface PaginationPointProps {
  isSelected: boolean
  altStyle?: boolean
}

const PaginationPoint: FC<PaginationPointProps> = memo(
  ({ isSelected, altStyle }) => {
    return <Point $isSelected={isSelected} $altStyle={altStyle} />
  }
)

interface OnePagerPaginationProps {
  index: number
  length: number
  altStyle?: boolean
  onChange: (page: number) => void
}

const OnePagerPagination: FC<OnePagerPaginationProps> = ({
  onChange,
  index,
  altStyle,
  length
}) => {
  const dotArray = useMemo(() => new Array(length).fill(0), [length])

  const handleClickArrowRight = useCallback(() => {
    onChange(index + 1)
  }, [index, onChange])

  const handleClickArrowLeft = useCallback(() => {
    onChange(index - 1)
  }, [index, onChange])
  return (
    <PaginationContainer>
      {index !== 0 && (
        <LeftArrow
          src={altStyle ? LeftArrowIconAlternative : LeftArrowIcon}
          onClick={handleClickArrowLeft}
        />
      )}
      {dotArray.map((el, idx) => (
        <PaginationPoint
          key={`dot-point-${idx}`}
          isSelected={index === idx}
          altStyle={altStyle}
        />
      ))}
      {index !== length - 1 && (
        <RightArrow
          src={altStyle ? LeftArrowIconAlternative : LeftArrowIcon}
          onClick={handleClickArrowRight}
        />
      )}
    </PaginationContainer>
  )
}

export default memo(OnePagerPagination)
