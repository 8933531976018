import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import Globe from 'assets/images/icon_language_globe.svg'
import GlobeAlternative from 'assets/images/icon_language_globe_alternative.svg'
import { Button, Fade, Popper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useEffect } from 'react'
import analytics from 'utils/analytics'

const LanguageSelectContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin: 50px 10px 0px 10px;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin: 20px 0px 0px 0px;
  }
`

const LanguageGlobeIcon = styled.img`
  height: 39px;
  width: 39px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 25px;
    width: 25px;
  }
`

const LanguageListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LanguageListItem = styled.div`
  display: flex;
`

const LanguageListItemText = styled.span<{
  $isSelected: boolean
  $altStyle?: boolean
}>`
  display: flex;
  font-size: 18px;
  opacity: ${({ $isSelected }) => ($isSelected ? '1' : '0.3')};
  color: ${({ $altStyle }) => ($altStyle ? '#112a32' : '#b7d2cd')};
  cursor: pointer;
`

const GlobeButton = styled(Button)``

interface LanguageItemProps {
  language: string
  isSelected: boolean
  altStyle?: boolean
  handleClickLanguage: (language: string) => void
}

const LanguageItem: FC<LanguageItemProps> = ({
  handleClickLanguage,
  language,
  isSelected,
  altStyle
}) => {
  const handleClick = useCallback(() => {
    handleClickLanguage(language)
  }, [handleClickLanguage, language])

  return (
    <LanguageListItem onClick={handleClick}>
      <LanguageListItemText $isSelected={isSelected} $altStyle={altStyle}>
        {language.toLocaleUpperCase()}
      </LanguageListItemText>
    </LanguageListItem>
  )
}

interface LangSelectProps {
  tick?: boolean // a boolean that causes the menu to close when changed.
  altStyle?: boolean
  focusDisabled?: boolean
}

const LangSelect: FC<LangSelectProps> = ({ tick, altStyle, focusDisabled }) => {
  const { i18n } = useTranslation('contactInfo')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const selectedLanguage = useMemo(() => i18n.language, [i18n.language])

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }, [])

  const handleClickLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language)
      setOpen(false)
      analytics.sendEvent(analytics.events.onboardingChangeLanguageClick, {
        labelArg: language
      })
    },
    [i18n]
  )

  useEffect(() => {
    setOpen(false)
  }, [tick])

  return (
    <LanguageSelectContainer>
      <Popper
        className={'lang-popover-one-pager'}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <LanguageListContainer>
              <LanguageItem
                handleClickLanguage={handleClickLanguage}
                isSelected={selectedLanguage === 'he'}
                altStyle={altStyle}
                language={'he'}
              />
              <LanguageItem
                handleClickLanguage={handleClickLanguage}
                isSelected={selectedLanguage === 'es'}
                altStyle={altStyle}
                language={'es'}
              />
              <LanguageItem
                handleClickLanguage={handleClickLanguage}
                isSelected={selectedLanguage === 'en'}
                altStyle={altStyle}
                language={'en'}
              />
              <LanguageItem
                handleClickLanguage={handleClickLanguage}
                isSelected={selectedLanguage === 'fr'}
                altStyle={altStyle}
                language={'fr'}
              />
              <LanguageItem
                handleClickLanguage={handleClickLanguage}
                isSelected={selectedLanguage === 'ar'}
                altStyle={altStyle}
                language={'ar'}
              />              
            </LanguageListContainer>
          </Fade>
        )}
      </Popper>
      <GlobeButton onClick={handleClick} tabIndex={focusDisabled ? -1 : 0}>
        <LanguageGlobeIcon src={altStyle ? GlobeAlternative : Globe} />
      </GlobeButton>
    </LanguageSelectContainer>
  )
}

export default LangSelect
