import React, { FC, useMemo, useEffect, useContext } from 'react'
import {
  Routes as ReactRouterRoutes,
  Navigate,
  Route,
  Outlet
} from 'react-router-dom'
import Onboarding from 'pages/Onboarding'
import GrowerPlotsView from 'pages/GrowerPlotsView'
import useQueryParams from 'hooks/useQueryParams'
import i18next from 'i18next'
import UserSettings from 'pages/UserSettings/UserSettings'
import Login from 'pages/Login/Login'
import { UserContext } from './UserContext'

export enum BrowserRoutes {
  Onboarding = '/onboarding',
  Plot = '/plot/:plotId',
  Conversion = '/conversion',
  Login = '/login',
  UserSettings = '/user-settings',
  GrowerPlots = '/grower-plots/:growerUuid'
}

const Conversion = () => <></>

export const PrivateRoute: FC = () => {
  const userContext = useContext(UserContext)
  return userContext.isAuthenticated ? (
    <Navigate to={BrowserRoutes.UserSettings} replace />
  ) : (
    <Outlet />
  )
}

export const AuthoredRoute: FC = () => {
  const userContext = useContext(UserContext)
  return userContext.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={BrowserRoutes.Login} replace />
  )
}

const Routes: FC = () => {
  const urlQueryParams = useQueryParams()
  const lang = useMemo(() => urlQueryParams.get('lang'), [urlQueryParams])

  useEffect(() => {
    if (!lang) {
      return
    }

    i18next.changeLanguage(lang)
  }, [lang])

  return (
    <ReactRouterRoutes>
      <Route path={BrowserRoutes.Onboarding} element={<Onboarding />} />
      <Route path={BrowserRoutes.GrowerPlots} element={<GrowerPlotsView />} />
      <Route path={BrowserRoutes.Conversion} element={<Conversion />} />
      <Route path={BrowserRoutes.Login} element={<PrivateRoute />}>
        <Route index element={<Login />} />
      </Route>
      <Route path={BrowserRoutes.UserSettings} element={<AuthoredRoute />}>
        <Route index element={<UserSettings />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={BrowserRoutes.Onboarding} replace />}
      />
    </ReactRouterRoutes>
  )
}

export default Routes
