import axios from 'axios'

const { REACT_APP_BASE_URL } = process.env

export const ApiEndpoints = {
  getSupportedLocales: () => '/bot/public/supported-locales',
  access: () => '/auth-server-0.0.1/public/access-otc/ecommerce',
  accessAndValidate: () => '/auth-server-0.0.1/public/accessAndValidate',
  verify: (code: string, requestId: string) =>
    `/auth-server-0.0.1/public/verify-otc/ecommerce?requestId=${requestId}&otp=${code}`,
  updateUser: () => '/bot/api/v3/users?onboarding=true',
  getActiveUser: (userId: number) => `bot/api/v3/users/${userId}/active`,
  createGrower: (userId: string) => `/bot/api/v3/users/${userId}/growers`,
  putOneTimeMessages: (userId: string, growerId: string, plotId: string) =>
    `/bot/api/v3/users/${userId}/growers/${growerId}/plots/${plotId}/messages/onboarding`,
  createPlot: (
    growerId: string,
    companyUuid: string,
    shouldAttachIWSensor: boolean
  ) =>
    `/bot/api/v3/growers/${growerId}/plots?attachVirtualCurrentSensor=true&attachVirtualForecastSensor=true&attachIWSensor=${shouldAttachIWSensor}&companyUuid=${companyUuid}`,
  getProtocol: (
    growerId: string,
    cropId: string,
    lat: string,
    lng: string,
    cropVariety: string
  ) =>
    `/bot/api/v2/growers/${growerId}/crops/${cropId}/protocols?latitude=${lat}&longitude=${lng}&idOnly=true&cropVariety=${cropVariety}`,
  getCrops: () => `/bot/api/v3/crop-types`,
  getCategory: (growerId: string, cropId: string) =>
    `/webfront-0.0.1/api/v2/cropProtocol/grower/${growerId}/cropId/${cropId}/category`,
  getCropProtocol: (
    growerId: string,
    cropId: string,
    lat: number,
    lng: number,
    variety: string | undefined,
    category: string | undefined
  ) =>
    `/webfront-0.0.1/api/v2/cropProtocol/grower/${growerId}/cropId/${cropId}/protocol?latitude=${lat}&longitude=${lng}&idOnly=true${variety ? `&cropVariety=${variety}` : ''
    }${category ? `&cropCategory=${category}` : ''}`,
  getGrowingTypes: (protocol: string, plantDate: number) =>
    `/webfront-0.0.1/api/v2/cropProtocol/protocol/${protocol}/growingType?plantDate=${plantDate}`,
  getPlotParams: (plotId: number) =>
    `/bot/api/v3/plots/${plotId}/params`,
  postPlotParams: (plotId: number) =>
    `/bot/api/v3/plots/${plotId}/params?comment=changeNominalFlow`,
  getCheckoutSession: (
    sessionId: string,
    growerId: number,
    userId: number,
    country: string
  ) =>
    `/webfront-0.0.1/api/v2/checkout/session?sessionId=${sessionId}&growerId=${growerId}&userId=${userId}&country=${country}`,
  postCheckoutSession: (priceId: string, country: string) =>
    `/webfront-0.0.1/api/v2/checkout/session?priceId=${priceId}&country=${country}`,
  getSubscription: (country: string) =>
    `/webfront-0.0.1/api/v2/subscription?country=${country}`,
  putSubscription: (priceId: string, country: string) =>
    `/webfront-0.0.1/api/v2/subscription/${priceId}?country=${country}`,
  getPlots: () => `/webfront-0.0.1/api/v2/grower/plot`,
  getSatelliteByPeriodData: (plotUuid: string) =>
    `/webfront-0.0.1/public/plotSatelliteData?plotUUID=${plotUuid}`,
  getPlotsByGrowerId: (growerUuid: string) =>
    `/webfront-0.0.1/public/grower/plot?growerUUID=${growerUuid}`,
  getPlotAlertByType: (plotUuid: string, typeIds: string) =>
    `/webfront-0.0.1/public/alert/type?plotUUID=${plotUuid}&typeIds=${typeIds}`,
  getPaymentMethod: (resellerId: string | number, phoneNumber: string) =>
    `/webfront-0.0.1/public/payment-method?resellerId=${resellerId}&phoneNumber=${phoneNumber}`,
  getAccessMasterPool: (resellerId: string, phoneNumber: string) =>
    `/auth-server-0.0.1/public/access-otc/master-pool?contractGrowerId=${resellerId}&phoneNumber=${phoneNumber}`,
  verifyMasterPool: (
    requestId: string,
    otp: string,
    resellerId: string | number
  ) =>
    `/auth-server-0.0.1/public/verify-otc/master-pool?resellerId=${resellerId}&requestId=${requestId}&otp=${otp}`,
  getAccessCoupon: (phoneNumber: string) =>
    `/auth-server-0.0.1/public/access-otc/coupon?phoneNumber=${phoneNumber}`,
  verifyCoupon: (
    requestId: string,
    otp: string,
    resellerId: string | number,
    couponCode: string
  ) =>
    `/auth-server-0.0.1/public/verify-otc/coupon?resellerId=${resellerId}&requestId=${requestId}&otp=${otp}&couponCode=${couponCode}`,
  postActivateMasterPool: (
    contractGrowerId: string,
    growerId: number | string,
    phoneNumber: string
  ) =>
    `/webfront-0.0.1/api/v2/master-pool/activate?contractGrowerId=${contractGrowerId}&growerId=${growerId}&phoneNumber=${phoneNumber}`,
  postActivateCoupon: (
    couponCode: string,
    resellerId: string,
    growerId: number | string
  ) =>
    `/webfront-0.0.1/api/v2/coupon/activate?couponCode=${couponCode}&resellerId=${resellerId}&growerId=${growerId}`,
  postLeads: () =>
    `/bot/api/v3/leads`,
}

const Api = axios.create(
  REACT_APP_BASE_URL
    ? {
      baseURL: REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    : {
      headers: {
        'Content-Type': 'application/json'
      }
    }
)

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error)
    return Promise.reject(error)
  }
)

export const setAuthorizationHeader = (token?: string) => {
  Api.defaults.headers = {
    ...Api.defaults.headers
  }
}

export { Api }
