import React, { Dispatch, FC, SetStateAction } from 'react'
import styled from '@emotion/styled/macro'
import { PaymentOption } from 'utils/types'
import { useTranslation } from 'react-i18next'
import analytics from 'utils/analytics'

const SwitchToggle = styled.div`
  height: 44px;
  position: relative;
  display: inline-flex;
  padding: 4px;
  border: 1px solid #1f3d43;
  margin-bottom: 40px;
  border-radius: 30px;
  background: #1f3d43;
  text-align: center;
  color: #b7d2cd;

  [type='radio'] {
    position: absolute;
    left: -9999px;
  }

  [type='radio']:checked#monthly ~ label[for='monthly'],
  [type='radio']:checked#yearly ~ label[for='yearly'] {
    color: #052b33;
    background: #e8fbfa;
    height: 36px;
  }

  [type='radio']:checked#monthly + label[for='yearly'] ~ span {
    transform: none;
  }

  [type='radio']:checked#yearly + label[for='monthly'] ~ span {
    transform: translateX(100%);
  }
`

const Label = styled.label`
  width: 165px;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  min-width: 100px;
  line-height: 38px;
  cursor: pointer;
  border-radius: 30px;
  transition: color 0.25s ease-in-out;

  :hover {
    font-weight: bold;
    height: 36px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 120px;
  }
`

const Highlighter = styled.span`
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(50% - 4px);
  height: calc(100% - 8px);
  border-radius: 30px;
  transition: transform 0.25s ease-in-out;
`

const Input = styled.input``

interface PricePeriodToggleProps {
  onPaymentOptionChange: Dispatch<SetStateAction<PaymentOption>>
}

const PricePeriodToggle: FC<PricePeriodToggleProps> = ({
  onPaymentOptionChange
}) => {
  const { t } = useTranslation('updateSubscription')
  return (
    <SwitchToggle>
      <Input id="monthly" type="radio" name="switch" checked />
      <Input
        id="yearly"
        type="radio"
        name="switch"
        value={PaymentOption.Yearly}
      />
      <Label
        htmlFor="monthly"
        onClick={() => {
          analytics.sendEvent(analytics.events.userSettingsChoosePlanToggle, {
            labelArg: PaymentOption.Monthly
          })
          onPaymentOptionChange(PaymentOption.Monthly)
        }}
      >
        {t('monthly')}
      </Label>
      <Label
        htmlFor="yearly"
        onClick={() => {
          analytics.sendEvent(analytics.events.userSettingsChoosePlanToggle, {
            labelArg: PaymentOption.Yearly
          })
          onPaymentOptionChange(PaymentOption.Yearly)
        }}
      >
        {t('yearly')}
      </Label>
      <Highlighter></Highlighter>
    </SwitchToggle>
  )
}

export default PricePeriodToggle
