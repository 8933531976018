import React, { FC, memo, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import BackgroundIcon from 'assets/images/icon_one_pager_second_background.svg'
import BackgroundIconMobile from 'assets/images/icon_one_pager_second_background_mobile.svg'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import OnePagerText from './OnePagerText'
import OnePagerPagination from './OnePagerPagination'
import { OnePagerPageProps } from './OnePagerFirstPage'

const Background = styled.img`
  pointer-events: none;
  bottom: -2px;
  align-self: flex-start;
  position: absolute;
  max-width: 1660px;
  width: 80%;
  aspect-ratio: auto;
  object-fit: cover;
  left: -10%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: calc(40vw + 150px);
    width: calc(92vw + 160px);
    right: 0px;
    left: calc(0vw - 90px);
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) and (orientation: landscape) {
    height: 300px;
    width: 600px;
    right: 0px;
    left: calc(50% - 300px);
  }
`

const PageContentContainer = styled.div`
  display: flex;
  background: #dcf900;
  overflow: hidden;
  position: relative;
  padding-top: 15vh;
  max-height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  flex-direction: row-reverse;

  body[dir='rtl'] & {
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex-direction: column;
    padding-top: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    max-height: unset;

    body[dir='rtl'] & {
      flex-direction: column;
    }
  }
`

const BackgroundImagesContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    min-height: 330px;
    display: flex;
    flex: 1;
    position: relative;
  }
`

const PageTextParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  align-items: center;
  margin-top: -15vh;
  align-self: center;

  padding: 0px 10px 0px 30px;
  max-width: 50vw;

  body[dir='rtl'] & {
    padding: 0px 30px 0px 10px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    align-self: center;
    padding: 0px 30px;
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;

    body[dir='rtl'] & {
      padding: 0px 30px;
    }
  }
`

const OnePagerSecondPage: FC<OnePagerPageProps> = ({
  handleChangePageIndex,
  isMobile
}) => {
  const { t, i18n } = useTranslation('onePager')
  const fadeDirection = useMemo(() => {
    if (isMobile) {
      return 'up'
    }
    return i18n.dir() === 'rtl' ? 'left' : 'right'
  }, [i18n, isMobile])

  return (
    <PageContentContainer>
      <PageTextParentContainer>
        <Fade triggerOnce direction={fadeDirection}>
          <OnePagerText
            titleText={t('page_title_2')}
            titleColor={'#052b33'}
            subText={t('page_subtext_2')}
            subTextColor={'#052b33'}
          />
        </Fade>
        <OnePagerPagination
          index={1}
          length={4}
          altStyle
          onChange={handleChangePageIndex}
        />
      </PageTextParentContainer>
      <BackgroundImagesContainer>
        <Background src={isMobile ? BackgroundIconMobile : BackgroundIcon} />
      </BackgroundImagesContainer>
    </PageContentContainer>
  )
}

export default memo(OnePagerSecondPage)
