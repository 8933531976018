import React, { SVGProps } from 'react'

const IconCheckboxUnchecked = ({ fill }: SVGProps<unknown>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g data-name="Rectangle 5" fill="none" stroke={fill}>
      <path stroke="none" d="M0 0h14v14H0z" />
      <path fill="none" d="M.5.5h13v13H.5z" />
    </g>
  </svg>
)

export default IconCheckboxUnchecked
