import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import styled from '@emotion/styled/macro'
import { FormProps } from './FormScreen'
import PolygonDrawingMap from 'core/components/PolygonDrawingMap'
import { useTranslation } from 'react-i18next'
import analytics from 'utils/analytics'
import { PlotCenter } from 'utils/types'
import { PolygonBound } from 'utils/formHelpers'
import StyledButton from 'core/components/StyledButton'

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  margin-top: -8vh;

  @media (max-width: 900px) {
    margin-top: 0;
    height: 530px;
  }
`

const TextContainer = styled.div`
  display: flex;
  padding: 10px 0px 2px 0px;
  align-self: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex-direction: column;
  }
`

const ColoredTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const TextSpace = styled.div`
  width: 50px;
`

const TextWhite = styled.span<{ $fontSize?: string, $mobileFontSize?: string }>`
  font-size: ${({ $fontSize }) => ($fontSize || '20px')};
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.colors.sections.plotLocation.description};
  text-align: start;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    line-height: 1;
    align-self: center;
    text-align: center;
    font-size: ${({ $mobileFontSize }) => ($mobileFontSize || '16px')};
  }
`

const TextGreen = styled(TextWhite)`
  color: ${({ theme }) => theme.colors.palette.secondary};
`

const TextWhiteQuestion = styled(TextWhite)`
  font-size: 16px;
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 16px;
    font-weight: 400;
  }
`

const TextGreenQuestion = styled(TextGreen)`
  font-size: 32px;
  font-weight: 900;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 32px;
    font-weight: 900;
  }
`

const ClearText = styled.span`
  font-weight: bold;
  letter-spacing: -0.32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.palette.secondary};
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
  align-self: flex-end;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    line-height: 1;
    font-size: 16px;
  }
`

const PlotPolygonConfirmContainer = styled.div`
  flex-direction: row;
  display: flex;
  gap: 5px;
  margin: 5px 0px 5px 0px;
  align-items: end;
  align-self: flex-end;
`


const ConfirmationButton = styled(StyledButton)`
  width: 74px;
  min-width: 74px;
  max-width: 74px;
  height: 40px;

  @media (max-width: 1200px) {
    min-width: 50px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    min-width: 50px;
    border-radius: 25.5px;
    height: 40px;
  }
`

const PolygonDrawingMapContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

export interface PolygonValidationDialogState {
  isOpen: boolean
  isError: boolean
  amount: null | number
}

const PlotLocation: FC<FormProps> = ({ formData, handleChangeFormData }) => {
  const { t } = useTranslation('plot')
  const { t: generalT } = useTranslation('general')
  const [polygon, setPolygon] = useState<{ lat: number | undefined, lng: number | undefined }[] | null>(formData.plotLocationForm.plotBounds || null)
  const [selectedCoordinates, setSelectedCoordinates] = useState<PlotCenter | undefined>()
  const [defaultPolygon, setDefaultPolygon] = useState<PolygonBound[] | undefined>()
  const [checkIfPolygonCorrect, setCheckIfPolygonCorrect] = useState<
    { location: PlotCenter | undefined, tempBounds: PolygonBound[] | undefined }
  >(formData.plotLocationForm.shouldDisplayPolygonConfirmation ?
    { location: formData.plotLocationForm.plotLocation, tempBounds: formData.plotLocationForm.plotBounds } : { location: undefined, tempBounds: undefined })
  const [width, setWidth] = useState(window.innerWidth)
  const mapTextRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (width < 1200) {
        mapTextRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 1500)
    return () => clearTimeout(timer)
  }, [width])

  const onPlotLocationSelected = useCallback(
    (
      location: PlotCenter | undefined,
      tempBounds: PolygonBound[] | undefined,
      displayQuestion: boolean,
      displayPolygon: boolean,
      preventCompletion?: boolean
    ) => {
      handleChangeFormData({
        ...formData,
        plotLocationForm: {
          ...formData.plotLocationForm,
          plotAreaAmount: 10, // Not important data anymore
          plotBounds: tempBounds,
          plotLocation: location,
          shouldDisplayPolygonConfirmation: displayQuestion,
          shouldDisplayPolygon: displayPolygon,
          completed: !!(
            location &&
            !preventCompletion &&
            tempBounds
          )
        }
      })
    },
    [formData, handleChangeFormData]
  )

  const clearLocation = useCallback(() => {
    onPlotLocationSelected(undefined, undefined, false, true)
    setPolygon(null)
  }, [onPlotLocationSelected])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  const polygonOnChange = useCallback(async (lat: number, lng: number, defaultPolygon: PolygonBound[] | undefined) => {
      setDefaultPolygon(defaultPolygon)
      setSelectedCoordinates({ latitude: lat, longitude: lng })
      setPolygon(defaultPolygon as PolygonBound[])
      const locationCoords = { latitude: lat, longitude: lng }
      onPlotLocationSelected(locationCoords, defaultPolygon as PolygonBound[], false, false, false)
  }, [onPlotLocationSelected])

  const handlePolygonConfirmation = (condition: boolean) => {
    if (condition && checkIfPolygonCorrect.location && checkIfPolygonCorrect.tempBounds) {
      onPlotLocationSelected(checkIfPolygonCorrect.location, checkIfPolygonCorrect.tempBounds, false, true)
      setCheckIfPolygonCorrect(prev => ({ ...prev, displayQuestion: false }))
    } else {
      onPlotLocationSelected(selectedCoordinates, defaultPolygon, false, false)
      setCheckIfPolygonCorrect(prev => ({ ...prev, displayQuestion: false }))
      setPolygon(null)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [formData])

  return (
    <LocationContainer id='plot-location-form'>
      {
        !!formData.plotLocationForm.shouldDisplayPolygonConfirmation && (
          <TextContainer ref={mapTextRef}>
            <ColoredTextContainer>
              <TextGreenQuestion>{t('plot_polygon_confirm_title')}</TextGreenQuestion>
              <TextWhiteQuestion>{t('plot_polygon_confirm_subtext')}</TextWhiteQuestion>
            </ColoredTextContainer>
            <TextSpace />
            <PlotPolygonConfirmContainer>
              <ConfirmationButton text={generalT('yes')} onClick={() => { handlePolygonConfirmation(true) }} />
              <ConfirmationButton altStyle text={generalT('no')} onClick={() => { handlePolygonConfirmation(false) }} />
            </PlotPolygonConfirmContainer>
          </TextContainer>
        )
      }
      {
        !formData.plotLocationForm.shouldDisplayPolygonConfirmation && (
          <TextContainer style={{ flexDirection: 'column' }} ref={mapTextRef}>
            <ColoredTextContainer>
              <TextWhite>{t('select_marker_1')}</TextWhite>
              {/* <TextWhite>{t('select_marker_2')}</TextWhite> */}
            </ColoredTextContainer>
            <TextSpace />
            <PlotPolygonConfirmContainer>
              <ClearText
                onClick={() => {
                  analytics.sendEvent(analytics.events.onboardingClearPolygonMapClick)
                  clearLocation()
                }}
              >
                {t('clear_selection')}
              </ClearText>
            </PlotPolygonConfirmContainer>
          </TextContainer>
        )
      }
      <PolygonDrawingMapContainer>
        <PolygonDrawingMap
          defaultLocation={formData.plotLocationForm.plotLocation}
          onMarkerDrawn={onPlotLocationSelected}
          polygonOnChange={polygonOnChange}
          generatedPolygon={formData.plotLocationForm.shouldDisplayPolygon ? polygon : null}
        />
      </PolygonDrawingMapContainer>
    </LocationContainer>
  )
}

export default PlotLocation
