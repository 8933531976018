import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import SatelliteAlertIcon from 'assets/images/icon_satellite_alerts.svg'
import ArrowLeftIcon from 'assets/images/icon_alert_arrow_left.svg'
import ArrowRightIcon from 'assets/images/icon_alert_arrow_right.svg'
import WeatherAlertIcon from 'assets/images/icon_weather_alert.svg'
import { HeatWaveAlert, Plot, SatelliteAlertsType } from 'utils/types'
import {
  generateHeatWavesAlerts,
  generateSatelliteAlerts
} from 'utils/plotAlertHelpers'

const ContentContainer = styled.div`
  color: #dcf900;
  text-align: center;
  align-items: center;
  margin-top: 55px;
`

const TitleContainer = styled.div<{
  direction: string
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) =>
    direction === 'rtl' ? 'row-reverse' : 'unset'};
  align-items: center;
  color: #dcf900;
  padding-top: 12px;
  padding-inline: 30px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding-inline: 0;
    padding-top: 0;
  }
`

const Title = styled.div`
  font-size: 20px;
`

const ContentBodyContainer = styled.div`
  padding: 45px 20px 10px 80px;
  text-align: start;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 45px 20px 10px 20px;
  }
`

const TextTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const TextTitleItemContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`

const TextContainer = styled.div<{
  direction: string
}>`
  padding-left: ${({ direction }) => (direction === 'rtl' ? '0' : '18px')};
  padding-right: ${({ direction }) => (direction === 'rtl' ? '18px' : '0')}; ;
`

const Text = styled.b`
  font-size: 14px;
  color: #f0fcfb;
`

const Marker = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #dcf900;
  margin-right: 10px;
  vertical-align: middle;
`

const SubText = styled.small`
  font-size: 80%;
  color: #f0fcfb;
  display: block;
  font-size: 12px;
`

const BorderBottom = styled.div`
  border-bottom: 1px solid #435c61;
  padding-top: 40px;
  width: 90%;
  left: 15%;
`

const Icon = styled.img`
  width: 32px;
  height: 32px;
`

const ArrowLeft = styled(Icon)`
  margin-left: 20px;
  cursor: pointer;
`

const ArrowRight = styled(Icon)`
  margin-right: 20px;
  cursor: pointer;
`

const Space = styled.div`
  height: 1rem;
`

interface PlotAlertProps {
  plot: Plot | null
  plotFieldData: any
  plotHeatWave: any
  nextPlot: () => void
  previousPlot: () => void
}

const PlotAlert: FC<PlotAlertProps> = ({
  plot,
  plotFieldData,
  plotHeatWave,
  nextPlot,
  previousPlot
}) => {
  const { t, i18n } = useTranslation('geoMap')
  const [plotAlerts, setPlotAlert] = useState<SatelliteAlertsType>({
    isStressAlert: false,
    isUnderIrrigationAlert: false,
    isOverIrrigationAlert: false,
    date: ''
  })

  const [heatWaveAlert, setHeatWaveAlert] = useState<HeatWaveAlert>({
    isDryNight: false,
    isDryTomorrow: false,
    isDryAfterTomorrow: false,
    date: ''
  })

  const getAlertsData = useCallback(() => {
    if (!plotFieldData) {
      return
    }

    const satelliteData = generateSatelliteAlerts(plotFieldData, t)
    if (satelliteData) {
      setPlotAlert(satelliteData)
    }

    const heatWaveData = generateHeatWavesAlerts(plotHeatWave, t)
    if (heatWaveData) {
      setHeatWaveAlert(heatWaveData)
    }
  }, [plotFieldData, plotHeatWave, t])

  useEffect(() => {
    getAlertsData()
  }, [getAlertsData])

  return (
    <ContentContainer>
      <TitleContainer direction={i18n.dir()}>
        <ArrowLeft src={ArrowLeftIcon} onClick={previousPlot} />
        <Title>{plot?.name}</Title>
        <ArrowRight src={ArrowRightIcon} onClick={nextPlot} />
      </TitleContainer>
      <ContentBodyContainer>
        <TextTitleContainer>
          <TextTitleItemContainer>
            <Icon src={SatelliteAlertIcon} />
            &#160;&#160;
            <Text>{t('satellite_alerts')}</Text>
          </TextTitleItemContainer>
          <TextTitleItemContainer>
            <SubText>{plotAlerts.date}</SubText>
          </TextTitleItemContainer>
        </TextTitleContainer>
        <Space />
        {plotAlerts.isStressAlert && (
          <TextContainer direction={i18n.dir()}>
            <Marker />
            <Text>{t('stress_alert_text')}</Text>
            <SubText>{t('stress_alert_subText')}</SubText>
          </TextContainer>
        )}
        {plotAlerts.isUnderIrrigationAlert && (
          <TextContainer direction={i18n.dir()}>
            <Marker />
            <Text>{t('under_irrigation_alert_text')}</Text>
            <SubText>{t('under_irrigation_alert_subText')}</SubText>
          </TextContainer>
        )}
        {plotAlerts.isOverIrrigationAlert && (
          <TextContainer direction={i18n.dir()}>
            <Marker />
            <Text>{t('over_irrigation_alert_text')}</Text>
            <SubText>{t('over_irrigation_alert_subText')}</SubText>
          </TextContainer>
        )}
        {!plotAlerts.isStressAlert &&
          !plotAlerts.isOverIrrigationAlert &&
          !plotAlerts.isUnderIrrigationAlert && (
            <TextContainer direction={i18n.dir()}>
              <Marker />
              <Text>{t('no_satellite_alerts')}</Text>
            </TextContainer>
          )}
        <BorderBottom />
      </ContentBodyContainer>
      <ContentBodyContainer>
        <TextTitleContainer>
          <TextTitleItemContainer>
            <Icon src={WeatherAlertIcon} />
            &#160;&#160;
            <Text>{t('weather_alerts')}</Text>
          </TextTitleItemContainer>
          <TextTitleItemContainer>
            <SubText>{heatWaveAlert.date}</SubText>
          </TextTitleItemContainer>
        </TextTitleContainer>
        <Space />
        {heatWaveAlert.isDryNight && (
          <TextContainer direction={i18n.dir()}>
            <Marker />
            <Text>{t('hw_alert_dry_night_text')}</Text>
            <SubText>{t('hw_alert_dry_night_subText')}</SubText>
          </TextContainer>
        )}
        {heatWaveAlert.isDryTomorrow && (
          <TextContainer direction={i18n.dir()}>
            <Marker />
            <Text>{t('hw_alert_dry_tomorrow_text')}</Text>
            <SubText>{t('hw_alert_dry_tomorrow_subText')}</SubText>
          </TextContainer>
        )}
        {heatWaveAlert.isDryAfterTomorrow && (
          <TextContainer direction={i18n.dir()}>
            <Marker />
            <Text>{t('hw_alert_dry_after_tomorrow_text')}</Text>
            <SubText>{t('hw_alert_dry_after_tomorrow_subText')}</SubText>
          </TextContainer>
        )}
        {!heatWaveAlert.isDryNight &&
          !heatWaveAlert.isDryTomorrow &&
          !heatWaveAlert.isDryAfterTomorrow && (
            <TextContainer direction={i18n.dir()}>
              <Marker />
              <Text>{t('no_hw_alert')}</Text>
            </TextContainer>
          )}
      </ContentBodyContainer>
    </ContentContainer>
  )
}

export default PlotAlert
