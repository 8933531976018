import React, { SVGProps } from 'react'

const IconCheckboxChecked = ({ fill }: SVGProps<unknown>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <path
      data-name="Rectangle 7"
      transform="translate(.5 .5)"
      fill="none"
      strokeMiterlimit={10}
      stroke={fill}
      d="M0 0h16v16H0z"
    />
    <path
      data-name="Path 3"
      d="m1763 11966.779 5.5 6.721 9.5-12.5"
      transform="translate(-1761.613 -11960.697)"
      fill="none"
      strokeMiterlimit={10}
      stroke={fill}
    />
  </svg>
)

export default IconCheckboxChecked
