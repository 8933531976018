import React, { FC, memo } from 'react'
import styled from '@emotion/styled/macro'
import { Fade } from 'react-awesome-reveal'
import GlobeIcon from 'assets/images/icon_about_us_globe.svg'

import { useTranslation } from 'react-i18next'
import OnePagerText from './OnePagerText'
import OnePagerPagination from './OnePagerPagination'

const Globe = styled.img`
  width: 45vw;
  max-width: 737px;
  height: 50vh;
  max-height: 737px;
  pointer-events: none;
  right: calc(320px + 30vw);
  bottom: 270px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 255px;
    width: 255px;
    position: unset;
    align-self: center;
  }
`

const PageContentContainer = styled.div`
  display: flex;
  background: #052b33;
  overflow: hidden;
  position: relative;
  padding-top: 15vh;
  max-height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  flex-direction: row-reverse;

  body[dir='rtl'] & {
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex-direction: column-reverse;
    padding-top: 10px;
    min-height: 90vh;
    max-height: unset;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;

    body[dir='rtl'] & {
      flex-direction: column-reverse;
    }
  }
`

const ImageContainer = styled.div`
  height: 100vh;
  margin-top: -15vh;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: unset;
    margin-top: 0px;
    align-self: center;
  }
`

const PageTextParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50vw;
  flex: 4;
  align-items: center;
  padding: 0px 10px 0px 30px;
  margin-top: -15vh;
  align-self: center;

  body[dir='rtl'] & {
    padding: 0px 30px 0px 10px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    align-self: center;
    padding: 0px 30px;
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;

    body[dir='rtl'] & {
      padding: 0px 30px;
    }
  }
`

export interface OnePagerPageProps {
  handleChangePageIndex: (index: number) => void
  isMobile?: boolean
  focusDisabled: boolean
}

const OnePagerFirstPage: FC<OnePagerPageProps> = ({
  handleChangePageIndex
}) => {
  const { t } = useTranslation('onePager')

  return (
    <PageContentContainer>
      <PageTextParentContainer>
        <Fade triggerOnce direction={'up'}>
          <OnePagerText
            titleText={t('page_title_1')}
            subText={t('page_subtext_1')}
          />
        </Fade>
        <OnePagerPagination
          index={0}
          length={4}
          onChange={handleChangePageIndex}
        />
      </PageTextParentContainer>
      <ImageContainer>
        <Globe src={GlobeIcon} />
      </ImageContainer>
    </PageContentContainer>
  )
}

export default memo(OnePagerFirstPage)
