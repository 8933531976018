import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import { FormProps } from './FormScreen'
import { UtmData } from 'utils/types'
import { useTranslation } from 'react-i18next'
import stripePaymentConfig from '../../../stripePaymentConfig.json'
import {
  PLANT_ONBOARDING_USER_COUNTRY,
  PLANT_ONBOARDING_USER_VERIFY,
  isAustraliaStrip
} from 'utils/formHelpers'
import { AppContext } from 'core/AppContext'
import { getCheckoutSession, processUser } from 'utils/userManagement'
import eventEmitter, { events } from 'utils/eventEmitter'
import verySessionStorage from 'utils/vss'
import useQueryParams from 'hooks/useQueryParams'
import { isProdEnv } from 'utils/common'
import api from 'Api/ApiMethods'

const PaymentRouteContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  height: 100%;
  margin-top: -2vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: unset;
    display: flex;
    align-self: start;
    margin-top: 0px;
  }
`

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

const StripePricingTable = styled.div`
  width: 70%;
  text-align: center;
  overflow-y: scroll;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: auto;
  }
`

const CouponContainer = styled.div`
  display: block;
  justify-content: flex-start;
  margin-top: 10px;
  letter-spacing: -0.5px;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    letter-spacing: -0.4px;
  }
`

const CouponText = styled.span`
  font-size: 20px;
  font-weight: 400;
  align-self: center;
  color: ${({ theme }) => theme.colors.sections.paymentOptions.couponText};

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const CouponTextLink = styled.span`
  font-size: 20px;
  font-weight: 400;
  align-self: center;
  color: ${({ theme }) => theme.colors.sections.paymentOptions.couponLink};
  text-underline-offset: 3px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.sections.paymentOptions.couponLinkHover};
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
  }
`

const PaymentOptions: FC<FormProps> = ({
  formData,
  handleChangeFormData,
  utmData,
  handlePostOnboarding
}) => {
  const { t, i18n } = useTranslation('formScreen')
  const { t: paymentOptionsT } = useTranslation('paymentOptions')
  const userCountryData = useContext(AppContext)
  const urlQueryParams = useQueryParams()
  const lang = useMemo(() => i18n.language, [i18n.language])
  const country = useMemo(
    () => userCountryData.country,
    [userCountryData.country]
  )

  const stripePaymentForm = useCallback(() => {
    const newFormData = {
      ...formData,
      paymentForm: {
        ...formData.paymentForm,
        payment: {
          sessionId: urlQueryParams.get('stripeSessionId'),
          status: 'stripe'
        },
        paymentSuccessful: true,
        completed: true
      }
    }

    handleChangeFormData(newFormData)
  }, [formData, handleChangeFormData, urlQueryParams])

  const getUserData = useCallback(async () => {
    const userVerify = verySessionStorage.get(PLANT_ONBOARDING_USER_VERIFY)
    const userCountry = verySessionStorage.get(PLANT_ONBOARDING_USER_COUNTRY)
    eventEmitter.emit(events.ENABLE_LOADING)
    const userData = await processUser(
      userVerify.authToken,
      userVerify.userObject,
      utmData as UtmData,
      formData,
      userCountry.country,
      lang,
      t
    )

    if (userData && userData.growerId) {
      await getCheckoutSession(
        userVerify.authToken,
        urlQueryParams.get('stripeSessionId') || '',
        userData.growerId,
        userVerify.userObject?.id,
        userCountry.country,
        t
      )
    }

    eventEmitter.emit(events.DISABLE_LOADING)
    if (handlePostOnboarding) {
      // from Stripe payment
      handlePostOnboarding(
        formData,
        t
      )
    }
  }, [formData, handlePostOnboarding, t, urlQueryParams, utmData, lang])

  useEffect(() => {
    if (urlQueryParams.has('isStripeSuccess')) {
      stripePaymentForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formData.paymentForm.payment.status === 'stripe') {
      getUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.paymentForm.payment.status])

  const handleClickCoupon = useCallback(async () => {
    const url = await api.postCheckoutSession(
      verySessionStorage.get(PLANT_ONBOARDING_USER_VERIFY).authToken,
      (stripePaymentConfig.markets as any)[userCountryData.country]
        .couponPriceId[isProdEnv() ? 'live' : 'test'],
      userCountryData.country
    )
    window.location.replace(url.data)
  }, [userCountryData.country])

  return (
    <PaymentRouteContainer id='payment-options-form'>
      <StyledFieldset>
        <StripePricingTable>
          <stripe-pricing-table
            pricing-table-id={
              (stripePaymentConfig.markets as any)[country].priceTableId[
              isProdEnv() ? 'live' : 'test'
              ]
            }
            publishable-key={
              isAustraliaStrip(country)
                ? process.env.REACT_APP_STRIPE_PK_ISRAEL
                : process.env.REACT_APP_STRIPE_PK_GLOBAL
            }
          ></stripe-pricing-table>
          <CouponContainer>
            <CouponText>{paymentOptionsT('coupon')}</CouponText>
            <CouponText>{'\u00A0'}</CouponText>
            <CouponTextLink onClick={handleClickCoupon}>
              {paymentOptionsT('click_here')}
            </CouponTextLink>
          </CouponContainer>
        </StripePricingTable>
      </StyledFieldset>
    </PaymentRouteContainer>
  )
}

export default PaymentOptions
