import React, { FC, RefObject, createRef, useCallback, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'

const ScrollContainer = styled.div<{ $scrollOverflow: string }>`
  display: flex;
  overflow: ${({ $scrollOverflow }) => $scrollOverflow};
  max-width: 100vw;
  margin-bottom: 100px;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`

const PhoneImage = styled.img<{ $imageSelected: boolean }>`
  height: 390px;
  width: 195px;
  margin: 15px 10px;
  box-shadow: ${({ theme }) => theme.colors.imageCarousel.boxShadow};
  border-radius: 28px;
  opacity: ${({ $imageSelected }) => ($imageSelected ? '1' : '0.5')};
`

interface ScrollableImage {
  ref: RefObject<HTMLImageElement>,
  image: string
  key: string
}

interface IntroScreenMobileImageScrollProps {
  images: string[]
}

const IntroScreenMobileImageScroll: FC<IntroScreenMobileImageScrollProps> = ({
  images
}) => {
  const { i18n } = useTranslation('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isRight = useMemo(() => i18n.dir() === 'rtl', [i18n.language])
  const scrollRef = useRef<HTMLDivElement>(null)
  const loadedImages = useRef<boolean[]>(images.map(() => false))
  const [selectedImage, setSelectedImage] = useState(1)
  const [touchPosition, setTouchPosition] = useState<number | null>(null)
  const [scrollOverflow, setScrollOverflow] = useState<'auto' | 'hidden'>(
    'hidden'
  )

  const scrollableImages: ScrollableImage[] = useMemo(() => (
    images.map((image, index) => ({ image, ref: createRef(), key: index.toString() }))
  ), [images])

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      const touchDown = e.touches[0].clientX
      setTouchPosition(touchDown)
    },
    []
  )

  const scrollToOffset = useCallback(
    (
      contentReference: React.RefObject<HTMLImageElement>,
      scrollReference: React.RefObject<HTMLDivElement>
    ) => {
      const contentOffset =
        (contentReference.current?.offsetLeft || 0) -
        ((scrollReference?.current?.clientWidth || 0) -
          (contentReference?.current?.width || 0)) /
          2 // center the image
      setScrollOverflow('auto')
      scrollRef.current?.scrollTo({
        left: contentOffset,
        behavior: 'smooth'
      })
      setTimeout(() => {
        setScrollOverflow('hidden')
      }, 250)
    },
    []
  )

  const scrollToIndex = useCallback(
    (index: number) => {
      let targetIndex = index
      const lastIndex = scrollableImages.length - 1
      if (targetIndex < 0) {
        targetIndex = 0
      }

      if (targetIndex > lastIndex) {
        targetIndex = lastIndex
      }

      const selectedImage = scrollableImages.find((_image, index) => index === targetIndex)
      if (selectedImage && selectedImage?.ref) {
        setSelectedImage(targetIndex)
        scrollToOffset(selectedImage.ref, scrollRef)
      }
    },
    [scrollableImages, scrollToOffset]
  )

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      const touchDown = touchPosition

      if (touchDown === null) {
        return
      }

      const currentTouch = e.touches[0].clientX
      const diff = touchDown - currentTouch

      if (diff > 5) {
        scrollToIndex(isRight ? selectedImage - 1 : selectedImage + 1)
      }

      if (diff < -5) {
        scrollToIndex(isRight ? selectedImage + 1 : selectedImage - 1)
      }

      setTouchPosition(null)
    },
    [isRight, scrollToIndex, selectedImage, touchPosition]
  )

  const scrollToCenter = useCallback(() => {
    const ScrollWidth = scrollRef?.current?.scrollWidth
    const ScrollOffset = scrollRef?.current?.offsetWidth
    const ScrollPosition = isRight
      ? ((ScrollOffset || 0) - (ScrollWidth || 0)) / 2
      : -1 * (((ScrollOffset || 0) - (ScrollWidth || 0)) / 2)
    scrollRef?.current?.scrollTo(ScrollPosition, 0)
  }, [isRight])

  const selectImage = useCallback((index: number) => () => {
    scrollToIndex(index)
  }, [scrollToIndex])

  const onImageLoad = useCallback((imageIndex: number) => () => {
    loadedImages.current[imageIndex] = true
    if (loadedImages.current.every(value => value)) {
      scrollToCenter()
    }
  }, [loadedImages, scrollToCenter])

  return (
    <ScrollContainer
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      ref={scrollRef}
      $scrollOverflow={scrollOverflow}
    >
      {scrollableImages.map(({ image, ref, key }, index) => (
        <PhoneImage
          key={key}
          $imageSelected={selectedImage === index}
          ref={ref}
          onClick={selectImage(index)}
          onLoad={onImageLoad(index)}
          src={image}
        />
      ))}
    </ScrollContainer>
  )
}

export default IntroScreenMobileImageScroll
