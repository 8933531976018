import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import styled from '@emotion/styled/macro'
import HeaderLogo from 'core/components/HeaderLogo'
import { UserContext } from 'core/UserContext'
import { BrowserRoutes } from 'core/Routes'
import { useNavigate } from 'react-router-dom'
import { OnboardingMode, Plot, UserSettingsAction } from 'utils/types'
import { useTranslation } from 'react-i18next'
import verySessionStorage from 'utils/vss'
import { PLANT_ONBOARDING_MODE } from 'utils/formHelpers'
import UpdateSubscription from './UpdateSubscription/UpdateSubscription'
import UserSettingsDashboard from './UserSettingsDashboard'
import analytics from 'utils/analytics'

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
`

interface UserSettingsContainerProps {
  userSettingsAction: UserSettingsAction | null
  onActionChange: Dispatch<SetStateAction<UserSettingsAction | null>>
  setUserPlot: Dispatch<SetStateAction<Plot | null>>
}

const UserSettingsContainer: FC<UserSettingsContainerProps> = ({
  onActionChange,
  userSettingsAction,
  setUserPlot
}) => {
  const getContainer = useCallback(() => {
    switch (userSettingsAction) {
      case UserSettingsAction.UpdateSubscription:
        return <UpdateSubscription onActionChange={onActionChange} />
      default:
        return (
          <UserSettingsDashboard
            onActionChange={onActionChange}
            setUserPlot={setUserPlot}
          />
        )
    }
  }, [userSettingsAction, onActionChange, setUserPlot])

  return getContainer()
}

const UserSettings: FC = () => {
  const { t } = useTranslation('userSettings')
  const userContext = useContext(UserContext)
  const navigate = useNavigate()
  const [userSettingsAction, setUserSettingsAction] =
    useState<UserSettingsAction | null>(null)

  const [userPlot, setUserPlot] = useState<Plot | null>(null)

  useEffect(() => {
    if (userSettingsAction === UserSettingsAction.AddPlot) {
      userContext.updateOnboardingMode(OnboardingMode.Add)
      verySessionStorage.set(PLANT_ONBOARDING_MODE, OnboardingMode.Add)
      navigate(
        `/onboarding?resellerId=${userContext.userSubscription?.resellerId}`,
        { state: userPlot }
      )
    }
  }, [userSettingsAction, userContext, navigate, userPlot])

  const logoutOnClickHandle = useCallback(() => {
    userContext.userLogout()
    navigate(BrowserRoutes.Login)
    analytics.sendEvent(analytics.events.userSettingsLogoutClick)
  }, [navigate, userContext])

  return (
    <PageContainer>
      <HeaderLogo
        onePagerOpen={false}
        isLogin
        userSettingsHeaderText={`${t('hello')}, ${
          userContext.userSettings?.firstName
        }  `}
        userLogoutText={t('log_out')}
        contactUsMessage={t('contact_us_user_settings_help')}
        logoutClick={logoutOnClickHandle}
      />
      <UserSettingsContainer
        userSettingsAction={userSettingsAction}
        onActionChange={setUserSettingsAction}
        setUserPlot={setUserPlot}
      />
    </PageContainer>
  )
}

export default UserSettings
