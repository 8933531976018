import React from 'react'
import { Global } from '@emotion/react'
import styled from '@emotion/styled/macro'
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  ThemeOptions
} from '@mui/material'
import Routes from 'core/Routes'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import ReactGA from 'react-ga'
import smartlookClient from 'smartlook-client'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import { globalStyles, theme } from './ui'
import vls from 'utils/vls'
import en from 'locales/en.json'
import he from 'locales/he.json'
import es from 'locales/es.json'
import fr from 'locales/fr.json'
import ar from 'locales/ar.json'
import Loading from 'core/Loading'
import { AppContextProvider } from 'core/AppContext'
import { UserContextProvider } from 'core/UserContext'
import { CoralogixRum } from '@coralogix/browser';
import packageJs from '../package.json'

const { REACT_APP_GA_MEASUREMENT_ID, REACT_APP_SMARTLOOK_API_KEY, REACT_APP_CORALOGIX_RUM_KEY, REACT_APP_CORALOGIX_RUM_ENABLED, REACT_APP_CORALOGIX_RUM_RECORDING_ENABLED, REACT_APP_CORALOGIX_RUM_APPLICATION_NAME } = process.env
if (REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID, {
    debug: process.env.NODE_ENV === 'development'
  })
  ReactGA.pageview(window.location.pathname + window.location.search)
}

if (REACT_APP_SMARTLOOK_API_KEY) {
  smartlookClient.init(REACT_APP_SMARTLOOK_API_KEY, { region: 'eu' })
}

if (!!REACT_APP_CORALOGIX_RUM_ENABLED) {
  CoralogixRum.init({
    public_key: REACT_APP_CORALOGIX_RUM_KEY,
    application: REACT_APP_CORALOGIX_RUM_APPLICATION_NAME || 'plant-undefined', // Provide a default value of an empty string if CORALOGIX_RUM_APPLICATION_NAME is undefined
    version: packageJs.version,
    coralogixDomain: 'EU1',
    sessionRecordingConfig: {
      enable: !!REACT_APP_CORALOGIX_RUM_RECORDING_ENABLED || false,
      autoStartSessionRecording: true,
      sessionRecordingSampleRate: 100
    }
  })
}

i18next.use(initReactI18next).init({
  resources: { en, he, es, fr, ar },
  fallbackLng: ['en', 'es', 'he', 'fr', 'ar'],
  lng: (vls.get('i18next_lng') as string) || 'en',
  interpolation: { escapeValue: false },
  returnEmptyString: false
})

document.body.dir = i18next.dir()
i18next.on('languageChanged', (lng) => {
  vls.set('i18next_lng', lng)
  const body = document.body
  body.dir = i18next.dir()
})

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;

  @media (max-width: ${theme.media.width.sm}) {
    min-height: 100%;
    height: 100%;
  }
`

function App() {
  const muiTheme = createTheme(theme as unknown as ThemeOptions)
  return (
    <BrowserRouter>
      <AppContextProvider>
        <UserContextProvider>
          <Page>
            <ToastContainer />
            <Global styles={globalStyles} />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <Loading />
                <Routes />
              </ThemeProvider>
            </StyledEngineProvider>
          </Page>
        </UserContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  )
}

export default App
