import styled from '@emotion/styled/macro'
import React, { FC } from 'react'
import { Checkbox, CheckboxProps } from '@mui/material'
import IconCheckboxChecked from 'assets/icons/IconCheckboxChecked'
import { theme } from 'ui'
import IconCheckboxUnchecked from 'assets/icons/IconCheckboxUnchecked'

interface ICheckboxProps extends CheckboxProps {
  isSatellite?: boolean
}

const StyledCheckbox = styled(Checkbox)`
  padding: 0px;
  margin: 0px 10px;

  > svg {
    height: 16px;
    width: 16px;

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      height: 14px;
      width: 14px;
    }
  }
`

const CustomCheckbox: FC<ICheckboxProps> = (props) => {
  return (
    <StyledCheckbox
      icon={
        <IconCheckboxUnchecked
          fill={
            props.isSatellite
              ? theme.colors.sections.plotName.checkboxDark
              : theme.colors.sections.plotName.checkbox
          }
        />
      }
      checkedIcon={
        <IconCheckboxChecked
          fill={
            props.isSatellite
              ? theme.colors.sections.plotName.checkboxDark
              : theme.colors.sections.plotName.checkbox
          }
        />
      }
      {...props}
    />
  )
}

export default CustomCheckbox
