import styled from '@emotion/styled/macro'
import React, {
  FC,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useContext
} from 'react'
import IntroScreen from 'pages/Onboarding/introScreen/IntroScreen'
import {
  PlotForm,
  Steps,
  Subdomain,
  checkIsMasterPoolOrCouponPayment,
  getSubdomain
} from 'utils/formConfig'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import HeaderLogo from 'core/components/HeaderLogo'
import analytics from 'utils/analytics'
import useWindowSize from 'hooks/useWindowSize'
import useQueryParams from 'hooks/useQueryParams'
import verySessionStorage from 'utils/vss'
import {
  PLANT_ONBOARDING_CURRENT_PAGE,
  PLANT_ONBOARDING_CURRENT_STEP,
  PLANT_ONBOARDING_MODE,
  PLANT_ONBOARDING_USER_COUNTRY,
  createInitialFormFromParams
} from 'utils/formHelpers'
import { AppContext } from 'core/AppContext'
import { UserContext } from 'core/UserContext'
import { useTranslation } from 'react-i18next'
import FormScreen from './formScreen/FormScreen'
import EndScreen from './endScreen/EndScreen'
import OnePager from './onePager/OnePager'
import SavedMoneyScreen from './savedMoneyScreen/SavedMoneyScreen'
import AskOtherCrop from './AskOtherCrop/AskOtherCrop'

const Page = styled.div<{ allowScrollbar?: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: ${({ allowScrollbar }) => (allowScrollbar ? 'auto' : 'hidden')};
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 100%;
  }
`

const PageContainer = styled.div<{ allowScrollbar?: boolean }>`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 100%;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`

interface PageStep {
  stepId: Steps
  component: FC<PageProps>
}

export interface PageProps {
  handleClickNextPage: (pageNumber: number, formData?: PlotForm) => void
  scrollRef?: HTMLDivElement | null
  formData?: PlotForm
  pageNumber: number
  currentStep?: number
}

const pageSteps: PageStep[] = [
  {
    stepId: Steps.LandingPage,
    component: IntroScreen
  },
  {
    stepId: Steps.Form,
    component: FormScreen
  },
  {
    stepId: Steps.SavedMoney,
    component: SavedMoneyScreen
  },
  {
    stepId: Steps.Ending,
    component: EndScreen
  },
  {
    stepId: Steps.OtherCrop,
    component: AskOtherCrop
  }
]

const Onboarding: FC = () => {
  const subdomain = getSubdomain()
  const { t } = useTranslation('formScreen')
  const urlQueryParams = useQueryParams()
  const initFormParams = useMemo(
    () => ({
      cropType: urlQueryParams.get('cropType'),
      couponCode: urlQueryParams.get('couponCode'),
      variety: urlQueryParams.get('variety')
    }),
    [urlQueryParams]
  )
  const [onePagerOpen, setOnePagerOpen] = useState(false)
  const [currentPageStep, setCurrentPageStep] = useState(pageSteps[Steps.Form])
  const initialFormFromParams = useMemo(
    () =>
      createInitialFormFromParams(
        initFormParams.cropType,
        initFormParams.variety
      ),
    [initFormParams.cropType, initFormParams.variety]
  )
  const [formData, setFormData] = useState<PlotForm | undefined>(
    initialFormFromParams
  )
  const nodeRef = useRef(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()
  const isMobile = useMemo(() => !!(width && width < 900), [width])
  const [allowOverflow, setAllowOverflow] = useState(isMobile ? true : false)
  const [currentFormStep, setCurrentFormStep] = useState(
    initialFormFromParams ? (initFormParams.variety ? 2 : 1) : 0
  )
  const userCountryData = useContext(AppContext)
  const userContext = useContext(UserContext)
  const isSavedMoneyScreenAvailable = useMemo(
    () => checkIsMasterPoolOrCouponPayment(),
    []
  )
  const totalPagesNumber = isSavedMoneyScreenAvailable
    ? pageSteps.length
    : pageSteps.length - 1

  const handleClickOnePager = useCallback(() => {
    if (!onePagerOpen) {
      analytics.sendEvent(analytics.events.onboardingAboutUsClick)
    }

    setOnePagerOpen((prev) => !prev)
  }, [onePagerOpen])

  const handleClickLogo = useCallback(() => {
    analytics.sendEvent(analytics.events.onboardingLogoRestartClick)
    verySessionStorage.clear()
    setCurrentFormStep(0)
    setFormData(undefined)
    setCurrentPageStep(pageSteps[Steps.Form])
  }, [])

  const handleClickNextPage = useCallback(
    (nextPageId: number, formData?: PlotForm) => {
      if (nextPageId - 1 === Steps.Form && !isSavedMoneyScreenAvailable) {
        setCurrentPageStep(pageSteps[Steps.Ending])
      } else if (nextPageId <= totalPagesNumber) {
        setCurrentPageStep(pageSteps[nextPageId])
      }

      if (formData) {
        setFormData(formData)
      }
    },
    [totalPagesNumber, isSavedMoneyScreenAvailable]
  )

  useEffect(() => {
    if (currentPageStep === pageSteps[Steps.LandingPage] && !isMobile) {
      setAllowOverflow(false)
    } else {
      setAllowOverflow(true)
    }
  }, [currentPageStep, isMobile])

  useEffect(() => {
    const sessionStoragePageNumber = verySessionStorage.getNumber(
      PLANT_ONBOARDING_CURRENT_PAGE
    )

    const formStep = verySessionStorage.getNumber(PLANT_ONBOARDING_CURRENT_STEP)
    if (formStep) {
      setCurrentPageStep(pageSteps[sessionStoragePageNumber])
      setCurrentFormStep(formStep)
    }

    const country = verySessionStorage.get(PLANT_ONBOARDING_USER_COUNTRY)
    if (country) {
      userCountryData.onChangeCountry(country.country)
    }

    const onboardingMode = verySessionStorage.get(PLANT_ONBOARDING_MODE)
    if (onboardingMode) {
      userContext.updateOnboardingMode(onboardingMode)
    }
  }, [urlQueryParams, userContext, userCountryData])

  return (
    <PageContainer>
      <Page allowScrollbar={allowOverflow} ref={scrollRef}>
        <HeaderLogo
          onePagerOpen={onePagerOpen}
          contactUsMessage={t('contact_us_onboarding_help')}
          handleClickLogo={handleClickLogo}
          handleClickOnePager={handleClickOnePager}
        />
        <BodyContainer>
          <SwitchTransition mode={'out-in'}>
            <CSSTransition
              key={currentPageStep.stepId}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false)
              }}
              onExiting={() => {
                setAllowOverflow(false)
              }}
              onEntered={() => {
                if (
                  currentPageStep === pageSteps[Steps.Form] &&
                  !isMobile
                ) {
                  setAllowOverflow(false)
                } else {
                  setAllowOverflow(true)
                }
              }}
              classNames="fade"
            >
              <div ref={nodeRef} style={{ display: 'flex', flex: '1' }}>
                <currentPageStep.component
                  handleClickNextPage={handleClickNextPage}
                  scrollRef={scrollRef.current}
                  pageNumber={currentPageStep.stepId}
                  formData={formData}
                  currentStep={currentFormStep}
                />
              </div>
            </CSSTransition>
          </SwitchTransition>
        </BodyContainer>
      </Page>
      <OnePager
        isMobile={isMobile}
        onePagerOpen={onePagerOpen}
        handleClickOnePager={handleClickOnePager}
      />
    </PageContainer>
  )
}

export default Onboarding
