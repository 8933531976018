import React, { SVGProps } from 'react'

const IconClementine = ({ fill }: SVGProps<unknown>) => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Isolation_Mode" clip-path="url(#clip0_371_4538)">
      <path
        id="Vector"
        d="M20.5008 1.00391C9.69002 1.00391 0.925781 9.75088 0.925781 20.5404C0.925781 31.3298 9.69002 40.0768 20.5008 40.0768C31.3116 40.0768 40.0758 31.3298 40.0758 20.5404C39.9371 15.5207 39.1046 11.5395 34.8034 7.96226C32.8147 2.60791 26.1663 0.473092 20.5008 1.00391Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M30.062 15.4283C31.3107 12.405 29.877 8.94311 26.8477 7.6853C29.877 8.94311 31.3223 12.4165 30.062 15.4399V15.4283Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M33.3911 14.2052C32.1308 11.1818 28.6621 9.75091 25.6328 10.9972C28.6621 9.73937 32.1424 11.1818 33.4027 14.2052H33.3911Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M12.394 10.2355C12.6558 10.2355 12.868 10.0237 12.868 9.76243C12.868 9.50113 12.6558 9.28931 12.394 9.28931C12.1322 9.28931 11.9199 9.50113 11.9199 9.76243C11.9199 10.0237 12.1322 10.2355 12.394 10.2355Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M17.8061 8.88545C18.0679 8.88545 18.2801 8.67363 18.2801 8.41233C18.2801 8.15103 18.0679 7.93921 17.8061 7.93921C17.5443 7.93921 17.332 8.15103 17.332 8.41233C17.332 8.67363 17.5443 8.88545 17.8061 8.88545Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_6"
        d="M13.2397 15.9014C13.5015 15.9014 13.7137 15.6896 13.7137 15.4283C13.7137 15.167 13.5015 14.9552 13.2397 14.9552C12.9779 14.9552 12.7656 15.167 12.7656 15.4283C12.7656 15.6896 12.9779 15.9014 13.2397 15.9014Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_7"
        d="M31.2983 29.0911C31.5601 29.0911 31.7723 28.8793 31.7723 28.618C31.7723 28.3567 31.5601 28.1449 31.2983 28.1449C31.0365 28.1449 30.8242 28.3567 30.8242 28.618C30.8242 28.8793 31.0365 29.0911 31.2983 29.0911Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_8"
        d="M32.6518 23.7022C32.9136 23.7022 33.1258 23.4904 33.1258 23.2291C33.1258 22.9678 32.9136 22.756 32.6518 22.756C32.39 22.756 32.1777 22.9678 32.1777 23.2291C32.1777 23.4904 32.39 23.7022 32.6518 23.7022Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_9"
        d="M25.6225 29.2066C25.8843 29.2066 26.0965 28.9948 26.0965 28.7335C26.0965 28.4722 25.8843 28.2604 25.6225 28.2604C25.3607 28.2604 25.1484 28.4722 25.1484 28.7335C25.1484 28.9948 25.3607 29.2066 25.6225 29.2066Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_371_4538">
        <rect width="41" height="41" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconClementine
