import React, { FC } from 'react'
import styled from '@emotion/styled/macro'
import { Plot } from 'utils/types'
import { useTranslation } from 'react-i18next'

interface PlotsListItemProps {
  plotIndex: number
  plot?: Plot
}

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  color: white;
  padding-top: 30px;
`

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 15px;
  grid-auto-flow: column;
`

const BorderBottom = styled.div`
  border-bottom: 1px solid #b7d2cd;
  padding-top: 30px;
  width: 90%;
`

const Item = styled.div``

const PlotTitle = styled.div`
  color: #dcf900;
  font-size: 14px;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 13px;
  }
`

const ItemTitleText = styled.div`
  color: #b7d2cd;
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 15px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 13px;
  }
`

const ItemContentText = styled.div`
  color: #e8fbfa;
  font-weight: bold;
  font-size: 25px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 20px;
  }
`

const PlotsListItem: FC<PlotsListItemProps> = ({ plotIndex, plot }) => {
  const { t } = useTranslation('userSettings')
  return (
    <PageContainer>
      <BoxContainer>
        <PlotTitle>{`${t('plot')} ${plotIndex}`}:</PlotTitle>
      </BoxContainer>
      <BoxContainer>
        <Item>
          <ItemTitleText>{t('plot_name')}:</ItemTitleText>
          <ItemContentText>{plot?.name}</ItemContentText>
        </Item>
        <Item>
          <ItemTitleText>{t('crop')}:</ItemTitleText>
          <ItemContentText>{plot?.cropName}</ItemContentText>
        </Item>
        <Item>
          <ItemTitleText>{t('size')}:</ItemTitleText>
          <ItemContentText>{`${plot?.area.val} ${plot?.area.unit}`}</ItemContentText>
        </Item>
        <Item>
          <ItemTitleText>{t('variety')}:</ItemTitleText>
          <ItemContentText>{plot?.variety}</ItemContentText>
        </Item>
      </BoxContainer>
      <BorderBottom></BorderBottom>
    </PageContainer>
  )
}

export default PlotsListItem
