import React, { FC, useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { Fade } from 'react-awesome-reveal'
import { PageProps } from 'pages/Onboarding'
import { Steps } from 'utils/formConfig'
import StyledButton from 'core/components/StyledButton'
import analytics from 'utils/analytics'
import TriColoredText from 'core/components/TriColoredText'
import PhoneIconWhatsAppEn from 'assets/images/icon_intro_phone_whatsapp_en.png'
import PhoneIconAlertEn from 'assets/images/icon_intro_phone_alert_en.png'
import PhoneIconMapEn from 'assets/images/icon_intro_phone_map_en.png'
import PhoneIconWhatsAppAr from 'assets/images/icon_intro_phone_whatsapp_ar.png'
import PhoneIconAlertAr from 'assets/images/icon_intro_phone_alert_ar.png'
import PhoneIconMapAr from 'assets/images/icon_intro_phone_map_ar.png'
import PhoneIconMapEs from 'assets/images/icon_intro_phone_map_es.png'
import PhoneIconMapHe from 'assets/images/icon_intro_phone_map_he.png'
import PhoneIconWhatsAppHe from 'assets/images/icon_intro_phone_whatsapp_he.png'
import PhoneIconAlertHe from 'assets/images/icon_intro_phone_alert_he.png'
import PhoneIconWhatsAppEs from 'assets/images/icon_intro_phone_whatsapp_es.png'
import PhoneIconAlertEs from 'assets/images/icon_intro_phone_alert_es.png'
import IntroScreenMobileImageScroll from './IntroScreenMobileImageScroll'
import { useEffect } from 'react'

const PhoneImage = styled.img`
  pointer-events: none;
  position: absolute;
  align-self: flex-start;
  left: 390px;
  top: 620px;
  box-shadow: 0px 0px 55px #010a0c;
  border-radius: 50px;

  body[dir='rtl'] & {
    left: unset;
    right: 390px;
  }

  @media (max-height: 899px) {
    height: 500px;
    border-radius: 40px;
    left: 300px;
    top: 400px;

    body[dir='rtl'] & {
      left: unset;
      right: 300px;
    }
  }
`

const PhoneImageWhatsApp = styled(PhoneImage)`
  top: 250px;
  left: 0px;

  body[dir='rtl'] & {
    right: 0;
    left: unset;
  }

  @media (max-height: 899px) {
    top: 200px;
    left: unset;
  }
`

const PhoneImageMap = styled(PhoneImage)`
  left: 390px;
  top: -130px;

  body[dir='rtl'] & {
    left: unset;
    right: 390px;
  }

  @media (max-height: ${({ theme }) => theme.media.width.sm}) {
    left: 300px;
    top: -130px;

    body[dir='rtl'] & {
      left: unset;
      right: 300px;
    }
  }
`

const PageContentContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.default};
  flex: 1;
  overflow: visible;
  padding: 0px 120px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 0px 25px;
    overflow: visible;
  }
`

const IntroScreenContainer = styled.div`
  display: flex;
  width: 100%;
`

const BodyImageContainer = styled.div`
  transform: translate(0px, 0px);
  display: flex; // Not using position absolute because it would break the animation this page uses
  height: 100vh; // As noted above , The animation uses transformations , which break absolute / fixed positions
  flex: 1;
  top: 0;
  right: 60vw;
  justify-content: center;

  @media (max-width: 1700px) {
    justify-content: unset;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: none;
    height: unset;
    margin-top: 0px;
    align-self: center;
  }
`

const BodyImageContainerMobile = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: flex;
  }
`

const BodyFullImageContainer = styled.div`
  display: flex;
  height: 100vh;
  top: -20vh; // the size of the header
  width: calc(100vw - 100px - 45vw);
  margin-right: -20vw;
  position: absolute;

  body[dir='rtl'] & {
    margin-right: 0vw;
    margin-left: -20vw;
  }

  &:after {
    content: '';
    display: table;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${({ theme }) =>
      theme.colors.sections.intro.introScreenGradient};
  }
`
const PageTextParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  max-width: 750px;
  width: 55vw;
  flex: 4;
  align-items: center;
  align-self: flex-start;
  min-height: 60vh;
  max-height: 60vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    max-height: unset;
    min-height: unset;
    align-self: flex-start;
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
`

const StyledButtonContainerDesktop = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-self: flex-start;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 100%;
    bottom: 0;
    justify-content: center;
    align-items: center;
  }
`

const MenuButton = styled(StyledButton)`
  body[dir='rtl'] & {
    > span {
      font-size: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {    
    body[dir='rtl'] & {
      > span {
        font-size: 14px;
      }
    }
  }
`
const PaddedText = styled.div`
  padding-right: 100px;
  display: flex;
  margin-bottom: 50px;
  height: 100%;

  body[dir='rtl'] & {
    padding-right: 0px;
    padding-left: 100px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 0;
    margin-bottom: 20px;

    body[dir='rtl'] & {
      padding: 0;
      margin-bottom: 20px;
    }
  }
`

const BulletsListContainer = styled(PaddedText)`
  text-align: start;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 0;

    body[dir='rtl'] & {
      padding: 0;
      margin-bottom: 20px;
    }
  }
`

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 100%;
  }
`

const StyledListItem = styled.li`
  font-size: 24px;
  line-height: 32px;
  font-family: Metropolis;
  color: ${({ theme }) => theme.colors.palette.primary};
  font-style: normal;
  font-weight: normal;

  &::before {
    content: '✓';
    color: ${({ theme }) => theme.colors.palette.secondary};
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 18px;
  }
`

const getImagesByLanguage = (lng: string) => {
  switch (lng) {
    case 'he':
      return {
        whatsapp: PhoneIconWhatsAppHe,
        alert: PhoneIconAlertHe,
        map: PhoneIconMapHe
      }
    case 'en':
      return {
        whatsapp: PhoneIconWhatsAppEn,
        alert: PhoneIconAlertEn,
        map: PhoneIconMapEn
      }
    case 'es':
      return {
        whatsapp: PhoneIconWhatsAppEs,
        alert: PhoneIconAlertEs,
        map: PhoneIconMapEs
      }
    case 'ar':
      return {
        whatsapp: PhoneIconWhatsAppAr,
        alert: PhoneIconAlertAr,
        map: PhoneIconMapAr
      }
    default:
      return {
        whatsapp: PhoneIconWhatsAppEn,
        alert: PhoneIconAlertEn,
        map: PhoneIconMapEn
      }
  }
}

const IntroScreen: FC<PageProps> = ({ handleClickNextPage }) => {
  const { t, i18n } = useTranslation('introScreen')
  const [shouldDisplayImages, setShouldDisplayImages] = useState(false)

  const images = useMemo(
    () => getImagesByLanguage(i18n.language),
    [i18n.language]
  )
  const scrollableImages = useMemo(
    () => [images.alert, images.whatsapp, images.map],
    [images]
  )
  const handleClickFooterButton = useCallback(() => {
    analytics.sendEvent(analytics.events.onboardingStartClick)
    handleClickNextPage(Steps.Form)
  }, [handleClickNextPage])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldDisplayImages(true)
    }, 600) // Work-around to page scroll causing fade to not work
    return () => clearTimeout(timer)
  }, [])

  return (
    <PageContentContainer id="intro-screen">
      <IntroScreenContainer>
        <PageTextParentContainer>
          <PaddedText>
            <TriColoredText
              isFormText
              responsiveHeight
              text1={t('intro_text_1')}
              text2={t('intro_text_2')}
              text3={t('intro_text_3')}
              textAlign={'start'}
              subText={t('intro_text_subtext')}
              subtextCustomSizes={{ lg: '24px', md: '20px', sm: '17px' }}
              textCustomSizes={{ lg: '70px', md: '50px', sm: '44px' }}
            />
          </PaddedText>
          <BulletsListContainer>
            <StyledList>
              <StyledListItem>
                {` ${t('agronomical_guidance')}`}
              </StyledListItem>
              <StyledListItem>{` ${t('stress_alerts')}`}</StyledListItem>
              <StyledListItem>{` ${t('plot_analysis')}`}</StyledListItem>
              <StyledListItem>
                {` ${t('personal_weather_forecast')}`}
              </StyledListItem>
            </StyledList>
          </BulletsListContainer>
          <StyledButtonContainerDesktop>
            <MenuButton
              hasInternalPadding
              onClick={handleClickFooterButton}
              text={t('intro_start_button_text')}
            />
          </StyledButtonContainerDesktop>
          <BodyImageContainerMobile>
            <IntroScreenMobileImageScroll images={scrollableImages} />
          </BodyImageContainerMobile>
        </PageTextParentContainer>
        <BodyImageContainer>
          {!!shouldDisplayImages && (
            <BodyFullImageContainer>
              <Fade delay={1000}>
                <PhoneImage src={images.alert} />
              </Fade>
              <Fade delay={0}>
                <PhoneImageMap src={images.map} />
              </Fade>
              <Fade delay={500}>
                <PhoneImageWhatsApp src={images.whatsapp} />
              </Fade>
            </BodyFullImageContainer>
          )}
        </BodyImageContainer>
      </IntroScreenContainer>
    </PageContentContainer>
  )
}

export default IntroScreen
