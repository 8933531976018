import React, { SVGProps } from 'react'

const IconMandarin = ({ fill }: SVGProps<unknown>) => (
  <svg
    width="39"
    height="48"
    viewBox="0 0 39 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_1" clip-path="url(#clip0_371_4433)">
      <path
        id="Vector"
        d="M37.8025 28.6264C37.8025 38.8105 29.5571 47.0559 19.3729 47.0559C9.18884 47.0559 0.943359 38.8105 0.943359 28.6264C0.943359 18.4423 9.18884 10.1968 19.3729 10.1968C29.5571 10.1968 37.8025 18.4423 37.8025 28.6264Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_2"
        d="M19.373 10.1968C29.5572 10.1968 37.8026 18.4423 37.8026 28.6264"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_3"
        d="M33.0192 28.6263C33.0192 24.4343 31.0931 20.4815 27.7949 17.9009"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_4"
        d="M19.9648 10.1842V10.0709C19.9648 1.54849 23.9302 1.22119 28.8146 1.22119H28.9279V1.33449C28.9279 9.46667 24.9625 10.1842 20.0781 10.1842H19.9648Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_5"
        d="M19.9647 10.1841V10.096C19.9647 3.51216 16.9057 3.2478 13.1291 3.2478H13.041V3.33592C13.041 9.63018 16.1 10.1715 19.8766 10.1715H19.9647V10.1841Z"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_6"
        d="M19.9648 10.1967V0"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_7"
        d="M9.89453 38.2816V36.708"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_8"
        d="M13.041 36.7208V35.1472"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_9"
        d="M9.89453 35.1471V33.5735"
        stroke="#052B33"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_371_4433">
        <rect width="38.7474" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconMandarin
