import React, {
  createContext,
  useState,
  FC,
  useEffect,
  useCallback
} from 'react'
import { SmsOTCUser, UserSubscription, OnboardingMode } from 'utils/types'

const UserContext = createContext<{
  isAuthenticated: boolean
  userSettings: SmsOTCUser | null
  authToken: string
  userSubscription: UserSubscription | null
  onboardingMode: OnboardingMode
  userLogin: (user: SmsOTCUser, token: string) => void
  userLogout: () => void
  updateUserSubscription: (subscription: UserSubscription) => void
  updateOnboardingMode: (mode: OnboardingMode) => void
}>({
  isAuthenticated: false,
  userSettings: null,
  authToken: '',
  userSubscription: null,
  onboardingMode: OnboardingMode.New,
  userLogin: (user, token) => {},
  userLogout: () => {},
  updateUserSubscription: (subscription) => {},
  updateOnboardingMode: (mode) => {}
})

interface UserContextProviderProps {
  children: JSX.Element
}

const UserContextProvider: FC<UserContextProviderProps> = ({ children }) => {
  const [userSettings, setUserSettings] = useState<SmsOTCUser | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authToken, setAuthToken] = useState<string>('')
  const [userSubscription, setUserSubscription] =
    useState<UserSubscription | null>(null)
  const [onboardingMode, setOnboardingMode] = useState<OnboardingMode>(
    OnboardingMode.New
  )

  useEffect(() => {
    setIsAuthenticated(Boolean(userSettings))
  }, [userSettings])

  const userLogin = useCallback((user: SmsOTCUser, token: string) => {
    setAuthToken(token)
    setUserSettings(user)
  }, [])

  const userLogout = useCallback(() => {
    setUserSettings(null)
  }, [])

  const updateUserSubscription = useCallback(
    (subscription: UserSubscription) => {
      setUserSubscription(subscription)
    },
    []
  )
  const updateOnboardingMode = useCallback((mode: OnboardingMode) => {
    setOnboardingMode(mode)
  }, [])

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider
      value={{
        isAuthenticated,
        userSettings,
        authToken,
        userSubscription,
        onboardingMode,
        userLogin,
        userLogout,
        updateUserSubscription,
        updateOnboardingMode
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserContextProvider }
