import React, { FC, memo } from 'react'
import styled from '@emotion/styled/macro'

const DEFAULT_LEGEND_ARRAY = [
  '#0B782B',
  '#7AD894',
  '#DFFFE7',
  '#FDED77',
  '#FFB966',
  '#F66A4B'
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  width: 135px;
  height: 180px;
  font-family: Metropolis;
`

const LegendColor = styled.div<{
  $color: string
  $isFirst: boolean
  $isLast: boolean
}>`
  display: flex;
  flex: 1;
  width: 14px;
  background-color: ${({ $color }): string => $color};
  border-top-left-radius: ${({ $isFirst }): string => ($isFirst ? '20px' : '')};
  border-top-right-radius: ${({ $isFirst }): string =>
    $isFirst ? '20px' : ''};
  border-bottom-left-radius: ${({ $isLast }): string =>
    $isLast ? '20px' : ''};
  border-bottom-right-radius: ${({ $isLast }): string =>
    $isLast ? '20px' : ''};
`

const LegendColorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 8px;
  margin-top: -42px;
  margin-bottom: -35px;
`

const LegendTitle = styled.h4`
  margin-top: 0;
  padding-top: 12px;
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
`

const LegendText = styled.span<{
  $isFirst: boolean
}>`
  font-size: 14px;
  text-align: left;
  font-weight: normal;
  margin: ${({ $isFirst }) => ($isFirst ? '-10px 40px' : '0 40px')};
  color: rgb(5, 43, 51);
`

interface RulerMapLegendProps {
  legendColorArray?: string[]
  className?: string
  highText?: string
  lowText?: string
  title?: string
}

const RulerMapLegend: FC<RulerMapLegendProps> = ({
  legendColorArray,
  className,
  highText,
  lowText,
  title
}) => {
  const legendArray = legendColorArray || DEFAULT_LEGEND_ARRAY

  return (
    <Container className={className}>
      <LegendTitle>{title}</LegendTitle>
      <LegendText $isFirst>{highText || ''}</LegendText>
      <LegendColorContainer>
        {legendArray.map((color, index) => (
          <LegendColor
            key={`legend-color-${color}`}
            $color={color}
            $isFirst={index === 0}
            $isLast={index === legendArray.length - 1}
          />
        ))}
      </LegendColorContainer>
      <LegendText $isFirst={false}>{lowText || ''}</LegendText>
    </Container>
  )
}

export default memo(RulerMapLegend)
