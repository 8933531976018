import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import styled from '@emotion/styled/macro'
import StyledButton from 'core/components/StyledButton'
import { useNavigate } from 'react-router-dom'
import { BrowserRoutes } from 'core/Routes'
import { UserContext } from 'core/UserContext'
import HeaderLogo from 'core/components/HeaderLogo'
import { Grid } from '@mui/material'
import TriColoredText, {
  RotatingTextSpanSubtext
} from 'core/components/TriColoredText'
import { useTranslation } from 'react-i18next'
import eventEmitter, { events } from 'utils/eventEmitter'
import { SmsOTCUser } from 'utils/types'
import SmsDialog from 'pages/Onboarding/formScreen/SmsDialog'
import api from 'Api/ApiMethods'
import { toast } from 'react-toastify'
import PhoneLogin from './PhoneLogin'
import analytics from 'utils/analytics'
import { PaymentMethods } from 'utils/formConfig'

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
`

const GridContainer = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 0px 100px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 0px 25px;
    overflow: visible;
  }
`

const StyledGrid = styled(Grid)`
  /* @noflip */
  flex-direction: row-reverse;

  body[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`

const GridBody = styled(Grid)`
  min-height: 60vh;
  max-height: 60vh;
  overflow: hidden;
  padding: 5px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-bottom: 100px;
    max-height: unset;
    min-height: 25vh;
    overflow: visible;
  }
`

const GridText = styled(Grid)`
  min-height: 60vh;
  max-height: 60vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    max-height: unset;
    min-height: unset;
  }
`

const GridContentContainer = styled.div`
  display: flex;
  overflow-wrap: anywhere;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const GridFormContentContainer = styled(GridContentContainer)`
  align-items: flex-start;
  padding-top: 10vh;
`

const GridTextContentContainer = styled(GridContentContainer)`
  align-items: flex-start;
  padding-top: 10vh;
`

const PaddedText = styled.div`
  padding-right: 100px;
  display: flex;
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  body[dir='rtl'] & {
    padding-right: 0px;
    padding-left: 100px;

    @media (max-width: ${({ theme }) => theme.media.width.sm}) {
      overflow: inherit;
      padding-left: unset;
      padding: 0px 8%;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    justify-content: center;
    overflow: inherit;
    padding-left: unset;
    padding: 0px 8%;
  }
`

const GridFooter = styled(Grid)`
  height: 20vh;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 0;
  }
`

const SubText = styled(RotatingTextSpanSubtext)`
  font-size: 24px;

  @media (max-width: ${({ theme }) => theme.media.width.md}) {
    font-size: 18px;
  }
`

const SecondSubText = styled(SubText)`
  line-height: 0;

  @media (max-width: ${({ theme }) => theme.media.width.md}) {
    line-height: 0;
  }
`

const LoginButton = styled(StyledButton)`
  width: 331px;
  height: 70px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 230px;
    height: 42px;
  }
`

const Login: FC = () => {
  const { t, i18n } = useTranslation('login')
  const lang = useMemo(() => i18n.language, [i18n.language])
  const userContext = useContext(UserContext)
  const navigate = useNavigate()
  const [phone, setPhone] = useState('')
  const [smsReqId, setSmsReqId] = useState('')

  const requestSmsCode = useCallback(
    async (phoneNumber: string) => {
      try {
        const res = await api.getAccessCode(phoneNumber, lang)
        setSmsReqId(res?.data || '')
      } catch (error) {
        toast.error(t<string>('formScreen:general_server_error'))
      }
    },
    [lang, t]
  )

  const handleClickResend = useCallback(async () => {
    const res = await api.getAccessCode(phone, lang)
    setSmsReqId(res?.data?.request_id || '')
  }, [lang, phone])

  const handleSmsSuccess = async (
    authToken: string,
    userObject: SmsOTCUser
  ) => {
    setSmsReqId('') // close the sms popup, since its finished
    eventEmitter.emit(events.ENABLE_LOADING)
    userContext.userLogin(userObject, authToken)
    navigate(BrowserRoutes.UserSettings)
    eventEmitter.emit(events.DISABLE_LOADING)
  }

  const handleSmsCloseDialog = useCallback(async () => {
    setSmsReqId('') // close the sms popup, since its finished
  }, [])

  const loginOnClickHandle = useCallback(() => {
    requestSmsCode(phone)
    analytics.sendEvent(analytics.events.userSettingsLoginClick)
  }, [phone, requestSmsCode])

  return (
    <PageContainer>
      <HeaderLogo
        onePagerOpen={false}
        isLogin
        contactUsMessage={t('userSettings:contact_us_user_settings_help')}
      />
      <GridContainer>
        <StyledGrid container style={{ position: 'relative' }}>
          <GridText item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <GridTextContentContainer>
              <PaddedText>
                <TriColoredText
                  isFormText
                  text1={t('upgrade')}
                  breakLine1
                  text2={t('your_plant')}
                  subText={
                    <>
                      <SubText>{t('login_sub_text_1')}</SubText>
                      <SecondSubText>{t('login_sub_text_2')}</SecondSubText>
                    </>
                  }
                  textAlign={'start'}
                  isSmaller
                />
              </PaddedText>
            </GridTextContentContainer>
          </GridText>
          <GridBody item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <GridFormContentContainer>
              <PhoneLogin setPhone={setPhone} />
            </GridFormContentContainer>
          </GridBody>
          <GridFooter item xs={12} md={6} order={{ xs: 3, md: 3 }}>
            <GridContentContainer>
              {!!Number(phone) && (
                <LoginButton
                  text={t('send_code')}
                  onClick={loginOnClickHandle}
                />
              )}
            </GridContentContainer>
          </GridFooter>
        </StyledGrid>
      </GridContainer>
      <SmsDialog
        couponCode={null}
        paymentMethod={PaymentMethods.ECommerce}
        smsReqId={smsReqId}
        isOpen={!!smsReqId}
        lang={lang}
        userPhoneNumber={phone}
        onSuccess={handleSmsSuccess}
        onClickResend={handleClickResend}
        onClose={handleSmsCloseDialog}
      />
    </PageContainer>
  )
}

export default Login
