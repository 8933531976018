const basePalette = {
  primary: '#e8fbfa',
  secondary: '#dcf900',
  white: '#ffffff',
  red: '#c13636',
  darkGray: '#9b9b9b'
}

const palette = {
  ...basePalette,
  tealDark: '#052b33',
  powderBlue: '#b7d2cd',
  slateGray: '#617b7f',
  steelBlue: '#323b41',
  paleBlue: '#f0fcfb',
  limeGreen: '#dcf904',
  midnightBlue: '#112a32',
  darkGray: '#9b9b9b'
}

const defaultColorSchema = {
  palette: basePalette,
  background: {
    default: palette.tealDark,
    transparent: 'rgba(5, 43, 51, 0.8)'
  },
  text: {
    description: palette.powderBlue
  },
  icons: {
    whatsAppIcon: 'rgba(18, 42, 49, 1)'
  },
  buttons: {
    text: palette.tealDark,
    background: palette.secondary,
    backgroundHover: '#cae400',
    backgroundActive: '#aac100',
    backgroundDisabled: '#435c61',
    altText: palette.powderBlue,
    altBorder: palette.powderBlue
  },
  inputs: {
    text: palette.primary,
    textFocused: palette.secondary,
    label: palette.primary,
    labelFocused: palette.secondary,
    border: palette.powderBlue,
    borderFocused: palette.secondary,
    borderError: palette.red
  },
  switch: {
    track: `${palette.secondary}99`,
    trackChecked: palette.secondary,
    thumb: palette.tealDark,
    thumbChecked: palette.tealDark,
    thumbFocused: palette.tealDark,
    text: palette.primary,
    activeText: palette.secondary
  },
  stepper: {
    line: palette.slateGray,
    lineSelected: palette.secondary,
    circle: palette.secondary,
    circleSelected: palette.tealDark,
    circleBorder: palette.slateGray,
    circleBorderSelected: palette.secondary
  },
  imageCarousel: {
    boxShadow: '0px 0px 18px #010a0c'
  },
  datePicker: {
    maskGradient: `linear-gradient(
        to bottom,
        rgba(5, 43, 51, 0.95),
        rgba(5, 43, 51, 0.6)
      ),
      linear-gradient(to top, rgba(5, 43, 51, 0.95), rgba(5, 43, 51, 0.6));`,
    label: palette.slateGray,
    text: palette.darkGray
  },
  slider: {
    line: palette.secondary,
    markBorder: palette.tealDark,
    text: palette.powderBlue,
    textSelected: palette.secondary,
    actionShadowColor: 'rgba(220, 249, 0, 0.3)'
  },
  checkbox: {
    checkboxIcon: palette.secondary,
    checkboxTitle: palette.primary,
    checkboxDescription: palette.slateGray,
    checkboxDescriptionSmall: palette.powderBlue
  },
  sections: {
    intro: {
      introScreenGradient: `linear-gradient(
        0deg,
        rgb(5 43 51 / 100%),
        rgba(255, 0, 0, 0) 10%
      ),
      linear-gradient(360deg, rgb(0 0 0 / 0%), rgba(0, 255, 0, 0) 80%),
      linear-gradient(360deg, rgb(0 0 0 / 0%), rgba(0, 255, 0, 0) 80%),
      linear-gradient(180deg, rgb(5 43 51 / 100%), rgba(0, 0, 255, 0) 10%);`
    },
    crops: {
      icon: palette.midnightBlue,
      iconSelected: palette.midnightBlue,
      iconLabel: palette.tealDark,
      iconLabelSelected: palette.tealDark,
      background: palette.white,
      backgroundSelected: palette.secondary,
      border: palette.white,
      borderSelected: palette.secondary
    },
    otherCrop: {
      background: '#E8FBFA',
      text: '#052B33'
    },
    units: {
      unitsTitle: palette.secondary,
      unitsSizeTitle: palette.paleBlue,
      nominalFlowTitle: palette.secondary,
      nominalSizeTitle: palette.secondary,
      unitsInputLabel: palette.paleBlue,
      unitsInputText: palette.steelBlue,
      unitsInputBorder: palette.steelBlue
    },
    plotLocation: {
      description: palette.powderBlue
    },
    plotName: {
      text: palette.powderBlue,
      linkHover: palette.secondary,
      checkbox: palette.powderBlue,
      checkboxDark: palette.tealDark
    },
    paymentOptions: {
      couponText: palette.powderBlue,
      couponLink: palette.powderBlue,
      couponLinkHover: palette.limeGreen
    }
  }
}

export default defaultColorSchema
