import { useState, useCallback, useEffect } from 'react'

const useInputFocusScroll = (inputRef: any, interval: number = 1000) => {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>(
    undefined
  )
  const handleInputFocus = useCallback(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }, interval)
    setTimeoutRef(timeout)
  }, [inputRef, interval])

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return handleInputFocus
}

export default useInputFocusScroll
