import React, { FC, useMemo, useState, useCallback } from 'react'
import styled from '@emotion/styled/macro'
import Picker from 'rmc-picker/lib/Picker'
import { FormProps } from './FormScreen'
import { range } from 'utils/arrayHelpers'
import { useEffect } from 'react'
import { getDaysInMonth } from 'date-fns'
import { useTranslation } from 'react-i18next'

interface MonthList {
  index: number
  name: string
}

const StyledPicker = styled(Picker) <{ $fontFamily?: string }>`
  .rmc-picker-mask {
    background-image: ${({ theme }) => theme.colors.datePicker.maskGradient};
  }

  .rmc-picker-indicator {
    width: 80px;
    left: calc(50% - 40px);
  }

  .rmc-picker-item {
    color: ${({ theme }) => theme.colors.datePicker.text};
    font-size: 20px;
    transition-duration: 50ms;
    font-family: ${({ $fontFamily }) => ($fontFamily ? $fontFamily : '')};
  }

  .rmc-picker-item-selected {
    color: ${({ theme }) => theme.colors.palette.secondary};
    font-size: 26px;
    font-family: ${({ $fontFamily }) => ($fontFamily ? $fontFamily : '')};
  }
`

const CropBlossomFormContent = styled.div`
  display: block;
  width: 100%;
`

const BlossomText = styled.div`
  text-align: center;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.datePicker.label};
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 14px;
    margin-bottom: 25px;
  }
`

const ColumnSelect = styled.div`
  flex-grow: 1;
`

const BlossomSelectWrap = styled.div``

const BlossomSelectColumnContent = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 10px;
`

const getYearsArray = (minYear: number | undefined) => {
  if (!minYear) {
    return range(1900, new Date().getFullYear())
  }

  return range(minYear, new Date().getFullYear())
}

const getDayItems = (total: number) => {
  const items: JSX.Element[] = []
  for (let i = 1; i <= total; i++) {
    items.push(
      <Picker.Item value={i} key={i}>
        {i}
      </Picker.Item>
    )
  }

  return items
}

const getMonthItems = (months: MonthList[]) => {
  const items: JSX.Element[] = []
  months.forEach((month) => {
    items.push(
      <Picker.Item value={month.index} key={month.index}>
        {month.name}
      </Picker.Item>
    )
  })

  return items
}

const getYears = (minYear: number | undefined) => {
  const items: JSX.Element[] = []
  getYearsArray(minYear).forEach((year) => {
    items.push(
      <Picker.Item value={year} key={year}>
        {year}
      </Picker.Item>
    )
  })

  return items
}

const CropBlossomForm: FC<FormProps> = ({ formData, handleChangeFormData }) => {
  const { t } = useTranslation('plot')
  const formDate = formData.cropBlossomForm.initialStageDate
  const minimumYear = useMemo(() => new Date().getFullYear() - 1, [])
  const [yearValue, setYearValue] = useState<number>(
    formDate ? new Date(formDate).getFullYear() : new Date().getFullYear()
  )
  const [monthValue, setMonthValue] = useState<number>(
    formDate ? new Date(formDate).getMonth() : 0
  )
  const [dayValue, setDayValue] = useState<number>(
    formDate ? new Date(formDate).getDate() : 1
  )
  const years = useMemo(() => getYears(minimumYear), [minimumYear])
  const maxDay = useMemo(() => {
    if (
      yearValue !== new Date().getFullYear() ||
      monthValue !== new Date().getMonth()
    ) {
      return getDaysInMonth(new Date(yearValue, monthValue))
    }

    return new Date().getDate()
  }, [monthValue, yearValue])
  const items = useMemo(() => getDayItems(maxDay), [maxDay])

  const maxMonth = useMemo(() => {
    if (yearValue !== new Date().getFullYear()) {
      return 11
    }

    return new Date().getMonth()
  }, [yearValue])

  const months = useMemo(() => {
    let monthArray: MonthList[] = []
    for (let i = 0; i <= maxMonth; i++) {
      monthArray.push({ index: i, name: t(`datePicker:${i}`) })
    }
    return monthArray
  }, [t, maxMonth])
  const monthItems = useMemo(() => getMonthItems(months), [months])

  const onChangeYear = useCallback((value: number) => {
    setYearValue(value)
  }, [])

  const onChangeMonth = useCallback((value: number) => {
    setMonthValue(value)
  }, [])

  const onChangeDay = useCallback((value: number) => {
    setDayValue(value)
  }, [])

  useEffect(() => {
    handleChangeFormData({
      ...formData,
      cropBlossomForm: {
        ...formData.cropBlossomForm,
        initialStageDate: new Date(yearValue, monthValue, dayValue),
        completed: true
      }
    })
    // formData not included to prevent loop , this is required to enable the button by default for this page (take current date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearValue, monthValue, dayValue])

  return (
    <CropBlossomFormContent id='crop-blossom-form'>
      <BlossomSelectWrap>
        <BlossomSelectColumnContent>
          <ColumnSelect>
            <BlossomText>{t('day')}</BlossomText>
            {/* @ts-ignore */}
            <StyledPicker
              selectedValue={dayValue}
              onValueChange={onChangeDay}
              $fontFamily={'Metropolis'}
            // onScrollChange={this.onScrollChange}
            >
              {items}
            </StyledPicker>
          </ColumnSelect>
          <ColumnSelect>
            <BlossomText>{t('month')}</BlossomText>
            {/* @ts-ignore */}
            <StyledPicker
              selectedValue={monthValue}
              onValueChange={onChangeMonth}
            // onScrollChange={this.onScrollChange}
            >
              {monthItems}
            </StyledPicker>
          </ColumnSelect>
          <ColumnSelect>
            <BlossomText>{t('year')}</BlossomText>
            {/* @ts-ignore */}
            <StyledPicker
              selectedValue={yearValue}
              onValueChange={onChangeYear}
              $fontFamily={'Metropolis'}
            // onScrollChange={this.onScrollChange}
            >
              {years}
            </StyledPicker>
          </ColumnSelect>
        </BlossomSelectColumnContent>
      </BlossomSelectWrap>
    </CropBlossomFormContent>
  )
}

export default CropBlossomForm
