import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { DialogContent } from '@mui/material'
import WhatsAppIcon from 'assets/images/icon_whatsapp.svg'
import Dialog from 'core/components/Dialog'
import { Grid } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import analytics from 'utils/analytics'

const PHONE_URL = process.env.REACT_APP_WHATSAPP_PHONE_URL

const DialogBodyContent = styled(DialogContent)`
  background-color: #dcf900;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
`

const CloseButton = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -20px;
  margin-left: 45px;
`

const Text = styled.div`
  font-size: 24px;
  margin: 10px 0 20px;
  line-height: 0.93;
  white-space: break-spaces;
  color: #112a32;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.04;
  letter-spacing: -0.72px;
  margin-top: -10px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin: 6px 23px 5.2px 25px;
  }
`

const WhatsAppContactContainer = styled.a`
  cursor: pointer;
  width: 95px;
  height: 95px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  z-index: 100;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 50%;
  background-color: rgba(18, 42, 49, 1);
  align-items: center;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    padding: 10px;
    width: 88.7px;
    height: 88.7px;
    z-index: 101;
  }
`

const Logo = styled.img`
  width: 52.7px;
  height: 52.7px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 47.7px;
    height: 47.7px;
  }
`

const Space = styled.div`
  height: 1rem;
`

interface CallSupportDialogProps {
  dialogVisible: boolean
  contactUsMessage: string
  onClose: () => void
}

const CallSupportDialog: FC<CallSupportDialogProps> = ({
  dialogVisible,
  contactUsMessage,
  onClose
}) => {
  const { t } = useTranslation('userSettings')

  useEffect(() => {
    analytics.sendEvent(analytics.events.userSettingsCallSupportDialogOpen)
  }, [])

  return (
    <Dialog onClose={onClose} open={dialogVisible} isCallSupport={true}>
      <DialogBodyContent>
        <CloseButton>
          <div />
          <IconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </CloseButton>
        <Grid>
          <Text>
            <WhatsAppContactContainer
              target="_blank"
              href={PHONE_URL + '?text=' + contactUsMessage}
              onClick={() => {
                analytics.sendEvent(
                  analytics.events.userSettingsCallSupportContactUsClick
                )
              }}
            >
              <Logo src={WhatsAppIcon} />
            </WhatsAppContactContainer>
          </Text>
          <Space />
          <Text>{t('contact_support')}</Text>
        </Grid>
      </DialogBodyContent>
    </Dialog>
  )
}

export default CallSupportDialog
