import React, { FC } from 'react'
import { Subdomain, getCompanyLogoFromSubdomain, getSubdomain } from 'utils/formConfig'
import DefaultHeader from './headers/DefaultHeader'
import PurpleGreenHeader from './headers/PurpleGreenHeader'

export interface HeaderLogoProps {
  companyLogo?: string
  onePagerOpen?: boolean
  isLogin?: boolean
  userSettingsHeaderText?: string
  userLogoutText?: string
  contactUsMessage: string
  handleClickLogo?: () => void
  handleClickOnePager?: () => void
  logoutClick?: () => void
}

const headers = {
  [Subdomain.App]: DefaultHeader,
  [Subdomain.Ahern]: PurpleGreenHeader,
  [Subdomain.Carrefour]: DefaultHeader,
  [Subdomain.Mexico]: DefaultHeader
}

const subdomain = getSubdomain()
const companyLogo = getCompanyLogoFromSubdomain()
const Component = headers[subdomain] ?? DefaultHeader

const HeaderLogo: FC<HeaderLogoProps> = (props) => (
  <Component companyLogo={companyLogo} {...props} />
)

export default HeaderLogo
