import React, { FC, useState, useCallback, ChangeEvent, useRef } from 'react'
import styled from '@emotion/styled/macro'
import { FormProps } from './FormScreen'
import RtlInput from 'core/components/RtlInput'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useInputFocusScroll from 'hooks/useInputFocusScroll'

const MAX_PLOT_NAME_LENGTH = 20
const MIN_PLOT_NAME_LENGTH = 1

const NameContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    align-items: unset;
  }
`

const NameContentContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 30px;
  flex-direction: column;
`

const plotNameSchema = () => {
  return Yup.object().shape({
    plotName: Yup.string()
      .min(MIN_PLOT_NAME_LENGTH)
      .max(MAX_PLOT_NAME_LENGTH)
      .required('Required')
  })
}

const PlotNameForm: FC<FormProps> = ({ formData, handleChangeFormData }) => {
  const { i18n, t } = useTranslation('plot')
  const [value, setValue] = useState<string>(
    /*formData?.plotNameForm.plotName ||*/ ''
  )
  const nameRef = useRef<HTMLInputElement>(null)
  const handleFocusName = useInputFocusScroll(nameRef)

  const formik = useFormik({
    initialValues: {
      plotName: value
    },
    validationSchema: plotNameSchema,
    onSubmit: () => {}
  })

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const plotName = event?.target?.value
      handleChangeFormData({
        ...formData,
        // plotNameForm: {
        //   ...formData.plotNameForm,
        //   plotName: event.target.value,
        //   completed: !!(plotName && plotName.length <= MAX_PLOT_NAME_LENGTH)
        // }
      })
      setValue(event.target.value)
      formik.setFieldValue('plotName', event.target.value)
    },
    [formData, handleChangeFormData, formik]
  )

  return (
    <NameContainer id="plot-name-form">
      <NameContentContainer>
        <RtlInput
          autoFocus
          onFocus={handleFocusName}
          inputRef={nameRef}
          error={!!formik.errors.plotName}
          isRtl={i18n.dir() === 'rtl'}
          value={value}
          onChange={onChange}
          id="standard-basic"
          label={t('plot_name')}
          variant="standard"
        />
      </NameContentContainer>
    </NameContainer>
  )
}

export default PlotNameForm
