import { Subdomain, getSubdomain } from 'utils/formConfig'
import purpleGreenColorSchema from './colorSchemas/purpleGreenColorSchema'
import defaultColorSchema from './colorSchemas/defaultColorSchema'

const colorSchemas = {
  [Subdomain.App]: defaultColorSchema,
  [Subdomain.Ahern]: purpleGreenColorSchema,
  [Subdomain.Carrefour]: defaultColorSchema,
  [Subdomain.Mexico]: defaultColorSchema
}

export const mediaQueries = {
  width: {
    sm: '899px',
    md: '1500px'
  }
}

const theme = {
  media: mediaQueries,
  unit: 'rem',
  fontFamily: 'Heebo',
  fontSize: {
    text: '1rem',
    sub: '0.8rem',
    heading1: 'calc(1.625rem + 1.6vw)',
    heading2: 'calc(1.375rem + 1.5vw)',
    heading3: 'calc(1.25rem + 1vw)',
    heading4: 'calc(1.135rem + .6vw)',
    heading5: 'calc(1.125rem + .25vw)',
    heading6: 'calc(1rem + .25vw)'
  },
  fonts: {
    heebo: 'Heebo, Source Sans Pro, Assistant, sans-serif',
    metropolis: 'Metropolis, Heebo, Source Sans Pro, Assistant, sans-serif',
    simplerPro: 'SimplerPro, Heebo, Source Sans Pro, Assistant, sans-serif'
  },
  css: {
    centered:
      'position: absolute; left: 0; right:0; margin-right: auto; margin-left: auto;',
    transition: 'all 300ms ease-in-out;'
  },
  direction: 'ltr'
}

const getAppTheme = () => {
  const subdomain = getSubdomain()
  const colorSchema = colorSchemas[subdomain] ?? defaultColorSchema
  return { ...theme, colors: colorSchema }
}

export default getAppTheme()
