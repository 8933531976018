import React, { FC, useMemo, memo } from 'react'
import styled from '@emotion/styled/macro'
import { Fade } from 'react-awesome-reveal'
import LogoIcon from 'assets/images/icon_one_pager_supplant_logo.svg'
import { useTranslation } from 'react-i18next'
import OnePagerPagination from './OnePagerPagination'
import { OnePagerPageProps } from './OnePagerFirstPage'
import analytics from 'utils/analytics'
import { useCallback } from 'react'

const GUIDE_LINK = process.env.REACT_APP_HOW_SUPPLANT_WORKS_LINK
const SUPPLANT_LINK = process.env.REACT_APP_SUPPLANT_SITE_LINK

const Logo = styled.img`
  width: 220px;
  height: 220px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 98px;
    height: 98px;
  }
`

const Page = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  background: #052b33;
  padding-top: 15vh;
  max-height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;

  body[dir='rtl'] & {
    flex-direction: row;
  }
`

const PageContent = styled.div`
  display: flex;
  align-self: center;
  margin: auto;
  margin-top: 10vh;
  flex-direction: row-reverse;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex-direction: column-reverse;
  }
`

const ImageContainer = styled.div`
  display: flex;
  margin: 0px 43px;
  align-self: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    align-self: flex-start;
    margin: 0px 43px;
  }
`

const PageTextParentContainer = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  max-width: 660px;
  align-self: flex-start;
  margin-top: 10vh;
  flex: 4;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 0px;
    padding: 0px 50px;
  }
`

const PageTextContainer = styled.div`
  display: block;
  margin-top: 30px;
`

const InfoText = styled.span`
  font-size: 41px;
  font-weight: 900;
  white-space: break-spaces;
  line-height: 1;
  letter-spacing: -1.23px;
  color: #e8fbfa;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 28px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.84px;
  }
`

const LinkText = styled.a`
  font-size: 41px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -1.23px;
  color: #dcf900;
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  text-underline-offset: 3px;

  :hover {
    color: #dcf904;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 28px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.84px;
  }
`

const OnePagerFourthPage: FC<OnePagerPageProps> = ({
  handleChangePageIndex,
  focusDisabled,
  isMobile
}) => {
  const { t, i18n } = useTranslation('onePager')

  const fadeDirection = useMemo(() => {
    if (isMobile) {
      return 'up'
    }

    return i18n.dir() === 'rtl' ? 'left' : 'right'
  }, [i18n, isMobile])

  const handleClickSensorsDescription = useCallback(() => {
    analytics.sendEvent(analytics.events.onePagerHowOurSystemWorksClick)
  }, [])

  const handleClickCompanyDescription = useCallback(() => {
    analytics.sendEvent(analytics.events.onePagerOurCompanyInfoClick)
  }, [])

  return (
    <Page>
      <Fade triggerOnce direction={fadeDirection}>
        <PageContent>
          <PageTextParentContainer>
            <PageTextContainer>
              <InfoText>{t('sensors_description')}</InfoText>
              <LinkText
                target="_blank"
                href={GUIDE_LINK}
                onClick={handleClickSensorsDescription}
                tabIndex={focusDisabled ? -1 : 0}
              >
                {t('click_here')}
              </LinkText>
            </PageTextContainer>
            <PageTextContainer>
              <InfoText>{t('supplant_description')}</InfoText>
              <LinkText
                target="_blank"
                href={SUPPLANT_LINK}
                onClick={handleClickCompanyDescription}
                tabIndex={focusDisabled ? -1 : 0}
              >
                {t('click_here')}
              </LinkText>
            </PageTextContainer>
            <OnePagerPagination
              index={3}
              length={4}
              onChange={handleChangePageIndex}
            />
          </PageTextParentContainer>
          <ImageContainer>
            <Logo src={LogoIcon} />
          </ImageContainer>
        </PageContent>
      </Fade>
    </Page>
  )
}

export default memo(OnePagerFourthPage)
