import React, { SVGProps } from 'react'

const IconLemon = ({ fill }: SVGProps<unknown>) => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Lemons">
      <path
        id="Vector"
        d="M14.7912 38.9889C12.668 38.9889 10.6116 38.6218 8.71076 37.8543C7.74367 37.4873 6.63207 37.5206 5.6761 37.9656C4.74236 38.366 2.47472 39.1446 1.16304 37.8321C0.251534 36.92 0.195949 35.2404 1.01853 33.3383C1.46316 32.3706 1.49652 31.2583 1.12969 30.2795C-1.68263 23.2942 0.862912 14.1287 7.488 7.49936C14.1131 0.869987 23.2726 -1.68833 30.2645 1.13694C31.2316 1.504 32.3432 1.47063 33.2881 1.03683C35.2111 0.2026 36.8896 0.258216 37.8011 1.17031C38.7126 2.08241 38.7682 3.762 37.9456 5.66405C37.501 6.63176 37.4676 7.74407 37.8345 8.7229C40.6468 15.7193 38.0901 24.8737 31.4762 31.503C26.6518 36.3305 20.4825 39 14.78 39L14.7912 38.9889ZM7.32127 35.8188C8.01046 35.8188 8.71074 35.9411 9.35547 36.1859C15.6137 38.7108 24.1952 36.2637 30.2201 30.2239C36.256 24.184 38.7015 15.6081 36.1893 9.35692C36.1893 9.35692 36.1893 9.34579 36.1893 9.33467C35.6446 7.91091 35.7002 6.29806 36.3338 4.90767C36.8674 3.67301 36.8563 2.68305 36.5561 2.39385C36.256 2.09353 35.2889 2.08241 34.0217 2.62744C32.6544 3.25033 31.0426 3.30595 29.6198 2.76091C23.3615 0.235969 14.78 2.68305 8.75521 8.7229C2.71927 14.7627 0.273774 23.3387 2.78597 29.5899C3.33065 31.0247 3.28618 32.6376 2.65257 34.028C2.119 35.2626 2.13012 36.2526 2.43025 36.5418C2.73038 36.8421 3.68635 36.8532 4.96468 36.3082C5.69833 35.9745 6.50981 35.7965 7.32127 35.7965V35.8188ZM9.82235 14.1955C9.6445 14.1955 9.45552 14.1399 9.2999 14.0286C8.89973 13.7394 8.81079 13.1833 9.0998 12.7828C9.74453 11.8819 10.4782 11.0365 11.2563 10.2468C13.9908 7.45487 17.459 5.56394 21.294 4.78532C21.772 4.68521 22.2388 4.99666 22.3389 5.48608C22.4389 5.96437 22.1277 6.43154 21.6386 6.53165C18.1482 7.2324 14.9913 8.95649 12.5124 11.5037C11.7899 12.2267 11.1229 13.0053 10.5226 13.8284C10.3448 14.0731 10.078 14.1955 9.80011 14.1955H9.82235Z"
        fill="#052B33"
      />
    </g>
  </svg>
)

export default IconLemon
