import React, { FC, useEffect, useContext, useCallback } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { PageProps } from 'pages/Onboarding'
import TriColoredText from 'core/components/TriColoredText'
import { BrowserRoutes } from 'core/Routes'
import verySessionStorage from 'utils/vss'
import { useSearchParams } from 'react-router-dom'
import { isProdEnv } from 'utils/common'
import veryLocalStorage from 'utils/vls'
import { PLANT_ONBOARDING_USER_LOCATION } from 'utils/formHelpers'
import { UserContext } from 'core/UserContext'
import StyledButton from 'core/components/StyledButton'
import { OnboardingMode } from 'utils/types'
import { useNavigate } from 'react-router-dom'

const GridContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  padding: 0px 20px;
  flex-direction: column;
`

const ConversionIframe = styled.iframe`
  visibility: hidden;
  height: 0px;
  width: 0px;
`

const BackButton = styled(StyledButton)`
  margin-top: 50px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 0;
  }
`

const EndScreen: FC<PageProps> = ({ formData }) => {
  const { t } = useTranslation('endScreen')
  const [searchParams, setSearchParams] = useSearchParams()
  const userContext = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    verySessionStorage.clear()
    veryLocalStorage.delete(PLANT_ONBOARDING_USER_LOCATION)
    searchParams.delete('stripeSessionId')
    searchParams.delete('isStripeSuccess')
    setSearchParams(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBackToUserSettingsClick = useCallback(() => {
    navigate(BrowserRoutes.UserSettings)
  }, [navigate])

  return (
    <>
      <GridContentContainer id="end-screen">
        <TriColoredText
          text2={t('welcome_onboarding_text')}
          subText={t('welcome_onboarding_sub_text')}
          isSmaller
        />
        {userContext.onboardingMode === OnboardingMode.Add && (
          <BackButton
            hasInternalPadding
            altStyle
            onClick={handleBackToUserSettingsClick}
            text={t('back')}
          />
        )}
      </GridContentContainer>
      {isProdEnv() && (
        <ConversionIframe
          title="conversion"
          src={`${window.location.origin}${BrowserRoutes.Conversion}`}
        />
      )}
    </>
  )
}

export default EndScreen
