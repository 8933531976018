import React, { FC, useCallback } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'
import { PageProps } from 'pages/Onboarding'
import TriColoredText from 'core/components/TriColoredText'
import IconWhatsapp from 'assets/icons/IconWhatsapp'
import analytics from 'utils/analytics'

const PHONE_URL = process.env.REACT_APP_WHATSAPP_PHONE_URL

const GridContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  padding: 0px 20px;
  flex-direction: column;
`

const ClickHereButton = styled.a`
  margin-top: 50px;
  background-color: ${({ theme }) =>
    theme.colors.sections.otherCrop.background};
  color: ${({ theme }) => theme.colors.sections.otherCrop.text};
  gap: 16px;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 100px;
  text-decoration: none;
  width: 260px;
  height: 65px;
  padding: 22px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 18px;
    padding: 35px;
  }
`

const AskOtherCrop: FC<PageProps> = ({ formData }) => {
  const { t } = useTranslation('otherCrop')

  const handleClickHereClick = useCallback(() => {
    analytics.sendEvent(analytics.events.onboardingOtherCropClick)
  }, [])

  return (
    <>
      <GridContentContainer id="other-crop-screen">
        <TriColoredText
          text1={t('not_find')}
          text2={t('your_crop')}
          subText={t('other_crop_subtext')}
          isSmaller
        />
        <ClickHereButton
          onClick={handleClickHereClick}
          target="_blank"
          href={PHONE_URL + `?text=${t('other_crop_message')}`}
        >
          <IconWhatsapp /> {t('click_here')}
        </ClickHereButton>
      </GridContentContainer>
    </>
  )
}

export default AskOtherCrop
