import React, { FC, useCallback, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import { CircularProgress } from '@mui/material'
import { Variety } from 'utils/formConfig'
import { useTranslation } from 'react-i18next'
import { FormProps } from './FormScreen'

interface VarietyItemProps {
  variety: Variety
  isSelected: boolean
  handleSelectVariety: (VarietyName: string) => void
}

const VarietyFormContent = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 40px;
  align-items: flex-start;
  margin-bottom: auto;
  padding-bottom: 8px;

  @media (max-width: 1920px) {
    gap: 45px;
  }

  @media (max-width: 1760px) {
    gap: 40px;
  }

  @media (max-width: 1400px) {
    gap: 35px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 0;
    gap: 15px;
    justify-content: center;
  }
`

const VarietyItemContainer = styled.div<{ isSelected: boolean }>`
  border-radius: 50%;
  height: 137px;
  width: 137px;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.sections.crops.backgroundSelected : theme.colors.sections.crops.background};
  border: ${({ isSelected, theme }) =>
    `1px solid ${isSelected ? theme.colors.sections.crops.borderSelected : theme.colors.sections.crops.border}`};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 100px;
    width: 100px;
  }
`

const VarietyItemText = styled.span<{ isSelected: boolean }>`
  text-align: center;
  margin-top: 2px;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.sections.crops.iconLabelSelected : theme.colors.sections.crops.iconLabel};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.32px;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 12px;
  }
`

const VarietyFormContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-x: hidden;
`

const VarietyItem: FC<VarietyItemProps> = ({
  variety,
  isSelected,
  handleSelectVariety
}) => {
  const { t } = useTranslation('varieties')
  const handleClickVarietyItem = useCallback(() => {
    handleSelectVariety(variety.value)
  }, [handleSelectVariety, variety.value])

  return (
    <VarietyItemContainer
      isSelected={isSelected}
      onClick={handleClickVarietyItem}
    >
      <VarietyItemText isSelected={isSelected}>
        {t(variety.name)}
      </VarietyItemText>
    </VarietyItemContainer>
  )
}

const VarietyForm: FC<FormProps> = ({
  formData,
  isLoading,
  handleChangeFormData,
  varietyList
}) => {
  const selectedType = useMemo(
    () => formData.varietyForm.varietyType,
    [formData]
  )
  const handleSelectVariety = useCallback(
    (varietyName: string | number) => {
      handleChangeFormData({
        ...formData,
        varietyForm: {
          ...formData.varietyForm,
          varietyType: varietyName,
          completed: true
        }
      })
    },
    [handleChangeFormData, formData]
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <VarietyFormContainer id='variety-form'>
      <VarietyFormContent>
        {varietyList?.map((variety) => (
          <VarietyItem
            key={variety.value}
            isSelected={selectedType === variety.value}
            variety={variety}
            handleSelectVariety={handleSelectVariety}
          />
        ))}
      </VarietyFormContent>
    </VarietyFormContainer>
  )
}

export default VarietyForm
