import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react'
import styled from '@emotion/styled/macro'
import PriceCard from './PriceCard'
import {
  PaymentOption,
  SubscriptionPlan,
  UserSettingsAction
} from 'utils/types'
import { AppContext } from 'core/AppContext'
import { useTranslation } from 'react-i18next'
import PricePeriodToggle from './PricePeriodToggle'
import stripeSubscriptionsPlans from '../../../stripeSubscriptionsPlans.json'

const Container = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 2rem;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    overflow-y: auto;
    padding-bottom: 180px;
  }
`

const PlanUpgradeContainer = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: 10px;
  }
`

const TitleContainer = styled.div`
  margin-bottom: 30px;
  line-height: 2.5rem;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    line-height: 2rem;
  }
`

const Title = styled.div`
  color: #e8fbfa;
  font-size: 60px;
  letter-spacing: -1px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 40px;
  }
`

const SubTitle = styled.div`
  color: #b7d2cd;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: -1px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 17px;
  }
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const Back = styled.div`
  color: #b7d2cd;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.66px;
  cursor: pointer;
  justify-self: self-start;
  text-align: center;
  margin-top: 50px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    font-size: 12px;
    letter-spacing: -0.36px;
    margin-top: 0;
  }
`

interface UpdateSubscriptionProps {
  onActionChange: Dispatch<SetStateAction<UserSettingsAction | null>>
}

const UpdateSubscription: FC<UpdateSubscriptionProps> = ({
  onActionChange
}) => {
  const { t } = useTranslation('updateSubscription')
  const userCountryData = useContext(AppContext)
  const [paymentOption, setPaymentOption] = useState<PaymentOption>(
    PaymentOption.Monthly
  )

  const country = useMemo(
    () => userCountryData.country,
    [userCountryData.country]
  )

  const plans = useMemo(
    () => (stripeSubscriptionsPlans.markets as any)[country][paymentOption],
    [country, paymentOption]
  )

  return (
    <Container>
      <PlanUpgradeContainer>
        <TitleContainer>
          <Title>{t('packages')}</Title>
          <SubTitle>{t('choose_package')}</SubTitle>
        </TitleContainer>
        <PricePeriodToggle onPaymentOptionChange={setPaymentOption} />
        <Flex>
          {plans.map((plan: SubscriptionPlan) => (
            <PriceCard
              plan={plan}
              paymentOption={paymentOption}
              onActionChange={onActionChange}
            />
          ))}
        </Flex>
      </PlanUpgradeContainer>
      <Back
        onClick={() => {
          onActionChange(UserSettingsAction.UserSettingsDashboard)
        }}
      >
        {t('back')}
      </Back>
    </Container>
  )
}

export default UpdateSubscription
