import React, { FC, memo, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import { Fade } from 'react-awesome-reveal'
import FarmIconEnglish from 'assets/images/icon_one_pager_third_background_english.svg'
import FarmIconHebrew from 'assets/images/icon_one_pager_third_background_hebrew.svg'
import FarmIconSpanish from 'assets/images/icon_one_pager_third_background_spanish.svg'
import FarmIconMobile from 'assets/images/icon_one_pager_third_background.svg'

import PhoneIconEng from 'assets/images/icon_one_pager_phone_english.png'
import PhoneIconEs from 'assets/images/icon_one_pager_phone_spanish.png'
import PhoneIconHe from 'assets/images/icon_one_pager_phone_hebrew.png'

import ComputerIconEng from 'assets/images/icon_one_pager_computer_english.png'
import ComputerIconEs from 'assets/images/icon_one_pager_computer_spanish.png'
import ComputerIconHe from 'assets/images/icon_one_pager_computer_hebrew.png'

import { useTranslation } from 'react-i18next'
import OnePagerText from './OnePagerText'
import OnePagerPagination from './OnePagerPagination'
import { OnePagerPageProps } from './OnePagerFirstPage'

const BackgroundImage = styled.img`
  pointer-events: none;
  bottom: -2px;
  align-self: flex-start;
  position: absolute;
  max-width: 1800px;
  width: 100%;
  aspect-ratio: auto;
  object-fit: cover;
  right: calc(-30% + 50vw);

  @media (min-width: 2100px) {
    right: calc(-30% + 60vw);
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 330px;
    width: 1160px;
    bottom: -2px;
    right: calc(50% - 810px);
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) and (orientation: landscape) {
    height: 300px;
    width: 100%;
    right: 0px;
    left: 0;
  }
`

const PhoneImage = styled.img`
  pointer-events: none;
  position: absolute;
  align-self: flex-start;
  right: calc(4% + 50vw);
  max-width: 283px;
  bottom: 110px;
  width: 14.5vw;

  body[dir='rtl'] & {
    right: calc(980px + 30vw);
    bottom: 140px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    height: 45vw;
    width: 30vw;
    right: calc(70% - 60vw);
    bottom: 90px;
    max-height: 300px;
    max-width: 180px;

    body[dir='rtl'] & {
      right: calc(70% - 15vw);
      bottom: 90px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) and (orientation: landscape) {
    height: 200px;
    width: 125px;
    right: 0px;
    right: calc(50% - 250px);

    body[dir='rtl'] & {
      right: calc(50% - 250px);
      bottom: 90px;
    }
  }
`
const ComputerImage = styled(PhoneImage)`
  width: 35vw;
  max-width: 690px;
  right: calc(10% + 50vw);
  bottom: 210px;
  body[dir='rtl'] & {
    right: calc(450px + 30vw);
    bottom: 250px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 80vw;
    height: 65vw;
    right: calc(50% - 40vw);
    bottom: 100px;
    max-width: 500px;
    max-height: 400px;

    body[dir='rtl'] & {
      right: calc(50% - 40vw);
      bottom: 100px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) and (orientation: landscape) {
    height: 250px;
    width: 300px;
    right: calc(50% - 200px);
    bottom: 90px;

    body[dir='rtl'] & {
      right: calc(50% - 200px);
      bottom: 90px;
    }
  }
`

const PageContentContainer = styled.div`
  display: flex;
  background: #052b33;
  overflow: hidden;
  position: relative;
  padding-top: 15vh;
  max-height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  flex-direction: row-reverse;

  body[dir='rtl'] & {
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    flex-direction: column;
    padding-top: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    max-height: unset;

    body[dir='rtl'] & {
      flex-direction: column;
    }
  }
`

const PageTextParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  align-items: center;
  margin-top: -15vh;
  align-self: center;

  padding: 0px 10px 0px 30px;
  max-width: 50vw;

  body[dir='rtl'] & {
    padding: 0px 30px 0px 10px;
  }

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    align-self: center;
    padding: 0px 30px;
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;

    body[dir='rtl'] & {
      padding: 0px 30px;
    }
  }
`

const BackgroundImagesContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    min-height: 330px;
    display: flex;
    flex: 1;
    position: relative;
  }
`

const getImagesByLanguage = (lng: string, isMobile: boolean) => {
  switch (lng) {
    case 'he':
      return {
        phoneSrc: PhoneIconHe,
        computerSrc: ComputerIconHe,
        backgroundSrc: isMobile ? FarmIconMobile : FarmIconHebrew
      }
    case 'en':
      return {
        phoneSrc: PhoneIconEng,
        computerSrc: ComputerIconEng,
        backgroundSrc: isMobile ? FarmIconMobile : FarmIconEnglish
      }
    case 'es':
      return {
        phoneSrc: PhoneIconEs,
        computerSrc: ComputerIconEs,
        backgroundSrc: isMobile ? FarmIconMobile : FarmIconSpanish
      }
    default:
      return {
        phoneSrc: PhoneIconEng,
        computerSrc: ComputerIconEng,
        backgroundSrc: isMobile ? FarmIconMobile : FarmIconEnglish
      }
  }
}

const OnePagerThirdPage: FC<OnePagerPageProps> = ({
  handleChangePageIndex,
  isMobile
}) => {
  const { t, i18n } = useTranslation('onePager')
  const images = useMemo(
    () => getImagesByLanguage(i18n.language, !!isMobile),
    [i18n.language, isMobile]
  )

  const fadeDirection = useMemo(() => {
    if (isMobile) {
      return 'up'
    }
    return i18n.dir() === 'rtl' ? 'left' : 'right'
  }, [i18n, isMobile])

  return (
    <PageContentContainer>
      <PageTextParentContainer>
        <Fade triggerOnce direction={fadeDirection}>
          <OnePagerText
            titleText={t('page_title_3')}
            subText={t('page_subtext_3')}
          />
        </Fade>
        <OnePagerPagination
          index={2}
          length={4}
          onChange={handleChangePageIndex}
        />
      </PageTextParentContainer>
      <BackgroundImagesContainer>
        <BackgroundImage src={images.backgroundSrc} />
        {!!isMobile && (
          <>
            <ComputerImage src={images.computerSrc} />
            <PhoneImage src={images.phoneSrc} />
          </>
        )}
      </BackgroundImagesContainer>
    </PageContentContainer>
  )
}

export default memo(OnePagerThirdPage)
