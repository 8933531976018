import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import { useParams } from 'react-router-dom'
import GeoTiffMapViewer from 'core/components/GeoTiffMapViewer'
import SupPlantLogo from 'assets/images/icon_supplant_logo.svg'
import api from 'Api/ApiMethods'
import { Plot, PlotCenter } from 'utils/types'
import Dialog from 'core/components/Dialog'
import SatelliteDialog from 'core/components/SatelliteDialog'
import vls from 'utils/vls'
import analytics from 'utils/analytics'
import { SATELLITE_POPUP_CHECKED } from 'utils/geoTiffHelpers'
import AlertsDrawer from 'core/components/AlertsDrawer'
import { useTranslation } from 'react-i18next'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { styled as muiStyle } from '@mui/system'

const CustomSwitch = muiStyle(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#dcf900'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track ': {
    backgroundColor: '#dcf900'
  }
}))

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
`

const CompanyLogo = styled.img`
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 20px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    top: 0px;
    height: 80px;
    width: 80px;
  }
`

const LayerToggle = styled.div`
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 50px;
  height: 60px;
  min-width: 150px;
  color: white;
`

const PlotMapContainer = styled.div`
  height: 100%;
  width: 100%;
  direction: ltr;
`

const GrowerPlotsView: FC = () => {
  const { t } = useTranslation('geoMap')
  const { growerUuid } = useParams()
  const [plots, setPlots] = useState<Plot[] | null>(null)
  const [currentPlot, setCurrentPlot] = useState<Plot | null>(null)
  const [plotId, setPlotId] = useState<number>(-1)
  const [dialogVisible, setDialogVisible] = useState(true)
  const [checked, setChecked] = useState(false)
  const [plotAlerts, setPlotAlerts] = useState<any[]>()
  const [plotFieldData, setPlotFieldData] = useState()
  const [plotHeatWave, setPlotHeatWave] = useState()
  const [currentPlotCenter, setCurrentPlotCenter] = useState<PlotCenter | null>(
    null
  )

  const [isUniformity, setIsUniformity] = useState(false)
  useEffect(() => {
    if (!growerUuid) {
      return
    }

    api
      .getPlotsByGrowerId(growerUuid)
      .then(({ data }) => {
        setPlots(data)
        setCurrentPlot(data[0])
      })
      .catch(console.error)

    const satellitePopupChecked = vls.get(SATELLITE_POPUP_CHECKED) as string
    if (satellitePopupChecked) {
      setDialogVisible(satellitePopupChecked === 'true')
    }

    const hidePopupTimeout = setTimeout(() => {
      setDialogVisible(false)
    }, 10000)
    return () => {
      clearTimeout(hidePopupTimeout)
    }
  }, [growerUuid])

  const satelliteDialogOnClose = useCallback(() => {
    analytics.sendEvent(analytics.events.plotsViewerModalCloseClick)
    setDialogVisible(false)
  }, [])

  const handleCheckBoxChange = useCallback(() => {
    setChecked(!checked)
    analytics.sendEvent(analytics.events.plotsViewerModalDontShowAgainClick)
    vls.set(SATELLITE_POPUP_CHECKED, !checked)
  }, [checked])

  const drawerNextPlot = useCallback(() => {
    if (!currentPlot || !plots) {
      return
    }

    const indexOfCurrentPlot = plots?.indexOf(currentPlot)
    if (indexOfCurrentPlot === plots?.length - 1) {
      return
    }

    setCurrentPlot(plots[indexOfCurrentPlot + 1])
    setCurrentPlotCenter({
      latitude: plots[indexOfCurrentPlot + 1].latitude,
      longitude: plots[indexOfCurrentPlot + 1].longitude
    })
    analytics.sendEvent(analytics.events.plotsViewerAlertsDrawerNavigate, {
      labelArg: 'next'
    })
  }, [currentPlot, plots])

  const drawerPreviousPlot = useCallback(() => {
    if (!currentPlot || !plots) {
      return
    }

    const indexOfCurrentPlot = plots?.indexOf(currentPlot)
    if (indexOfCurrentPlot === 0) {
      return
    }

    setCurrentPlot(plots[indexOfCurrentPlot - 1])
    setCurrentPlotCenter({
      latitude: plots[indexOfCurrentPlot - 1].latitude,
      longitude: plots[indexOfCurrentPlot - 1].longitude
    })
    analytics.sendEvent(analytics.events.plotsViewerAlertsDrawerNavigate, {
      labelArg: 'prev'
    })
  }, [currentPlot, plots])

  useEffect(() => {
    if (!plotId || !plots) {
      return
    }

    const selectedPlot = plots.find((plot) => plot.id === plotId)
    if (selectedPlot) {
      setCurrentPlot(selectedPlot)
    }
  }, [plotId, plots])

  useEffect(() => {
    if (!plotAlerts || !currentPlot) {
      return
    }

    const fieldData = plotAlerts.find((item) => item.id === currentPlot.id)
    if (fieldData) {
      setPlotFieldData(fieldData.data)
      setPlotHeatWave(fieldData.hw)
    }
  }, [plotAlerts, currentPlot])

  const handleLayerOnClick = useCallback(() => {
    analytics.sendEvent(analytics.events.plotsViewerSatelliteLayerToggle, {
      labelArg: isUniformity ? 'soil' : 'uniformity'
    })
    setIsUniformity((prev) => !prev)
  }, [isUniformity])

  return (
    <PageContainer id='satellite-screen'>
      <Dialog onClose={satelliteDialogOnClose} open={dialogVisible}>
        <SatelliteDialog
          onClose={satelliteDialogOnClose}
          checkBoxChange={handleCheckBoxChange}
        />
      </Dialog>
      <CompanyLogo src={SupPlantLogo} />
      <LayerToggle>
        <Typography variant="body1" component="span">
          {t('soil')}
        </Typography>
        <CustomSwitch checked={isUniformity} onChange={handleLayerOnClick} />
        <Typography variant="body1" component="span">
          {t('uniformity')}
        </Typography>
      </LayerToggle>
      <AlertsDrawer
        isOpen
        currentPlot={currentPlot}
        plotFieldData={plotFieldData}
        plotHeatWave={plotHeatWave}
        nextPlot={drawerNextPlot}
        previousPlot={drawerPreviousPlot}
      />
      <PlotMapContainer>
        <GeoTiffMapViewer
          plots={plots}
          selectedPlotCenter={currentPlotCenter}
          isUniformity={isUniformity}
          onPlotIdChange={setPlotId}
          onPlotAlertChange={setPlotAlerts}
        />
      </PlotMapContainer>
    </PageContainer>
  )
}

export default GrowerPlotsView
