import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import styled from '@emotion/styled/macro'
import StyledButton from 'core/components/StyledButton'
import {
  PaymentOption,
  SubscriptionPlan,
  UserSettingsAction
} from 'utils/types'
import api from 'Api/ApiMethods'
import { UserContext } from 'core/UserContext'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { isProdEnv } from 'utils/common'
import CallSupportDialog from '../CallSupportDialog'
import { AppContext } from 'core/AppContext'
import analytics from 'utils/analytics'

const Card = styled.div`
  padding: 50px;
  border: 1px solid #506f76;
  margin-bottom: 10px;
  border-radius: 22px;
  max-width: 380px;
`

const CardTitle = styled.h2`
  font-weight: bold;
  font-size: 35px;
  color: white;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin-top: -10px;
    font-size: 25px;
  }
`

const Price = styled.p`
  margin: 25px 0;
  font-weight: bold;
  font-size: 37px;
  color: #dcf900;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    margin: 0;
    font-size: 30px;
  }
`

const SubText = styled.p`
  font-size: 14px;
  color: #b7d2cd;
  font-weight: normal;
`

const UpgradeButton = styled(StyledButton)`
  width: 225px;
  height: 70px;

  @media (max-width: ${({ theme }) => theme.media.width.sm}) {
    width: 120px;
    height: 45px;
  }
`

interface PriceCardProps {
  plan: SubscriptionPlan
  paymentOption: PaymentOption
  onActionChange: Dispatch<SetStateAction<UserSettingsAction | null>>
}

const PriceCard: FC<PriceCardProps> = ({
  plan,
  paymentOption,
  onActionChange
}) => {
  const { t } = useTranslation('updateSubscription')
  const userContext = useContext(UserContext)
  const userCountryData = useContext(AppContext)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [contactUsMessage, setContactUsMessage] = useState<string>('')
  const perPeriod = useMemo(
    () =>
      paymentOption === PaymentOption.Monthly ? t('per_month') : t('per_year'),
    [t, paymentOption]
  )

  const updateSubscription = useCallback(async () => {
    try {
      if (
        userContext.userSubscription?.plotPlan &&
        plan.plan <= userContext.userSubscription?.plotPlan
      ) {
        setDialogVisible(true)
        setContactUsMessage(t('contact_us_downgrade'))
        return
      }

      analytics.sendEvent(analytics.events.userSettingsChoosePackageClick, {
        labelArg: `${plan.plan} plots`
      })
      await api.putSubscription(
        userContext.authToken,
        plan.priceId[isProdEnv() ? 'live' : 'test'],
        userCountryData.country
      )
      onActionChange(UserSettingsAction.UserSettingsDashboard)
    } catch (error) {
      toast.error(t<string>('formScreen:general_server_error'))
      setDialogVisible(true)
      setContactUsMessage(t('contact_us_upgrade_error'))
    }
  }, [
    userContext.userSubscription?.plotPlan,
    userContext.authToken,
    plan.plan,
    plan.priceId,
    userCountryData.country,
    onActionChange,
    t
  ])

  const callCustomerSuccessDialogOnClose = useCallback(() => {
    setDialogVisible(false)
  }, [])

  return (
    <Card>
      <CallSupportDialog
        dialogVisible={dialogVisible}
        contactUsMessage={contactUsMessage}
        onClose={callCustomerSuccessDialogOnClose}
      />
      <CardTitle>
        {plan.plan} {t('plots')}
      </CardTitle>
      <SubText>{t(plan.description)}</SubText>
      <Price>{plan.price}</Price>
      <SubText>{perPeriod}</SubText>
      <UpgradeButton text={t('upgrade').toLocaleUpperCase()} onClick={updateSubscription} />
    </Card>
  )
}

export default PriceCard
