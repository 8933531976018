/* eslint-disable max-len */
import ReactGA from 'react-ga'

export enum AnalyticsCategory {
  Onboarding = 'ONBOARDING',
  OnePager = 'ONE_PAGER',
  PlotsViewer = 'PLOTS_VIEWER',
  UserSettings = 'USER_SETTINGS'
}

export enum AnalyticsAction {
  Click = 'click'
}

export interface SimpleEventArgs {
  category: AnalyticsCategory | string,
  action: AnalyticsAction | string,
  label?: string,
  value?: number
}

export interface EventOptions {
  value?: number,
  labelArg?: string
}

const events: { [key: string]: SimpleEventArgs } = {
  //  ONBOARDING Category
  onboardingNextClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'next_step - {labelArg}' },
  onboardingBackClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'back_step - {labelArg}' },
  onboardingConfirmClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'confirm' },
  onboardingPaymentFailure: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'payment_failed' },
  onboardingCreateChatFailure: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'create_chat_failed' },
  onboardingSuccess: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'onboarding_success' },
  onboardingContactUsClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'contact_us' },
  onboardingAboutUsClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'about_us' },
  onboardingStartClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'start_onboarding' },
  onboardingChangeLanguageClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'change_language - {labelArg}' },
  onboardingClearPolygonMapClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'clear_plot_polygon_map' },
  onboardingTermsAndConditionsClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'terms_and_conditions_link' },
  onboardingDownloadWhatsAppClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'download_whatsapp_link' },
  onboardingLogoRestartClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'start_over' },
  onboardingOTPClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'otp_need_help' },
  onboardingOtherCropClick: { category: AnalyticsCategory.Onboarding, action: AnalyticsAction.Click, label: 'other_crop' },
  //  ONE_PAGER  Category
  onePagerNextClick: { category: AnalyticsCategory.OnePager, action: AnalyticsAction.Click, label: 'next_step - {labelArg}' },
  onePagerBackClick: { category: AnalyticsCategory.OnePager, action: AnalyticsAction.Click, label: 'back_step - {labelArg}' },
  onePagerReturnClick: { category: AnalyticsCategory.OnePager, action: AnalyticsAction.Click, label: 'back_to_onboarding - {labelArg}' },
  onePagerHowOurSystemWorksClick: { category: AnalyticsCategory.OnePager, action: AnalyticsAction.Click, label: 'how_our_system_works_link' },
  onePagerOurCompanyInfoClick: { category: AnalyticsCategory.OnePager, action: AnalyticsAction.Click, label: 'our_company_more_info_link' },
  // PLOTS_VIEWER Category
  plotsViewerModalCloseClick: { category: AnalyticsCategory.PlotsViewer, action: AnalyticsAction.Click, label: 'close_satellite_info_modal' },
  plotsViewerModalDontShowAgainClick: { category: AnalyticsCategory.PlotsViewer, action: AnalyticsAction.Click, label: 'dont_show_me_again_satellite_info_modal' },
  plotsViewerAlertsDrawerToggle: { category: AnalyticsCategory.PlotsViewer, action: AnalyticsAction.Click, label: 'alerts_drawer_toggle - {labelArg}' },
  plotsViewerAlertsDrawerNavigate: { category: AnalyticsCategory.PlotsViewer, action: AnalyticsAction.Click, label: 'alerts_drawer_navigate - {labelArg}' },
  plotsViewerSatelliteLayerToggle: { category: AnalyticsCategory.PlotsViewer, action: AnalyticsAction.Click, label: 'satellite_layer_toggle - {labelArg}' },
  // USER_SETTINGS Category
  userSettingsLoginClick: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_login' },
  userSettingsLogoutClick: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_logout' },
  userSettingsAddPlotClick: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_add_plot' },
  userSettingsPlanUpgradeClick: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_plan_upgrade' },
  userSettingsChoosePlanToggle: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_choose_plan_toggle - {labelArg}' },
  userSettingsChoosePackageClick: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_choose_package - {labelArg}' },
  userSettingsCallSupportDialogOpen: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_call_support_dialog_open' },
  userSettingsCallSupportContactUsClick: { category: AnalyticsCategory.UserSettings, action: AnalyticsAction.Click, label: 'user_settings_call_support_contact_us_click' }
}

const createEvent = (simpleEvent: SimpleEventArgs, eventOptions?: EventOptions): SimpleEventArgs => {
  const event: SimpleEventArgs = { ...simpleEvent }
  if (event?.label) {
    event.label = `[${event?.category}] ${event?.label}`

    if (eventOptions?.labelArg) {
      event.label = event?.label.replace('{labelArg}', eventOptions?.labelArg)
    }
  }

  if (eventOptions?.value !== undefined) {
    event.value = eventOptions?.value
  }

  return event
}

const sendEvent = (simpleEvent: SimpleEventArgs, eventOptions?: EventOptions): void => {
  try {
    const event = eventOptions ? createEvent(simpleEvent, eventOptions) : simpleEvent
    ReactGA.event(event)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in analytics sendEvent: ', error)
  }
}

const handleAnalyticsClick = (event: any, simpleEvent: SimpleEventArgs | undefined, onClick: (event: any) => void | undefined): void => {
  try {
    if (simpleEvent) {
      sendEvent(simpleEvent)
    }

    if (onClick) {
      onClick(event)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in analytics handleAnalyticsClick:', error)
  }
}

const analytics = {
  events,
  createEvent,
  sendEvent,
  handleAnalyticsClick
}

export default analytics
