import { Api, ApiEndpoints } from './Api'
import { Country } from 'utils/formConfig'
import { BotUser } from 'utils/types'
import { getGrowerBody } from 'utils/userManagement'

const getSupportedLocales = async () => {
  return await Api.get(ApiEndpoints.getSupportedLocales())
}

const getSatelliteByPeriodData = async (
  plotUuid: string,
  from: number,
  to: number
) => {
  return await Api.get(ApiEndpoints.getSatelliteByPeriodData(plotUuid), {
    params: { from, to }
  })
}

const getPlotsByGrowerId = async (growerUuid: string) => {
  return await Api.get(ApiEndpoints.getPlotsByGrowerId(growerUuid))
}

const getPlotAlertByType = async (plotUuid: string, typeIds: number[]) => {
  return await Api.get(
    ApiEndpoints.getPlotAlertByType(plotUuid, typeIds.toString())
  )
}

const getAccessCode = async (phone: string, lang: string) => {
  return await Api.post(ApiEndpoints.access(), null, {
    params: { phone },
    headers: {
      'Accept-Language': lang || 'en'
    }
  })
}

const getAccessAndValidate = async (phone: string, lang: string) => {
  return await Api.post(ApiEndpoints.accessAndValidate(), null, {
    params: { phone },
    headers: {
      'Accept-Language': lang || 'en'
    }
  })
}

const postAccessCode = async (code: string, secret: string, lang: string) => {
  return await Api.post(ApiEndpoints.verify(code, secret), null, {
    headers: {
      'Accept-Language': lang || 'en'
    }
  })
}

const updateUser = async (newUser: BotUser[], token: string) => {
  return await Api.put(ApiEndpoints.updateUser(), newUser, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Language': newUser[0].locale || 'en-us'
    }
  })
}

const getActiveUser = async (token: string, userId: number) => {
  return await Api.get(ApiEndpoints.getActiveUser(userId), {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const createGrower = async (
  token: string,
  userId: string,
  growerName: string,
  country: Country
) => {
  return await Api.post(
    ApiEndpoints.createGrower(userId),
    { ...getGrowerBody(country), name: growerName },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const putOneTimeMessages = async (
  token: string,
  userId: string,
  growerId: string,
  plotId: string
) => {
  return await Api.put(
    ApiEndpoints.putOneTimeMessages(userId, growerId, plotId),
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const getCrops = async (token: string) => {
  return await Api.get(ApiEndpoints.getCrops(), {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getCategory = async (
  token: string,
  growerId: string,
  cropId: string | undefined
) => {
  if (!cropId) {
    return undefined
  }

  return await Api.get(ApiEndpoints.getCategory(growerId, cropId), {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getCropProtocol = async (
  token: string,
  growerId: string,
  cropId: string | undefined,
  lat: number,
  lng: number,
  variety: string | undefined,
  category: string | undefined
) => {
  if (!cropId) {
    return undefined
  }

  return await Api.get(
    ApiEndpoints.getCropProtocol(growerId, cropId, lat, lng, variety, category),
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const createPlot = async (
  token: string,
  growerId: string,
  companyUuid: string,
  plotBody: any,
  shouldAttachIWSensor: boolean
) => {
  return await Api.post(
    ApiEndpoints.createPlot(growerId, companyUuid, shouldAttachIWSensor),
    plotBody,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const getGrowingTypes = async (
  token: string,
  protocol: string,
  plantDate: number
) => {
  return await Api.get(ApiEndpoints.getGrowingTypes(protocol, plantDate), {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getPlotParams = async (token: string, plotId: number) => {
  return await Api.get(ApiEndpoints.getPlotParams(plotId), {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const postPlotParams = async (token: string, plotId: number, body: any) => {
  return await Api.post(ApiEndpoints.postPlotParams(plotId), body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getCheckoutSession = async (
  token: string,
  sessionId: string,
  growerId: number,
  userId: number,
  country: string
) => {
  return await Api.get(
    ApiEndpoints.getCheckoutSession(sessionId, growerId, userId, country),
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const postCheckoutSession = async (
  token: string,
  priceId: string,
  country: string
) => {
  return await Api.post(
    ApiEndpoints.postCheckoutSession(priceId, country),
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const getSubscription = async (token: string, country: string) => {
  return await Api.get(ApiEndpoints.getSubscription(country), {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const putSubscription = async (
  token: string,
  priceId: string,
  country: string
) => {
  return await Api.put(
    ApiEndpoints.putSubscription(priceId, country),
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const getPlots = async (token: string) => {
  return await Api.get(ApiEndpoints.getPlots(), {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getPaymentMethod = async (
  resellerId: string | number,
  phoneNumber: string
) => {
  return await Api.get(ApiEndpoints.getPaymentMethod(resellerId, phoneNumber))
}

const getAccessCodeMasterPool = async (
  resellerId: string,
  phone: string,
  lang: string
) => {
  return await Api.post(
    ApiEndpoints.getAccessMasterPool(resellerId, phone),
    null,
    {
      params: { phone },
      headers: {
        'Accept-Language': lang || 'en'
      }
    }
  )
}

const postAccessCodeMasterPool = async (
  code: string,
  secret: string,
  resellerId: string,
  lang: string
) => {
  return await Api.post(
    ApiEndpoints.verifyMasterPool(secret, code, resellerId),
    null,
    {
      headers: {
        'Accept-Language': lang || 'en'
      }
    }
  )
}

const getAccessCodeCoupon = async (phone: string, lang: string) => {
  return await Api.post(ApiEndpoints.getAccessCoupon(phone), null, {
    params: { phone },
    headers: {
      'Accept-Language': lang || 'en'
    }
  })
}

const postAccessCodeCoupon = async (
  code: string,
  secret: string,
  resellerId: string,
  coupon: string,
  lang: string
) => {
  return await Api.post(
    ApiEndpoints.verifyCoupon(secret, code, resellerId, coupon),
    null,
    {
      headers: {
        'Accept-Language': lang || 'en'
      }
    }
  )
}

const postActivateMasterPool = async (
  token: string,
  contractGrowerId: string,
  growerId: number | string,
  phoneNumber: string,
  lang: string
) => {
  return await Api.post(
    ApiEndpoints.postActivateMasterPool(
      contractGrowerId,
      growerId,
      phoneNumber
    ),
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

const postActivateCoupon = async (
  token: string,
  couponCode: string,
  resellerId: string,
  growerId: number | string,
  lang: string
) => {
  return await Api.post(
    ApiEndpoints.postActivateCoupon(couponCode, resellerId, growerId),
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

const postLeads = async (
  token: string,
  phoneNumber: string,
  timestamp: number,
  userDetails: string
) => {
  return await Api.post(
    ApiEndpoints.postLeads(),
    {
      phoneNumber: phoneNumber,
      date: timestamp,
      userDetails: userDetails
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

const api = {
  getSupportedLocales,
  getSatelliteByPeriodData,
  getAccessCode,
  getAccessAndValidate,
  postAccessCode,
  getAccessCodeMasterPool,
  postAccessCodeMasterPool,
  getAccessCodeCoupon,
  postAccessCodeCoupon,
  postActivateMasterPool,
  postActivateCoupon,
  updateUser,
  getActiveUser,
  createGrower,
  putOneTimeMessages,
  getCrops,
  getCategory,
  getCropProtocol,
  createPlot,
  getGrowingTypes,
  getPlotParams,
  postPlotParams,
  getCheckoutSession,
  postCheckoutSession,
  getSubscription,
  putSubscription,
  getPlots,
  getPlotsByGrowerId,
  getPlotAlertByType,
  getPaymentMethod,
  postLeads,
}

export default api
