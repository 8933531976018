import React, { memo, FC } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import eventEmitter, { events } from 'utils/eventEmitter'
import styled from '@emotion/styled/macro'
import CircularProgress from '@mui/material/CircularProgress'
import { theme } from 'ui'

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1301;
`

const Loading: FC = () => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const setLoadingEnabled = (): void => {
      setIsLoading(true)
    }

    const setLoadingDisabled = (): void => {
      setIsLoading(false)
    }

    eventEmitter.on(events.ENABLE_LOADING, setLoadingEnabled)
    eventEmitter.on(events.DISABLE_LOADING, setLoadingDisabled)

    return (): void => {
      eventEmitter.removeListener(events.ENABLE_LOADING, setLoadingEnabled)
      eventEmitter.removeListener(events.DISABLE_LOADING, setLoadingDisabled)
    }
  }, [])

  if (!isLoading) {
    return null
  }

  return (
    <LoadingContainer>
      <CircularProgress
        size="10rem"
        sx={{ color: theme.colors.palette.secondary }}
      />
    </LoadingContainer>
  )
}

export default memo(Loading)
