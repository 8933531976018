import React, { FC, memo } from 'react'
import styled from '@emotion/styled/macro'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Metropolis;
  border-radius: 8px;
`

const LegendsContainer = styled.div`
  width: 135px;
  height: 180px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
`

const LegendTitle = styled.h4`
  margin-top: 0;
  padding-top: 12px;
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
`

const LegendContent = styled.div`
  padding-top: 6px;
  margin-top: -6px;
`

const Color = styled.i`
  width: 16px;
  height: 16px;
  float: left;
  margin: 0px 8px 0px 8px;
`

const LegendText = styled.span`
  position: relative;
  bottom: 5px;
  color: rgb(5, 43, 51);
  font-size: 16px;
  font-weight: normal;
`

interface TextualMapLegendProps {
  legendColorArray?: any
  title?: string
}

const TextualMapLegend: FC<TextualMapLegendProps> = ({
  legendColorArray,
  title
}) => {
  const { t } = useTranslation('geoMap')

  return (
    <Container>
      <LegendsContainer>
        <LegendTitle>{title}</LegendTitle>
        {Object.entries(legendColorArray).map(([key, value]) => (
          <LegendContent>
            <Color style={{ background: (value as any).color }} />
            <LegendText>{t((value as any).textKey)}</LegendText>
            <br />
          </LegendContent>
        ))}
      </LegendsContainer>
    </Container>
  )
}

export default memo(TextualMapLegend)
